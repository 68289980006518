import ActionTypes from '../constants/actionTypes'
import store from '../store'

export const setNewNotValidated = (notValidated) => {
  store.dispatch({ type: ActionTypes.SET_NEW_NOT_VALIDATED, value:notValidated })
} ;

export const setAnimateCart = (animation) => {
  store.dispatch({ type: ActionTypes.SET_ANIMATION_TO_DO, value:animation })
} ;

export const setShowCart = (show) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_CART, value:show })
}

export const addNotValidated = () => {
  store.dispatch({ type: ActionTypes.ADD_ONE_NOT_VALIDATED, value:"" })
}

