import React from 'react';
import "./css/marks.css"
import {connect} from "react-redux";
import {setShowError, setShowSuccess} from "../../../actions/menu";
import {Link} from "@material-ui/core";

const SuccessMark = (props) => {

  React.useEffect(() => {
    if (props.success) {
      setTimeout(() => {
        if (props.success != null) {
          setShowSuccess(null);
        }
      }, 5000)
    }
  }, [props.success])

  return (
    <>
      {
        props.success != null && (
          <>
            <Link underline={"none"} onClick={() => setShowSuccess(null)}>
              <div className={"blackBGAlert"}/>
              <div className={"markTextSuccess"}>
                {props.success}
                <div className={"actionPin centerSuccess"}>
                  <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                  </svg>
                </div>

              </div>
            </Link>
          </>
        )
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  success: state.menu.success
});

export default connect(mapStateToProps)(SuccessMark)

