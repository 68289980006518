import React from "react";
import {Card, CardActions, Grid, Typography} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import i18next from "i18next";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {setLanguage} from "../../actions/language";
import Link from "@material-ui/core/Link";
import {setStateContainer} from "../../actions/session";
import {FlagsMediaLink} from "../../constants/global";

const Language = () => {

  const languages = [
      {
        value: "en",
        img: `${FlagsMediaLink}en2.png`
      },
      {
        value: "fr",
        img: `${FlagsMediaLink}fr.png`
      },
      {
        value: "ae",
        img: `${FlagsMediaLink}ae.png`
      },
      {
        value: "de",
        img: `${FlagsMediaLink}de.png`
      },
      {
        value: "es",
        img: `${FlagsMediaLink}es.png`
      },
      {
        value: "in",
        img: `${FlagsMediaLink}in.png`
      },
      {
        value: "it",
        img: `${FlagsMediaLink}it.png`
      },
      {
        value: "jp",
        img: `${FlagsMediaLink}jp.png`
      },
      {
        value: "pt",
        img: `${FlagsMediaLink}pt.png`
      },
      {
        value: "ru",
        img: `${FlagsMediaLink}ru.png`
      }
    ]
  ;

  return (
    <React.Fragment>
      <Grid
        style={{backgroundImage: `url("/assets/img/menu/bg.png")`, height: "100vh",}}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={11} md={6} xl={4}>
          <Card variant="outlined">
            <CardHeader
              style={{textAlign: "left"}}
              title={
                <Typography variant="h4" component="p">
                  {i18next.t("language.title")}
                </Typography>
              }
            />
            <CardContent>
              <Divider style={{marginTop: "-1em", marginBottom: "2em", width: "100%"}}/>
              <Grid item xs={12} style={{marginBottom: "1em"}}>
                {
                  languages.map(language => (
                    <Link onClick={() => {
                      setLanguage(language.value);
                      setStateContainer("menu");
                    }}>
                      <img
                        alt={language.value}
                        src={language.img}
                        style={{
                          height: "5.5em",
                          margin: ".5em",
                          border: "1px solid #e6e6e6",
                          boxShadow: "1px 1px 0px #e6e6e6"
                        }}
                      />
                    </Link>
                  ))
                }
              </Grid>
            </CardContent>
            <CardActions/>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
};

export default Language;
