import React, {Component} from 'react';
import './css/selfie.css'
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {Clear, Done} from "@material-ui/icons";
import {Dialog} from "@material-ui/core";
import i18next from "i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import {LogoLink} from "../../constants/global";
import Zoom from "@material-ui/core/Zoom";
import {setEmail, setShowLegalMention} from "../../actions/session";
import effects from "./filters.js";
import {setBackDrop} from "../../actions/menu";
import { connect } from 'react-redux'

class CameraFeed extends Component {

  interval = 0;
  closing = false;

  constructor(props) {
    super(props);
    this.state = {
      timeouts: [],
      pictureTaken: false,
      counter: 5,
      counterState: false,
      flash: false,
      open: false,
      email: "",
      email2: "",
      email3: "",
      email4: "",
      email5: "",
      loading: false,
      openDeal: false,
      deal: false,
      emailError: false,
      filter: false,
      imgSrc: "google.fr"
    };
    this.validatePicture.bind(this);
  }

  /**
   * Sets the active device and starts playing the feed
   * @memberof CameraFeed
   * @instance
   */
  async setDevice() {
    navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: "user"}}).then((s) => {

      this.videoPlayer.srcObject = s;
      this.videoPlayer.play();
      const context = this.canvas.getContext('2d');


      this.interval = setInterval(() => {

        if (!this.state.pictureTaken) {
          if (!this.closing && !this.props.kill) {
            context.drawImage(this.videoPlayer, 0, 0, 800, 1066);
          }
        }
      }, 30);

    });

  }


  /**
   * On mount, grab the users connected devices and process them
   * @memberof CameraFeed
   * @instance
   * @override
   */
  componentDidMount() {
    this.setDevice();
    this.initiateEmail()
  }

  initiateEmail() {
    if(this.props.emails.email.length > 0) {
      this.setState({email: this.props.emails.email})
    }
    if(this.props.emails.email2.length > 0) {
      this.setState({email2: this.props.emails.email2})
    }
    if(this.props.emails.email3.length > 0) {
      this.setState({email3: this.props.emails.email3})
    }
    if(this.props.emails.email4.length > 0) {
      this.setState({email4: this.props.emails.email4})
    }
    if(this.props.emails.email5.length > 0) {
      this.setState({email5: this.props.emails.email5})
    }
  }

  decreaseCounter(currentCounter) {
    this.setState({counter: currentCounter});
    this.state.timeouts.push(setTimeout(() => {
      if (currentCounter > 0) {
        this.decreaseCounter(currentCounter - 1);
      }
    }, 1000));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.state.timeouts.forEach(t => clearTimeout(t));
  }

  canvasFilters = [];

  /**
   * Handles taking a still image from the video feed on the camera
   * @memberof CameraFeed
   * @instance
   */
  takePhoto = () => {

    this.setState({counter: 5, counterState: true});
    this.decreaseCounter(5);

    // Light on the flash 4800 ms later
    const flashTimeout = setTimeout(() => {
      this.setState({flash: true})
      this.state.timeouts.push(setTimeout(() => {
        this.setState({flash: false})
      }, 400));
    }, 4800);

    this.state.timeouts.push(flashTimeout);

    // Take the picture 5100 ms after pressing the button
    const takePictureTimeout = setTimeout(() => {
      setBackDrop(true)
      this.setState({pictureTaken: true, counterState: false, counter: 5});
      clearTimeout(takePictureTimeout);
      clearTimeout(flashTimeout);

      this.imgRef.src = this.canvas.toDataURL('image/jpeg')

      setTimeout(() => {
        setBackDrop(false)
        effects.forEach(effect => {
          this.applyEffect(effect.name, this.canvasFilters[effect.name])
        })
      }, 1000)

    }, 5100);

    this.state.timeouts.push(takePictureTimeout);

  };

  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  validatePicture = () => {
    const {sendFile} = this.props;
    let email;
    this.setState({emailError: false});

    if (this.state.email.length > 0)
      if (this.validateEmail(this.state.email)) {
        email = this.state.email;
      } else {
        this.setState({emailError: true});
        return;
      }

    if (this.state.email2.length > 0)
      if (this.validateEmail(this.state.email2)) {
        email += `,${this.state.email2}`;
      } else {
        this.setState({emailError: true});
        return;
      }

    if (this.state.email3.length > 0)
      if (this.validateEmail(this.state.email3)) {
        email += `,${this.state.email3}`;
      } else {
        this.setState({emailError: true});
        return;
      }

    if (this.state.email4.length > 0)
      if (this.validateEmail(this.state.email4)) {
        email += `,${this.state.email4}`;
      } else {
        this.setState({emailError: true});
        return;
      }

    if (this.state.email5.length > 0)
      if (this.validateEmail(this.state.email5)) {
        email += `,${this.state.email5}`;
      } else {
        this.setState({emailError: true});
        return;
      }

    if (email.length === 0)
      return;

    this.setState({open: false});
    this.canvas.toBlob((data) => sendFile(data, email, this.state.deal, this.state.filter));
  }

  applyEffect = (name, canvas) => {

    const {width, height} = canvas;

    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, width, height);

    const effect = effects.find(eff => eff.name === name);

    ctx.filter = effect.filter;
    ctx.globalCompositeOperation = "source-over";

    effect.overlays.forEach(overlay => {
      ctx.globalCompositeOperation = overlay.mixBlendMode;
      ctx.fillStyle = overlay.backgroundColor;
    });

    ctx.drawImage(this.imgRef, 0, 0, width, height);

  }

  render() {
    return (
      <>
        <Grid container alignContent={"center"} justify={"center"} alignItems={"center"} className={"menuSelfie"}>
          {
            this.state.pictureTaken ? (
              <>
                <Grid item xs={6} className={"menuCancel"} onClick={() => {
                  this.setState({pictureTaken: false})
                  this.applyEffect("normal", this.canvas)
                }}>
                  <div className={"globalCenter styleSelfieText"}>
                    {i18next.t("selfie.back")}
                  </div>
                </Grid>
                <Grid item xs={6} className={"menuValidate"} onClick={
                  () => this.setState({openFilter: true})
                }>
                  <div className={"globalCenter styleSelfieText"}>
                    {i18next.t("selfie.grant")}
                  </div>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} className={"menuValidate"} style={{zIndex: 10000}} onClick={
                () => {
                  this.props.handleKill();
                  this.setState({loading: true});
                  clearInterval(this.interval);
                  this.closing = true;
                }
              }>
                <div className={"globalCenter styleSelfieText"}>
                  {i18next.t("selfie.back")}
                </div>
              </Grid>
            )
          }
        </Grid>

        <div style={{backgroundImage: `url("${LogoLink}${this.props.logo}")`}} className={"bottomSelfie"}/>


        <div className={"styleSelfie2"} style={{
          backgroundImage: `url("/assets/img/menu/bgSelfie2.png")`
        }}/>


        {
          this.state.loading && (
            <div className={"successDialogContent"}>
              <div className={"successDialog"}>
                <CircularProgress/>
              </div>
            </div>
          )
        }

        {this.state.counterState && (
          <>
            <div className={"counterBG"}>
              <div className={"counterBG_1"}>
                <div className={"counterBG_2"}>
                  <div className={"counterBG_3"}>
                    <div className={"counterContent"}>
                      {this.state.counter}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {
          this.state.pictureTaken && (
            <Grid container className={"effectsContainer"} justify={"center"} alignItems={"center"}
                  alignContent={"center"} spacing={4}>
              {
                effects.map((effect) => (
                  <Grid item className={"selfieEffects"}>
                    <Link onClick={() => this.applyEffect(effect.name, this.canvas)}>
                      <canvas width="110" height="146" className={"selfieEffectsCanvas"}
                              ref={ref => (this.canvasFilters[effect.name] = ref)}/>
                    </Link>
                  </Grid>
                ))
              }
            </Grid>
          )
        }

        {this.state.flash && <div className={"flash"}/>}

        <div>
          <div className={!this.state.pictureTaken ? "showCamera" : "hideCamera"}>
            <div className="c-camera-feed__viewer">
              <video style={{
                display: this.state.loading ? "none" : "block"
              }} className={"selfie"} ref={ref => (this.videoPlayer = ref)}/>
            </div>
          </div>
          <div className={"showCanvas"}>
            <canvas width="800" height="1066" className={"selfieR"} ref={ref => (this.canvas = ref)}/>

            <img width="800" height="1066" className={"selfieImgView"} ref={ref => (this.imgRef = ref)}/>

          </div>
        </div>

        {
          !this.state.pictureTaken && !this.state.counterState && !this.state.loading && (
            <>
              <div className={"blackBG"}/>
              <div className={"screenButtonBG"}>
                <Link underline={"none"} onClick={this.takePhoto}>
                  <div className={"screenButtonBG_1"}>
                    <div className={"screenButtonBG_2"}>
                      <div className={"screenButtonBG_3"}>
                        <div className={"screenButtonContent"}>
                          {i18next.t("selfie.screen")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )
        }

        <Dialog fullWidth={true} fullScreen={true} open={this.state.open} onClose={() => this.setState({open: false})}
                aria-labelledby="form-dialog-title" PaperProps={{
          className: "dialogGrant"
        }}>
          <div className={"dealHeader"}>
            <Clear onClick={() => this.setState({open: false})} className={"dealHeaderIcon dealHeaderIconWhite"}/>
          </div>

          <div className={"globalCenter"}>
            {
              this.state.emailError && (
                <div className={"emailErrorText"}>
                  {i18next.t("selfie.wrongAddress")}
                </div>
              )
            }
            <input placeholder={`Email 1`} type={"email"}
                   className={this.state.emailError ? "emailStyled emailError" : "emailStyled"}
                   onChange={(e) => {
                     this.setState({email: e.target.value})
                     setEmail({
                       ...this.props.emails,
                       email: e.target.value
                     })
                   }} value={this.state.email}/>
            <input placeholder={`Email 2`} type={"email"}
                   className={this.state.emailError ? "emailStyled emailError" : "emailStyled"}
                   onChange={(e) => {
                     this.setState({email2: e.target.value})
                     setEmail({
                       ...this.props.emails,
                       email2: e.target.value
                     })
                   }} value={this.state.email2}/>
            <input placeholder={`Email 3`} type={"email"}
                   className={this.state.emailError ? "emailStyled emailError" : "emailStyled"}
                   onChange={(e) => {
                     this.setState({email3: e.target.value})
                     setEmail({
                       ...this.props.emails,
                       email3: e.target.value
                     })
                   }} value={this.state.email3}/>
            <input placeholder={`Email 4`} type={"email"}
                   className={this.state.emailError ? "emailStyled emailError" : "emailStyled"}
                   onChange={(e) => {
                     this.setState({email4: e.target.value})
                     setEmail({
                       ...this.props.emails,
                       email4: e.target.value
                     })
                   }} value={this.state.email4}/>
            <input placeholder={`Email 5`} type={"email"}
                   className={this.state.emailError ? "emailStyled emailError" : "emailStyled"}
                   onChange={(e) => {
                     this.setState({email5: e.target.value})
                     setEmail({
                       ...this.props.emails,
                       email5: e.target.value
                     })
                   }} value={this.state.email5}/>
          </div>

          <div className={"dealContainerButtonSend"}>
            <Zoom in={
              this.validateEmail(this.state.email) ||
              this.validateEmail(this.state.email2) ||
              this.validateEmail(this.state.email3) ||
              this.validateEmail(this.state.email4) ||
              this.validateEmail(this.state.email5)
            }>
              <div className={"yellowButtonFull buttonSelfieSend"} onClick={() => this.validatePicture()}>
                {i18next.t("bill.dialog.send")}
              </div>
            </Zoom>
          </div>
        </Dialog>

        <Dialog fullWidth={true} fullScreen={true} open={this.state.openDeal} PaperProps={{
          className: "dialogGrant"
        }}
                onClose={() => this.setState({openDeal: false})}
                aria-labelledby="form-dialog-title">
          <div className={"dealHeader"}>
            <Clear onClick={() => this.setState({openDeal: false})} className={"dealHeaderIcon"}/>
          </div>
          <div className={"globalCenter dealText"}>
            <p>{i18next.t("selfie.grantSentence")}</p>
            <div className={"buttons"}>
              <Link onClick={() => {
                this.setState({deal: 0, open: true, openDeal: false});
              }}>
                <div className={"buttonRoundSelfie"}>
                  <Clear className={"buttonRoundSelfieIcon"}/>
                </div>
              </Link>
              <Link onClick={() => {
                this.setState({deal: 1, open: true, openDeal: false});
              }}>
                <div className={"buttonRoundSelfie"}>
                  <Done className={"buttonRoundSelfieIcon"}/>
                </div>
              </Link>
            </div>
          </div>
          <div className={"selfieNB"}
               onClick={() => setShowLegalMention(true)}>{i18next.t("selfie.NBgrantSentence")}<br/>{i18next.t("selfie.NBLink")}
          </div>
        </Dialog>

        <Dialog fullWidth={true} fullScreen={true} open={this.state.openFilter} PaperProps={{
          className: "dialogGrant"
        }}
                onClose={() => this.setState({openFilter: false})}
                aria-labelledby="form-dialog-title">
          <div className={"dealHeader"}>
            <Clear onClick={() => this.setState({openFilter: false})} className={"dealHeaderIcon"}/>
          </div>
          <div className={"globalCenter dealText"}>
            <p>{i18next.t("selfie.grantSentenceFilter")}</p>
            <div className={"buttons"}>
              <Link onClick={() => {
                this.setState({filter: 0, openDeal: true, openFilter: false});
              }}>
                <div className={"buttonRoundSelfie"}>
                  <Clear className={"buttonRoundSelfieIcon"}/>
                </div>
              </Link>
              <Link onClick={() => {
                this.setState({filter: 1, openDeal: true, openFilter: false});
              }}>
                <div className={"buttonRoundSelfie"}>
                  <Done className={"buttonRoundSelfieIcon"}/>
                </div>
              </Link>
            </div>
          </div>
        </Dialog>

      </>
    )
      ;
  }
}

const mapStateToProps = (state) => ({
  emails: state.session.emails,
});

export default connect(mapStateToProps)(CameraFeed)
