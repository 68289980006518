import ActionTypes from '../constants/actionTypes'
import store from '../store'

export const setEmail = (emails) => {
  store.dispatch({ type: ActionTypes.SET_EMAIL, value:emails }) ;
} ;

export const setToken = (token) => {
  localStorage.setItem("session_tablet_customer", token) ;
  store.dispatch({ type: ActionTypes.SET_NEW_TOKEN, value:token }) ;
} ;

export const setStartNewYear = (startNewYear) => {
  store.dispatch({ type: ActionTypes.SET_START_NEW_YEAR, value:startNewYear }) ;
} ;

export const setEvent = (event) => {
  store.dispatch({ type: ActionTypes.SET_EVENT, value:event }) ;
} ;

export const setShowPartners = (showPartners) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_PARTNERS, value:showPartners }) ;
} ;

export const setBirthday = (birthday) => {
  store.dispatch({ type: ActionTypes.SET_BIRTHDAY, value:birthday }) ;
} ;

export const setOnlyAds = (onlyAds) => {
  store.dispatch({ type: ActionTypes.SET_ONLY_ADS, value:onlyAds }) ;
} ;

export const setOrder = order => {
  store.dispatch({ type: ActionTypes.SET_ORDER, value:order }) ;
} ;

export const setColors = colors => {
  store.dispatch({ type: ActionTypes.SET_COLORS, value:colors }) ;
} ;

export const setNameEstablishment = nameEstablishment => {
  localStorage.setItem("name_establishment", nameEstablishment) ;
  store.dispatch({ type: ActionTypes.SET_NAME_ESTABLISHMENT, value:nameEstablishment }) ;
} ;

export const setMinPrice = minPrice => {
  store.dispatch({ type: ActionTypes.SET_MIN_PRICE, value:minPrice }) ;
} ;

export const setChatOff = chatOff => {
  store.dispatch({ type: ActionTypes.SET_CHAT_OFF, value:chatOff }) ;
} ;

export const setPin = pin => {
  store.dispatch({ type: ActionTypes.SET_PIN, value:pin }) ;
}

export const setWelcome = welcome => {
  store.dispatch({ type: ActionTypes.SET_WELCOME, value:welcome }) ;
}

export const setRefill = refill => {
  store.dispatch({ type: ActionTypes.SET_REFILL, value:refill }) ;
}

export const setShowRefill = refill => {
  store.dispatch({ type: ActionTypes.SET_SHOW_REFILL, value:refill }) ;
}

export const setNewLogo = logo => {
  store.dispatch({ type: ActionTypes.SET_NEW_LOGO, value:logo }) ;
}

export const setShowLegalMention = showLegalMention => {
  store.dispatch({ type: ActionTypes.SHOW_LEGAL_MENTION, value:showLegalMention }) ;
}

export const setStateContainer = stateContainer => {
  store.dispatch({ type: ActionTypes.SET_STATE_CONTAINER, value:stateContainer }) ;
}

export const setLaunchAd = launchAd => {
  store.dispatch({ type: ActionTypes.SET_LAUNCH_AD, value:launchAd }) ;
}

export const setCurrency = currency => {
  store.dispatch({ type: ActionTypes.SET_CURRENCY, value:currency }) ;
}

export const refreshMessage = message => {
  store.dispatch({ type: ActionTypes.REFRESH_MESSAGES, value:message }) ;
}

export const newChat = chat => {
  store.dispatch({ type: ActionTypes.NEW_CHAT, value:chat }) ;
}

export const setSelectedChat = chat => {
  store.dispatch({ type: ActionTypes.SET_SELECTED_CHAT, value:chat }) ;
}

export const setPremiumPartner = premiumPartner => {
  store.dispatch({ type: ActionTypes.SET_NEW_PREMIUM_PARTNER, value:premiumPartner }) ;
}

export const setPartners = partners => {
  store.dispatch({ type: ActionTypes.SET_NEW_PARTNERS, value:partners }) ;
}

export const setAdLaunched = adLaunched => {
  store.dispatch({ type: ActionTypes.SET_AD_LAUNCHED, value:adLaunched }) ;
}

export const setIgnorePlug = ignorePlug => {
  store.dispatch({ type: ActionTypes.SET_IGNORE_PLUG, value:ignorePlug }) ;
}
