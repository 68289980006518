import { combineReducers } from 'redux'
import session from './session'
import language from './language'
import menu from './menu'
import cart from './cart'
import billing from "./billing";
import route from "./route";

const rootReducer = combineReducers({
  session,
  language,
  menu,
  cart,
  route,
  billing
}) ;

export default rootReducer
