import ActionTypes from '../constants/actionTypes'

const initialState = {
  route : ""
};

export default function route(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_NEW_ROUTE:
      return {
        ...state,
        route: action.value
      };
    default:
      return state
  }
}
