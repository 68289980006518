import React, {useEffect, useRef} from "react";
import './css/birthday.css';
import Link from "@material-ui/core/Link";
import Confetti from "react-confetti";
import {connect} from "react-redux";
import {setBirthday, setShowLegalMention, setShowPartners} from "../../actions/session";
import {setSos, setSosChoice} from "../../actions/menu";

const Birthday = (props) => {

  const animation = useRef(null);

  let reverse = true;

  const animateFunction = () => {
    let timeoutSave = setTimeout(() => {

      if (animation.current === null) {
        clearTimeout(timeoutSave);
        return;
      } else if (reverse) {
        animation.current.style.background = "#000";
        animation.current.style.color = "white";
      } else {
        animation.current.style.background = "#FFF";
        animation.current.style.color = "black";
      }

      reverse = !reverse;

      animateFunction();

    }, 150)
  }

  React.useEffect(() => {
    if (props.birthday !== null) {
      setSos(false)
      setSosChoice(null)
      setShowLegalMention(false)
      setShowPartners(false)
    }
  }, [props.birthday])

  useEffect(() => {
    animateFunction();
  });

  return (
    <>
      {props.birthday !== null && (
        <Link onClick={() => setBirthday(null)}>
          <div ref={animation} className={"birthday"} style={{background: "white"}}>

            <Confetti numberOfPieces={200}/>

            <div className={"center content"}>
              HAPPY BIRTHDAY
              <br/>
              <br/>
              <span className={"name"}>{props.birthday}</span>
            </div>
          </div>
        </Link>
      )}
    </>
  );

}

const mapStateToProps = (state) => ({
  birthday: state.session.birthday,
});

export default connect(mapStateToProps)(Birthday)
