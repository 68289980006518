import ActionTypes from '../constants/actionTypes'
import store from '../store'

export const setMenu = (menu) => {
  localStorage.setItem("menu", JSON.stringify(menu)) ;
  store.dispatch({ type: ActionTypes.SET_NEW_MENU, value:menu }) ;
} ;

export const setSos = (sos) => {
  store.dispatch({ type: ActionTypes.SET_SOS, value:sos }) ;
}

export const setSosChoice = (choice) => {
  store.dispatch({ type: ActionTypes.SET_SOS_CHOICE, value:choice }) ;
}

export const setShowValidateModal = (validateModal) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_VALIDATE_MODAL, value:validateModal }) ;
}

export const setShowError = (error) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_ERROR, value:error }) ;
}

export const setShowSuccess = (success) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_SUCCESS, value:success }) ;
}

export const setSizeChoice = (sizeChoice) => {
  store.dispatch({ type: ActionTypes.SET_SIZE_CHOICE, value:sizeChoice }) ;
}

export const setShowSoft = (showSoft) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_SOFT, value:showSoft }) ;
}

export const setShowSoftGlass = (showSoft) => {
  store.dispatch({ type: ActionTypes.SET_SHOW_SOFT_GLASS, value:showSoft }) ;
}

export const setSelectedSoft = (selectedSoft) => {
  store.dispatch({ type: ActionTypes.SET_SELECTED_SOFT, value:selectedSoft }) ;
}

export const setIdArticleSelected = (idArticleSelected) => {
  store.dispatch({ type: ActionTypes.SET_ID_ARTICLE_SELECTED, value:idArticleSelected }) ;
}

export const setCountSoft = (countSoft) => {
  store.dispatch({ type: ActionTypes.SET_COUNT_SOFT, value:countSoft }) ;
}

export const setChoice = (choice) => {
  store.dispatch({ type: ActionTypes.SET_CHOICE, value:choice }) ;
}

export const setAddingChoice = (addingChoice) => {
  store.dispatch({ type: ActionTypes.SET_ADDING_CHOICE, value:addingChoice }) ;
}

export const setErrorRefused = (errorRefused) => {
  store.dispatch({ type: ActionTypes.SET_ERROR_REFUSED, value:errorRefused }) ;
}

export const setPromos = (promos) => {
  store.dispatch({ type: ActionTypes.SET_PROMOS, value:promos }) ;
}

export const setRefreshPromos = (refreshPromos) => {
  store.dispatch({ type: ActionTypes.SET_REFRESH_PROMOS, value:refreshPromos }) ;
}

let timeout = null ;

export const setBackDrop = (boolean) => {

  if(timeout) {
    clearTimeout(timeout)
  }

  timeout = setTimeout(() => {
    if(boolean) {
      setBackDrop(false) ;
    }
  }, 30000)

  store.dispatch({ type: ActionTypes.SET_BACK_DROP, value:boolean }) ;
}
