import ActionTypes from '../constants/actionTypes'
import store from '../store'
import i18next from "i18next";
import {setStateContainer} from "./session";

export const setLanguage = (language, redirect = true) => {
  localStorage.setItem("language", language) ;
  i18next.changeLanguage(language) ;
  if(redirect)
    setTimeout(() => setStateContainer("menu"), 6000) ;
  store.dispatch({ type: ActionTypes.SET_NEW_LANG, value:language })
} ;

