import React from "react";
import "./css/bottleSize.css"
import Link from "@material-ui/core/Link";
import {Add, Close, Remove} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import {BackEndLink, CART_ANIMATION, loggedParams, MENU_CHOICE} from "../../constants/global";
import {connect} from "react-redux";
import {
  setAddingChoice, setBackDrop,
  setChoice, setCountSoft,
  setSelectedSoft,
  setShowSoft,
  setShowValidateModal,
  setSizeChoice
} from "../../actions/menu";
import {addNotValidated, setAnimateCart} from "../../actions/cart";
import axios from "axios";
import {setStateContainer} from "../../actions/session";
import i18next from "i18next";
import IconButton from "@material-ui/core/IconButton";

const BottleSizeChoice = (props) => {

  const [size, setSize] = React.useState(0);
  const [selected, setSelected] = React.useState(-1);
  const [showBalls, setShowBalls] = React.useState(false);

  React.useEffect(() => {

    const choices = props.sizeChoice.choices;

    if (!choices) {
      return;
    }

    const divHeight = 80;

    setSize(divHeight / choices.length);

    let counter = 1;

    Object.keys(choices).forEach((idx) => {

      if(props.promos && props.promos.length > 0) {
        props.promos.forEach(p => {
          if(p.id_bottle === choices[idx].id_article) {
            const end = new Date(p.end_promo).getTime()
            const now = new Date().getTime()

            if(end - now < 0)
              return ;

            choices[idx].promo = p
          }

        })
      }
      choices[idx].counter = counter;
      counter += 1;
    })

  }, [props.sizeChoice.choices]);

  const handleClose = () => {
    setShowBalls(false);
    setTimeout(() => {
      setSelected(null);
    }, 1000)
    setSizeChoice({...props.sizeChoice, open: false});
  }

  const addCartMenuChoice = (idArticleChoice, sizeBottle) => {
    setChoice(true);
    setSelectedSoft([]);
    setAddingChoice({idArticleChoice, sizeBottle});
  }

  const addCartNotHardChoice = (idArticleC, sizeBottle) => {

    setAnimateCart(CART_ANIMATION.LOADING);
    const params = loggedParams.valueOf();
    params.set("id_article", idArticleC);
    params.set("id_menu_choice", sizeBottle.id_menu_choice);
    params.set("id_article_choice", sizeBottle.id_article);
    params.set("quantity", props.sizeChoice.quantity);

    setBackDrop(true);

    axios.post(`${BackEndLink}/cart/addChoice`, params).then(result => {
      params.delete("id_article");
      params.delete("id_menu_choice");
      params.delete("id_article_choice");
      params.delete("quantity");
      setBackDrop(false);
      if (result.data.error === 0) {
        addNotValidated();
        setTimeout(() => {
          setAnimateCart(CART_ANIMATION.SUCCESS);
          setShowValidateModal(true);
        }, 750)
      } else {
        setStateContainer("waiting");
      }
    })
  };

  const addCart = (soft_count_article = null) => {

    if (soft_count_article) {
      setCountSoft(soft_count_article);
    }

    if ((props.sizeChoice.category === MENU_CHOICE.VODKA || props.sizeChoice.category === MENU_CHOICE.GIN || props.sizeChoice.category === MENU_CHOICE.WHISKY || props.sizeChoice.category === MENU_CHOICE.TEQUILA
      || props.sizeChoice.category === MENU_CHOICE.RHUM || props.sizeChoice.category === MENU_CHOICE.LIQUOR) && (props.countSoft > 0 || soft_count_article > 0)) {
      setTimeout(() => {
        setShowSoft(true);
      }, 1000)
      handleClose();
      addCartMenuChoice(props.sizeChoice.idArticle, props.sizeChoice);
    } else {
      handleClose();
      addCartNotHardChoice(props.sizeChoice.idArticle, props.sizeChoice);
    }
  }

  return (
    <>
      {
        props.sizeChoice.open &&
        <Link onClick={handleClose}>
          <div className={"emptyZone"}/>
        </Link>
      }

      <div
        className={props.sizeChoice.open ? "bottleSizeContainer bottleSizeContainerShow" : props.showSoft ? "bottleSizeContainer" : "bottleSizeContainer"}>
        <div className={"bottleSizeLess"}>
          <Close/>
        </div>
        <div className={"bottleSizeTitle"} style={{
          color: props.colors.fontAndCategoryColor
        }}>
          {!props.onlyAds && i18next.t("order.bottleSize")}
        </div>

        <div className={"bottleSizeImageContainer"}>
          <img alt={"Product"} className={"bottleSizeImage"} src={props.sizeChoice.image}/>
        </div>

        {
          props.sizeChoice.open &&
          <div style={{height: `${props.sizeChoice.height + 1}vh`}} className={"bottleSizeGrow"}>
            {
              showBalls && (
                <>
                  <div id={"stars"}/>
                  <div id={"stars2"}/>
                  <div id={"stars3"}/>
                </>
              )
            }
          </div>
        }

        <div className={"bottleContainer"}>
          {
            props.sizeChoice.choices && [...props.sizeChoice.choices].reverse().map((choice) => (
              <div key={choice.name_article} className={"bottleContainerLegendChoice"}
                   style={{
                     height: `${size}vh`,
                     position: "absolute",
                     width: "100vw",
                     bottom: `${(choice.counter - 1) * size}vh`
                   }}>

                <div className={"bottleSizeBorder"} style={{top: `0vh`}}/>

                <Grid
                  className={selected === -1 ? "bottleLegendContainer" : choice.counter < selected ? "bottleLegendContainer bottleLegendBottom" : choice.counter === selected ? "bottleLegendContainer bottleLegendSelected" : "bottleLegendContainer bottleLegendContainerEmpty"}
                  container justify={"center"} alignContent={"center"} alignItems={"center"}>

                  {
                    choice.promo && (
                      <div className={"discountBottleChoice"}><div className="discount-label red"><span>-{choice.promo.promo}%</span></div></div>
                    )
                  }

                  <Grid item xs={4} className={"tc"}>
                    <div className={"bottleLegendLabel"} style={{
                      color: choice.counter !== selected ? props.colors.fontAndCategoryColor : ""
                    }}>
                      {choice.name_article}
                    </div>
                    <div className={"bottleLegendDesc"}>
                      {choice.desc_article}
                    </div>
                  </Grid>

                  <Grid item xs={5} className={"bottleLegendPrice"}>
                    <div className={"cartActionPriceEuro"}>{props.currency}</div>
                    <span style={{
                      color: props.colors.buttonOrderColor
                    }}>

                      {
                        !choice.promo ? parseInt(choice.additionnal_cost, 0) + parseInt(props.sizeChoice.price, 0)
                                      : (parseInt(choice.additionnal_cost, 0) + parseInt(props.sizeChoice.price, 0)) - ((parseInt(choice.additionnal_cost, 0) + parseInt(props.sizeChoice.price, 0)) * (parseInt(choice.promo.promo,10)/100))
                      }
                    </span>
                  </Grid>

                  {
                    !props.onlyAds && (
                      <Slide direction={"left"} in={selected === choice.counter}
                             timeout={{enter: 400, exit: 400}}
                      >
                        <Grid style={{zIndex: 1000000}} item xs={12}>
                          <Link onClick={() => addCart(choice.soft_count_article)} underline={"none"}>
                            <div className={"yellowButtonFull choiceBottleButton"} style={{
                              backgroundColor: props.colors.buttonOrderColor,
                              color: props.colors.fontAndCategoryColor
                            }}>
                              {i18next.t("cart.add")}
                            </div>
                          </Link>
                          <div className={"quantitySelection"} style={{
                            color: "white",
                          }}>
                            <IconButton disabled={props.sizeChoice.quantity <= 1} onClick={() => setSizeChoice({...props.sizeChoice, quantity: props.sizeChoice.quantity - 1})} color="secondary" aria-label="add an alarm">
                              <Remove style={{fontSize: "1.5em", color: props.sizeChoice.quantity <= 1 ? "" : "white"}} fontSize="large"/>
                            </IconButton>
                            x{props.sizeChoice.quantity}
                            <IconButton color="secondary" onClick={() => setSizeChoice({...props.sizeChoice, quantity: props.sizeChoice.quantity + 1})} aria-label="add an alarm">
                              <Add style={{fontSize: "1.5em", color: "white"}} fontSize="large"/>
                            </IconButton>
                          </div>
                        </Grid>
                      </Slide>
                    )
                  }

                </Grid>


                {
                  selected !== choice.counter && (
                    <Link key={choice.name_article} onClick={() => {
                      setTimeout(() => {
                        setShowBalls(true);
                      }, 600)
                      setShowBalls(false);
                      setSelected(choice.counter);
                      setSizeChoice({
                        ...props.sizeChoice,
                        height: choice.counter * size,
                        id_article: choice.id_article,
                        id_menu_choice: choice.id_menu_choice,
                        name_article: choice.name_article,
                        additionnal_cost: parseInt(choice.additionnal_cost, 0) + parseInt(props.sizeChoice.price, 0)
                      });
                    }}>
                      <div style={{height: `${size}vh`}} className={"bottleContainerChoiceBackgroundWaveSelection"}/>
                    </Link>
                  )
                }
              </div>
            ))
          }
        </div>

      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  sizeChoice: state.menu.sizeChoice,
  showSoft: state.menu.showSoft,
  currency: state.session.currency,
  colors: state.session.colors,
  countSoft: state.menu.countSoft,
  onlyAds: state.session.onlyAds,
  promos: state.menu.promos
});

export default connect(mapStateToProps)(BottleSizeChoice)
