import React, {useEffect, useRef} from "react";
import './css/birthday.css';
import Link from "@material-ui/core/Link";
import Confetti from "react-confetti";
import {connect} from "react-redux";
import {setEvent, setShowLegalMention, setShowPartners, setStartNewYear} from "../../actions/session";
import {setSos, setSosChoice} from "../../actions/menu";
import { Fireworks } from 'fireworks-js/dist/react'

const NewYear = (props) => {

  const defaultTimer = 60;
  const animation = useRef(null);
  const [timer, setTimer] = React.useState(defaultTimer)
  const currentTime = React.useRef(defaultTimer)

  let reverse = true;

  const options = {
    brightness: {
      min: 50,
      max: 80,
      decay: {
        min: 0.015,
        max: 0.03
      }
    },
    delay: {
      min: 9
    },
    explosion: 10,
    sound: {
      enabled: true,
      files: [
        "https://fireworks.js.org/sounds/explosion0.mp3",
        "https://fireworks.js.org/sounds/explosion1.mp3",
        "https://fireworks.js.org/sounds/explosion2.mp3"
      ],
      volume: {
        min: 2,
        max: 2
      }
    },
    mouse: {
      click: true,
      move: false,
      max: 1
    },
    speed: 10,
    acceleration: 1.2,
    boundaries: {
      visible: false
    },
    friction: 1
  }

  const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
  }


  const animateFunction = () => {
    let timeoutSave = setTimeout(() => {

      if (animation.current === null) {
        clearTimeout(timeoutSave);
        return;
      } else if (reverse) {
        animation.current.style.background = "#000";
        animation.current.style.color = "white";
      } else {
        animation.current.style.background = "#828181";
        animation.current.style.color = "black";
      }

      reverse = !reverse;

      animateFunction();

    }, 150)
  }

  const timerFunction = () => {
    if (currentTime.current >= 0) {
      currentTime.current = currentTime.current - 1;
      setTimer(currentTime.current)
      setTimeout(timerFunction, 1000)

      if (currentTime.current === 0) {
        animateFunction()
      }
    } else if (currentTime.current >= -45) {
      currentTime.current = currentTime.current - 1;
      setTimer(currentTime.current)
      setTimeout(timerFunction, 1000)
    } else {
      setStartNewYear(false)
    }
  }

  React.useEffect(() => {
    if (props.newYear) {
      animation.current.style.background = "#000";
      animation.current.style.color = "white";
      setSos(false)
      setSosChoice(null)
      setShowLegalMention(false)
      setShowPartners(false)
      timerFunction()
      currentTime.current = defaultTimer
      setTimer(defaultTimer)
    }

  }, [props.newYear])


  return (
    <>
      {props.newYear && (
        <div ref={animation} className={"birthday newYear"} style={{background: "white"}}>

          {
            currentTime.current < 2 && <Confetti numberOfPieces={50}/>
          }

          {
            currentTime.current < 2 && <Fireworks options={options} style={style} />
          }


          <div className={currentTime.current > 0 ? "center font animateDigits" : "center font"}>
            {currentTime.current > 0 ? timer : "HAPPY NEW YEAR"}
          </div>
        </div>
      )}
    </>
  );

}

const mapStateToProps = (state) => ({
  newYear: state.session.newYear,
});

export default connect(mapStateToProps)(NewYear)
