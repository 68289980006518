import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import store from './store'
import Routes from './routes'
import './styles/globalStyles.css'
import * as serviceWorker from './utils/serviceWorker'
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/styles";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      light: '#2c2c2c',
      main: '#134c98',
      dark: '#134c98',
      contrastText: '#fff',
    },
    secondary: {
      light: '#2c2c2c',
      main: '#134c98',
      dark: '#134c98',
      contrastText: '#fff',
    },
  },
});

render(
  <Provider store={store}>
    <ToastContainer/>
    <ThemeProvider theme={theme}>
      <Routes/>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
