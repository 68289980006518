import React, {useEffect, useRef} from 'react'
import Link from "@material-ui/core/Link";
import './css/alertSecurity.css';

const AlertSecurity = (props) => {

  const animation = useRef(null);
  let reverse = true;

  const animateFunction = () => {
    const timeoutSave = setTimeout(() => {
      if (animation.current === null) {
        clearTimeout(timeoutSave);
        return;
      } else if (reverse) {
        animation.current.style.background = props.alertSecurityColor;
        animation.current.style.color = "white";
      } else {
        animation.current.style.background = "#000";
        animation.current.style.color = "red";
      }
      reverse = !reverse;
      animateFunction();
    }, 150)
  }

  useEffect(() => {
    if (props.alertSecurity)
      setTimeout(() => {
        props.setAlertSecurity(false)
      }, 10000)
    animateFunction()
  }, [])

  return (
    <Link onClick={() => props.setAlertSecurity(false)}>
      <div ref={animation} className={"alertSecurity"}>
        <div className={"center content"}/>
      </div>
    </Link>
  );

};

export default AlertSecurity;
