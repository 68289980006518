import ActionTypes from '../constants/actionTypes'

/*
 * How does it works ? If no menu is present in localStorage. While new order is required, the code will retreive the menu.
 * Then, it is saved into localStorage in json format. That means that we have to decode it then.
 * Here is the anwser for json.parse.
 */


const initialState = {
  menu: localStorage.getItem("menu") === null ? undefined : JSON.parse(localStorage.getItem("menu")),
  sos: false,
  showValidateCart: false,
  error: null,
  success: null,
  sizeChoice: {
    choice: null,
    open: false,
    height: 0,
    selected: -1,
    id_menu_choice: 0,
    id_article: 0,
    name_article: "",
    additionnal_cost: 0,
    image: null,
    category: null,
    idArticle: null
  },
  showSoft: false,
  showSoftGlass: false,
  selectedSoft: [],
  idArticleSelected: null,
  choice: false,
  addingChoice: {idArticleChoice: null, sizeBottle: null},
  sosChoice: null,
  errorRefused: null,
  backdrop: false,
  countSoft: 0,
  promos: [],
  refreshPromos: 0
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_NEW_MENU:
      return {
        ...state,
        menu: action.value
      };
    case ActionTypes.SET_SOS:
      return {
        ...state,
        sos: action.value
      };
    case ActionTypes.SET_SHOW_VALIDATE_MODAL:
      return {
        ...state,
        showValidateCart: action.value
      };
      case ActionTypes.SET_SHOW_SUCCESS:
      return {
        ...state,
        success: action.value
      };
      case ActionTypes.SET_SHOW_ERROR:
      return {
        ...state,
        error: action.value
      };
      case ActionTypes.SET_SIZE_CHOICE:
      return {
        ...state,
        sizeChoice: action.value
      };
      case ActionTypes.SET_SHOW_SOFT:
      return {
        ...state,
        showSoft: action.value
      };
      case ActionTypes.SET_SHOW_SOFT_GLASS:
      return {
        ...state,
        showSoftGlass: action.value
      };
      case ActionTypes.SET_SELECTED_SOFT:
      return {
        ...state,
        selectedSoft: action.value
      };
      case ActionTypes.SET_ID_ARTICLE_SELECTED:
      return {
        ...state,
        idArticleSelected: action.value
      };
      case ActionTypes.SET_CHOICE:
      return {
        ...state,
        choice: action.value
      };
      case ActionTypes.SET_ADDING_CHOICE:
      return {
        ...state,
        addingChoice: action.value
      };
      case ActionTypes.SET_SOS_CHOICE:
      return {
        ...state,
        sosChoice: action.value
      };
      case ActionTypes.SET_ERROR_REFUSED:
      return {
        ...state,
        errorRefused: action.value
      };
      case ActionTypes.SET_BACK_DROP:
      return {
        ...state,
        backdrop: action.value
      };
      case ActionTypes.SET_COUNT_SOFT:
      return {
        ...state,
        countSoft: action.value
      };
      case ActionTypes.SET_PROMOS:
      return {
        ...state,
        promos: action.value
      };
      case ActionTypes.SET_REFRESH_PROMOS:
      return {
        ...state,
        refreshPromos: action.value
      };
    default:
      return state
  }
}
