import React from 'react'
import './css/lightedOff.css'
import i18next from "i18next";

const LightedOff = () => (
  <>
    <div className={"lightedOffBG"}>
      <div className={"lightedOffContainer"}>
        <img alt="logo" className={"lightedOffLogo"} src={"logo_texture.svg"}/>
        <p className={"lightedOffMessage"}>{i18next.t('global.lightedOff')}</p>
      </div>
    </div>
  </>
)

export default LightedOff
