import React from 'react'
import axios from "axios"
import {Button, Card, CardActions, Grid, TextField, Typography} from "@material-ui/core";
import {Alert, Skeleton} from '@material-ui/lab';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Weekend from "@material-ui/icons/Weekend";
import {BackEndLink} from "../../constants/global";
import {toast} from 'react-toastify';
import {setToken} from '../../actions/session'
import i18next from 'i18next';
import CardHeader from "@material-ui/core/CardHeader";
import Language from "./Language";
import CardContent from "@material-ui/core/CardContent";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3'
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import {Cached, Replay} from "@material-ui/icons";

const Login = () => {

  const [captcha, setCaptcha] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [message, setMessage] = React.useState("Initialization of the captcha module ");
  const [progress, setProgress] = React.useState(0);

  const [state, setState] = React.useState({
    loading: false,
    step: 0,
    idEstablishment: undefined,
    establishements: {
      id_establishment: undefined,
      name_establishment: undefined,
      town_establishment: undefined
    },
    error: false,
    passwordField: "",
    tables: [{
      id_table: undefined,
      name_table: undefined
    }]
  });

  React.useEffect(() => {
    loadReCaptcha("6Ld4J8YZAAAAAMReux9tmNXAhuj4sp4euqEpmXwa", () => {
      setProgress(33);
      setMessage("Getting security token");
    });

    if (window.Android) {
      window.Android.removeIdentity();
    }
  }, []);

  const handleSelectEstablishment = () => {
    if (state.establishements.id_establishment !== undefined) {
      setState({...state, step: state.step + 1, idEstablishment: state.establishements.id_establishment});
    }
  };

  const lookUpEstablishment = (e) => {
    setState({...state, loading: true, establishements: {}, error: false});

    axios.get(`${BackEndLink}/Login/getEstablishments/${e.target.value}`).then(response => {
      const data = response.data;
      if (data.errorCode === undefined)
        setState({...state, loading: false, establishements: data, error: false});
      else {
        setState({...state, loading: false, establishements: {}, error: true});
      }
    });
  };

  const lookUpEstablishmentPassWord = () => {
    captcha.execute();
  };

  const handleClickSelectTable = (idTable) => {
    const params = new URLSearchParams();
    params.append('password_establishment', state.passwordField);
    params.append('id_table', idTable);

    axios.post(`${BackEndLink}/Login/linkTablet/${state.idEstablishment}`, params).then(response => {
      const data = response.data;
      if (data.errorCode === 0) {
        localStorage.setItem("id_table", data.id_table);
        localStorage.setItem("id_tablet_customer", data.id_tablet_customer);
        localStorage.setItem("id_establishment", data.id_establishment);
        localStorage.setItem("name_establishment", state.establishements.name_establishment);

        if (window.Android) {
          window.Android.changeIdentity(data.id_establishment, data.id_tablet_customer);
        }

        setToken(data.session_tablet_customer);
        window.location.reload();
      } else if (data.errorCode === 1) {
        toast.error("Erreur dans les données envoyées");
      } else {
        toast.error("Erreur inopinée");
      }
    })
  };

  const reCheck = () => {
    setProgress(33);
    setMessage("Getting security token");
    setChecked(false);
  }

  React.useEffect(() => {
      if (captcha)
        if (!checked)
          captcha.execute()
    },
    [checked, captcha]
  )

  const check = (recaptchaToken) => {
    if (!checked) {

      setProgress(66);
      setMessage("Verifying your identity")

      const params = new URLSearchParams();
      params.set('response', recaptchaToken);

      axios.post(`${BackEndLink}/Login/reCaptcha`, params).then(response => {
        params.delete("response");
        if (response.data.success) {
          setProgress(100)
          setMessage("The server has accepted your request")
          setTimeout(() => {
            setChecked(true)
          }, 1000)
        } else {
          setMessage("Error, you may be a bot")
        }
      })
    } else {
      setState({...state, loading: true, error: false});

      const params = new URLSearchParams();
      params.append('password_establishment', state.passwordField);
      params.append('response', recaptchaToken);

      axios.post(`${BackEndLink}/Login/getEstablishmentsPasswordAndTables/${state.idEstablishment}`, params).then(response => {
        const data = response.data;
        if (data.errorCode === 0) {
          setState({
            ...state,
            loading: false,
            error: false,
            passwordCheck: true,
            step: state.step + 1,
            tables: data.tables
          });
        } else
          setState({...state, loading: false, error: true, passwordCheck: false});
      });
    }
  }


  const firstStep = () => (
    <>

      <Grid item xs={12} style={{marginBottom: "1em"}}>
        {i18next.t("login.step1.title")}
      </Grid>
      <Divider style={{width: "100%", marginTop: ".5em", marginBottom: "1em"}}/>
      <Grid item xs={12}>
        <TextField type={"number"} onChange={lookUpEstablishment} id="standard-basic"
                   label={i18next.t("login.step1.field1")}/>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{marginTop: "1em", marginBottom: "2em"}}
        >
          <Grid item xs={12}>
            {state.loading && (<Skeleton animation={"wave"} variant={"rect"} height={30}/>)}
            {state.establishements.id_establishment !== undefined && (
              <>
                <Typography>
                  {`${state.establishements.id_establishment} - ${state.establishements.name_establishment} (${state.establishements.town_establishment})`}
                </Typography>
                <Divider style={{width: "100%", marginTop: ".5em", marginBottom: "1em"}}/>
                <Button variant="contained" onClick={handleSelectEstablishment}
                        color="primary">{i18next.t("login.step1.select")}</Button>
              </>
            )}
            {state.error && <Alert severity="error">{i18next.t("login.step1.notFound")}</Alert>}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const secondStep = () => (
    <>
      <Grid item xs={12} style={{marginBottom: "1em"}}>
        {i18next.t("login.step2.title")}
      </Grid>
      <Grid item xs={12}>
        <div>
          <TextField type={"password"} value={state.passwordField} onChange={(e) => {
            setState({...state, passwordField: e.target.value})
          }} id="standard-basic" label={i18next.t("login.step2.field1")}/>
        </div>
        <Divider style={{width: "100%", marginTop: ".5em", marginBottom: "1em"}}/>
        <div>
          <Button variant="contained" onClick={lookUpEstablishmentPassWord}
                  color="primary">{i18next.t("login.step2.validate")}</Button>
        </div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{marginTop: "1em", marginBottom: "2em"}}
        >
          <Grid item xs={12}>
            {state.error && <Alert severity="error">{i18next.t("login.step2.wrong")}</Alert>}
          </Grid>

          <Grid item xs={12}>
            {state.loading && <Alert severity="info">Checking if this request is permitted</Alert>}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const thirdStep = () => (
    <>
      <Grid item xs={12} style={{marginBottom: "1em"}}>
        {i18next.t("login.step3.title")}
      </Grid>
      <Grid item xs={12}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {state.tables.length > 0 && (
            state.tables.map((table) => (
              <ListItem key={table.name_table} button onClick={() => handleClickSelectTable(table.id_table)}>
                <ListItemIcon>
                  <Weekend/>
                </ListItemIcon>
                <ListItemText primary={table.name_table}/>
              </ListItem>
            ))
          )}
        </List>
      </Grid>
    </>
  );

  return (
    <React.Fragment>

      <ReCaptcha
        ref={ref => setCaptcha(ref)}
        sitekey="6Ld4J8YZAAAAAMReux9tmNXAhuj4sp4euqEpmXwa"
        action='test'
        verifyCallback={check}
      />

      <Grid
        style={{backgroundImage: `url("/assets/img/menu/bg.png")`, height: "100vh",}}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        {
          !checked && (
            <div style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "black",
              fontSize: "1.2em",
              backgroundColor: "white",
              width: "60vw",
              padding: "1em",
              paddingTop: "2em",
              borderRadius: "2px"
            }}>
              <div style={{
                position: "absolute",
                right: 10,
                top: 0
              }}>
                <IconButton onClick={() => {
                  reCheck()
                }} aria-label="settings">
                  <Replay/>
                </IconButton>
              </div>
              <div style={{
                position: "absolute",
                left: 10,
                top: 0
              }}>
                <IconButton onClick={() => {
                  window.location.reload();
                }} aria-label="settings">
                  <Cached/>
                </IconButton>
              </div>
              {message}
              <LinearProgress variant="determinate" value={progress}/>
            </div>
          )
        }

        {
          checked && (
            <Grid item>
              <Card variant="outlined" style={{
                maxHeight: "99vh",
                overflowY:"scroll"
              }}>
                <CardHeader
                  style={{textAlign: "left"}}
                  action={
                    <Language/>
                  }
                  title={
                    <Typography variant="h4" component="p">
                      {i18next.t("login.title")}
                    </Typography>
                  }
                />
                <CardContent>
                  <Divider style={{marginTop: "-1em", marginBottom: "2em", width: "100%"}}/>
                  {state.step === 0 && firstStep()}
                  {state.step === 1 && secondStep()}
                  {state.step === 2 && thirdStep()}
                </CardContent>
                <CardActions/>
              </Card>
            </Grid>
          )
        }
      </Grid>

    </React.Fragment>
  );
};

export default Login;
