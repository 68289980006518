import React from "react" ;
import Typography from "@material-ui/core/Typography";
import '../Menu/css/waiting.css' ;
import i18next from "i18next";
import Link from "@material-ui/core/Link";
import {connect} from "react-redux";
import {setShowBill} from "../../actions/billing";
import {LogoLink} from "../../constants/global";
import {setShowSuccess} from "../../actions/menu";

const confetis = false;

const Waiting = (props) => {

  React.useEffect(() => {
    setShowBill(false) ;
  }, [])

  const handleStartOrder = () => {

    const regex = /Réserv/mi;

    if(
      regex.exec(props.welcome) !== null
    ) {
      setShowSuccess("Rendez-vous à l'accueil pour demander l'ouverture de votre tablette.")
    } else {
      props.startNewOrder()
    }

  }

  return (
  <>
    <Link className={"waiting"} onClick={handleStartOrder}>

      {confetis &&
        (
          <div style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
          }}>
            <img src={"/assets/img/menu/confe.gif"} alt={"condeta"}/>
          </div>
        )
      }

      {
        props.logo && (
            <div className={"waitingLogo"} style={{backgroundImage: `url("${LogoLink}${props.logo}")`}}/>
        )
      }

      <div className={"background"}/>

      {
        props.welcome.length > 1 ? <div className={"welcomeName"}>{props.welcome}</div> : <div className={"welcome_nb"}>
          <Typography className={"title"}>{i18next.t("waiting.order_here")}</Typography>
        </div>
      }


      <div className={"bar"}/>

      <div className={"establishment"}>
        <Typography className={"title"}>{props.nameEstablishment}</Typography>
      </div>

      <div className={"welcome"}>
        <Typography className={"title"}>{i18next.t("waiting.welcome")}</Typography>
      </div>

      <div className={"bar right"}/>

      <div className={"buttonFinger"}>
        <img alt="click here" src={`/assets/img/menu/main blanche.png`}/>
      </div>
    </Link>
  </>
)};

const mapStateToProps = (state) => ({
  welcome: state.session.welcome,
  nameEstablishment: state.session.nameEstablishment,
  logo: state.session.logo
});

export default connect(mapStateToProps)(Waiting)
