import React from "react";
import Draggable from 'react-draggable';
import {Card} from "@material-ui/core";
import i18next from "i18next";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {BackEndLink, loggedParams} from "../../constants/global";
import './css/seatingPlan.css'
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Skeleton from "@material-ui/lab/Skeleton";
import Flicking from "@egjs/react-flicking";

const SeatingPlan = props => {

  const [entities, setEntities] = React.useState([])
  const [currentFloor, setCurrentFloor] = React.useState(0);
  const [selectedTable, setSelectedTable] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [buttons, setButtons] = React.useState({
    prev: false,
    next: false
  });
  const idTable = parseInt(localStorage.getItem("id_table"), 0);
  const [floors, setFloors] = React.useState([]);
  const _plugins = [];
  const flicking = React.createRef();

  React.useEffect(() => {
    setButtons({
      prev: (entities.filter(e => parseInt(e.floor, 0) === (currentFloor - 1)).length) > 0,
      next: (entities.filter(e => parseInt(e.floor, 0) === (currentFloor + 1)).length) > 0
    })

    const entitiesFloors = entities.map(e => parseInt(e.floor));
    const max = Math.max(...entitiesFloors);
    const min = Math.min(...entitiesFloors);
    const tempFloors = [];
    for (let i = min; i <= max; i++) {
      tempFloors.push(i)
    }
    setFloors(tempFloors);

  }, [entities, currentFloor])

  const printEntity = (index, entity, type) => {
    if (type !== "wall" && type !== "text") {

      let picture = `${type}.svg`;

      if (type === "entrance") {
        picture = "door.svg"
      } else if (type === "cloakroom") {
        picture = "cloakroom.png"
      }

      if (type === "table") {
        if (parseInt(entity.variant) === 1) {
          picture = "table_vip.svg"
        }
      }

      return (
        <Draggable
          onMouseDown={() => {
            if (entity.type === "table" && parseInt(entity.id_table, 0) !== idTable) {
              setSelectedTable(index)
            }
          }}

          disabled={true}
          key={`entity_${entity.id_seating_plan}`}
          bounds="parent" position={{x: parseInt(entity.x, 0), y: parseInt(entity.y, 0)}}>
          <div style={{position: "absolute"}}>

            <div
              className={parseInt(entity.id_table, 0) === idTable ? "userTable seatingPlanContainerEntitiesTable" : selectedTable === index ? "selectedTable seatingPlanContainerEntitiesTable" : type === "table" ? "seatingPlanContainerEntitiesTable" : "seatingPlanContainerEntitiesWall"}
              style={{
                transform: `rotate(${entity.rotation}deg)`,
                backgroundImage: `url("/assets/plan/${picture}")`,
                height: `${(100 * entity.size)}px`,
                width: `${(100 * entity.size)}px`,
              }}/>
            {
              /**
               * entity.type === "table" &&
                <div className={"tableLegend"} style={{fontSize: `${1.7 * entity.size}em`}}>{entity.id_table}</div>
               **/
            }
          </div>
        </Draggable>
      )
    } else if (type === "wall") {
      return (
        <Draggable
          disabled={true}
          key={`entity_${entity.id_seating_plan}`}
          bounds="parent" position={{x: parseInt(entity.x, 0), y: parseInt(entity.y, 0)}}>
          <div style={{position: "absolute"}}>
            <div className={"seatingPlanContainerEntities"} style={{
              transform: `rotate(${entity.rotation}deg)`,
              background: `black`,
              width: `2px`,
              height: `${(50 * entity.size)}px`

            }}/>
          </div>
        </Draggable>
      )
    }

    return (
      <Draggable
        disabled={true}
        key={`entity_${entity.id_seating_plan}`}
        bounds="parent" position={{x: parseInt(entity.x, 0), y: parseInt(entity.y, 0)}}>
        <div style={{position: "absolute"}}>
          <div className={"seatingPlanContainerEntities"} style={{
            width: `150px`,
            height: `20px`
          }}/>
          <div className={"seatingPlanContainerEntitiesNameText"} style={{
            fontSize: `${0.5 * entity.size}em`,
            transform: `rotate(${entity.rotation}deg)`
          }}>
            {entity.name}
          </div>

        </div>
      </Draggable>
    )

  }

  const floorUp = () => {
    setCurrentFloor(currentFloor + 1)
  }

  const floorDown = () => {
    setCurrentFloor(currentFloor - 1)
  }

  const getEntities = () => {
    setLoading(true)
    axios.post(`${BackEndLink}/features/get_seating_plan`, loggedParams).then(result => {
      setEntities(result.data)
      setLoading(false)
    })
  }

  React.useEffect(() => {
    if (entities.length === 0) {
      getEntities()
    }
  }, [props.open])

  React.useEffect(() => {
    getEntities()
  }, [])

  if (props.open)
    return (
      <>
        <div className="seatingPlanModal">
          <div className={"seatingPlanModalText"}>
            {i18next.t("seatingPlan.nb")}
          </div>

          <div className={"seatingPlanModalTextRoom"}>
            {
              currentFloor === 0 ? (
                i18next.t("seatingPlan.mainRoom")
              ) : (
                `${i18next.t("seatingPlan.room")} ${currentFloor}`
              )
            }

          </div>

          <Grid container className={"cancelButton"} spacing={4}>
            <Grid item>
              <div className={"yellowButtonFull"} onClick={() => props.setOpenSeatingPlan(false)}>
                {i18next.t("seatingPlan.cancel")}
              </div>
            </Grid>
          </Grid>

          <Zoom in={selectedTable !== null} unmountOnExit>
            <Grid container className={"selectButton"} justify={"center"} alignItems={"center"} alignContent={"center"}
                  spacing={4}>
              <Grid item>
                <div className={"yellowButtonFull"} onClick={() => {
                  props.setOpenSeatingPlan(false)
                  props.setChatTable(entities[selectedTable].id_table)
                  setSelectedTable(null);
                }}>
                  {i18next.t("seatingPlan.select")}
                </div>
              </Grid>
            </Grid>
          </Zoom>

          <Grid container className={""} justify={"center"} alignItems={"center"} alignContent={"center"}>
            <Grid item xs={12}>
              <Card className={"seatingPlanCard"}>
                <Grid container justify={"center"} alignItems={"center"} alignContent={"center"} spacing={4}>
                  <Grid item style={{textAlign: "center"}}>
                    <div
                      className={"userTable seatingPlanContainerEntitiesWall seatingPlanLegend"}
                      style={{backgroundImage: `url("/assets/plan/table.svg")`}}/>
                  </Grid>
                  <Grid item>
                    {i18next.t("seatingPlan.userTable")}
                  </Grid>
                  <Grid item style={{textAlign: "center"}}>
                    <div
                      className={"selectedTable seatingPlanContainerEntitiesWall seatingPlanLegend"}
                      style={{backgroundImage: `url("/assets/plan/table.svg")`}}/>
                  </Grid>
                  <Grid item>
                    {i18next.t("seatingPlan.selectedTable")}
                  </Grid>

                  {
                    loading ? (
                      <Grid item className={"seatingPlanContainer"}>
                        <Skeleton animation={"wave"} variant="rect" height={800} width={800}/>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <div className={"plansContainer"}>
                          <Flicking circular={false} infinite={true} plugins={_plugins} ref={flicking}>
                            {
                              floors.map(floor => (
                                <div className="card-panel" style={{left: `${floor * 681.583}px`}} key={floor}>
                                  <div className={"seatingPlanContainer"}>
                                    {
                                      Object.keys(entities).map(i => {
                                        const entity = entities[i];

                                        if (parseInt(entity.floor) === floor)
                                          return printEntity(i, entity, entity.type);

                                      })
                                    }
                                  </div>
                                </div>
                              ))
                            }
                          </Flicking>
                        </div>
                      </Grid>
                    )
                  }
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
    )

  return ""
}

export default SeatingPlan
