import React, {useEffect, useRef} from "react";
import './css/birthday.css';
import Link from "@material-ui/core/Link";
import Confetti from "react-confetti";
import {connect} from "react-redux";
import {setEvent, setShowLegalMention, setShowPartners} from "../../actions/session";
import {setSos, setSosChoice} from "../../actions/menu";
import { Fireworks } from 'fireworks-js/dist/react'

const Event = (props) => {

  const animation = useRef(null);

  let reverse = true;

  const options = {
    brightness: {
      min: 50,
      max: 80,
      decay: {
        min: 0.015,
        max: 0.03
      }
    },
    delay: {
      min: 9
    },
    explosion: 10,
    sound: {
      enabled: true,
      files: [
        "https://fireworks.js.org/sounds/explosion0.mp3",
        "https://fireworks.js.org/sounds/explosion1.mp3",
        "https://fireworks.js.org/sounds/explosion2.mp3"
      ],
      volume: {
        min: 2,
        max: 2
      }
    },
    mouse: {
      click: true,
      move: false,
      max: 1
    },
    speed: 10,
    acceleration: 1.2,
    boundaries: {
      visible: false
    },
    friction: 1
  }

  const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
  }

  const animateFunction = () => {
    let timeoutSave = setTimeout(() => {

      if (animation.current === null) {
        clearTimeout(timeoutSave);
        return;
      } else if (reverse) {
        animation.current.style.background = "#000";
        animation.current.style.color = "white";
      } else {
        animation.current.style.background = "#FFF";
        animation.current.style.color = "black";
      }

      reverse = !reverse;

      animateFunction();

    }, 150)
  }

  React.useEffect(() => {
    if(props.event.event !== null) {
      setSos(false)
      setSosChoice(null)
      setShowLegalMention(false)
      setShowPartners(false)
    }
  },[props.event])

  useEffect(() => {
    animateFunction();
  });

  return (
    <>
      {props.event.event !== null && (
        <Link onClick={() => setEvent({
          event: null,
          name: null
        })}>
          <div ref={animation} className={"birthday"} style={{background: "white"}}>

            <Confetti numberOfPieces={100}/>
            <Fireworks options={options} style={style} />

            <div className={"center content font event"} style={{
              fontSizeAdjust: 0.5
            }}>
              {props.event.event}
              <br/>
              <br/>
              <span className={"name"}>{props.event.name}</span>
            </div>
          </div>
        </Link>
      )}
    </>
  );

}

const mapStateToProps = (state) => ({
  event: state.session.event,
});

export default connect(mapStateToProps)(Event)
