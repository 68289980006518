import React from "react";
import FirstMenu from "./FirstMenu.jsx";
import {connect} from "react-redux";
import SecondMenu from "./SecondMenu";
import {adTimeout} from "../../constants/global";
import {setLaunchAd} from "../../actions/session";
import {setShowCart} from "../../actions/cart";
import {setShowBill} from "../../actions/billing";
import Refill from "../Features/Refill";
import Promo from "../Features/Promo";
import {Router} from "react-router-dom";


const Menu = (props) => {

  const [currentMenu, setCurrentMenu] = React.useState(0);
  const [menuChoice, setMenuChoice] = React.useState("");
  const [articleFocus, setArticleFocus] = React.useState("");

  const changeMenu = (menu, choice, article = null) => {
    setCurrentMenu(menu);
    setMenuChoice(choice);
    if(article)
      setArticleFocus(article)
    else
      setArticleFocus(null)
  };

  return (
    <>
      <Promo changeMenu={changeMenu} setCurrentMenu={setCurrentMenu} />
      <Refill changeMenu={changeMenu} />
      {currentMenu === 0 && <FirstMenu changeMenu={changeMenu}/>}
      {currentMenu === 1 &&
      <SecondMenu articleFocus={articleFocus} setCurrentMenu={setCurrentMenu} menuChoice={menuChoice} setMenuChoice={setMenuChoice}/>}
    </>
  )
};

const mapStateToProps = (state) => ({
  order: state.session.order,
  notValidated: state.cart.notValidated,
  stateContainer: state.session.stateContainer,
  launchAd: state.session.launchAd
});

export default connect(mapStateToProps)(Menu)
