import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Translate} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {setLanguage} from "../../actions/language";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function Language() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (value) => {
    setLanguage(value);
    handleClose();
  };

  const languages = [
      {
        flag: "🇬🇧",
        value: "en",
        display: "English"
      },
      {
        flag: "🇫🇷",
        value: "fr",
        display: "Français"
      }
    ]
  ;

  return (
    <div>
      <IconButton onClick={handleClick} aria-label="settings">
        <Translate/>
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(language => (
          <StyledMenuItem key={language.value} onClick={() => handleChangeLanguage(language.value)}>
            <ListItemText primary={`${language.flag} ${language.display}`}/>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
