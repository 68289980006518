import ActionTypes from '../constants/actionTypes'

const initialState = {
  token: localStorage.getItem("session_tablet_customer") === null ? undefined : localStorage.getItem("session_tablet_customer"),
  nameEstablishment: localStorage.getItem("name_establishment") === null ? undefined : localStorage.getItem("name_establishment"),
  order : undefined,
  welcome : "",
  refill : "",
  showRefill : false,
  pin: localStorage.getItem("pin") === null ? undefined : localStorage.getItem("pin"),
  logo: null,
  showLegalMention: false,
  stateContainer: "loading",
  launchAd: false,
  currency: null,
  refreshChat: {},
  newChat: [],
  selectedChat: null,
  minPrice: 0,
  chatOff: false,
  premiumPartner: null,
  partners: [],
  adLaunched: false,
  onlyAds: false,
  birthday: null,
  showPartners: false,
  colors: {
    backgroundColor: "#FFF",
    wave1: "#3668b8",
    wave2: "#2A5292",
    wave3: "#24457A",
    buttonsColor: "#5456D3",
    fontAndCategoryColor : '#134c9a',
    buttonOrderColor : '#fec433',
    sosButtonColor : '#aa8ad7',
    chatAndSelfieButtonColor : '#abbcec',
    sosBackgroundColor : '#F8C0C8',
    chatAndSelfieBackgroundColor : '#F5F9E9',
    hardBackgroundColor : '#102130',
    softBackgroundColor : '#E9806E',
    otherBackgroundColor : '#92CED7',
    foodBackgroundColor : '#FFE8D4',
    billBackgroundColor : '#FFFFFF',
    chatAndSelfieTextColor : '#FFFFFF',
    sosTextColor : '#FFFFFF',
    buttonsTextColor : '#FFFFFF'
  },
  event: {
    event: null,
    name: null
  },
  newYear: false,
  emails: {
    email: "",
    email2: "",
    email3: "",
    email4: "",
    email5: ""
  },
  ignorePlug: false
};

export default function session(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_NEW_TOKEN:
      return {
        ...state,
        token: action.value
      };
      case ActionTypes.SET_COLORS:
      return {
        ...state,
        colors: action.value
      };
      case ActionTypes.SET_NAME_ESTABLISHMENT:
      return {
        ...state,
        nameEstablishment: action.value
      };
    case ActionTypes.SET_CHAT_OFF:
      return {
        ...state,
        chatOff: action.value
      };
    case ActionTypes.SET_MIN_PRICE:
      return {
        ...state,
        minPrice: parseFloat(action.value)
      };
    case ActionTypes.REFRESH_MESSAGES:
      return {
        ...state,
        refreshChat: action.value
      };
    case ActionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: action.value
      };
    case ActionTypes.SET_ORDER:
      return {
        ...state,
        order: action.value
      };
    case ActionTypes.SHOW_LEGAL_MENTION:
      return {
        ...state,
        showLegalMention: action.value
      };
    case ActionTypes.SET_PIN:
      return {
        ...state,
        pin: action.value
      };
    case ActionTypes.SET_WELCOME:
      return {
        ...state,
        welcome: action.value
      };
    case ActionTypes.SET_REFILL:
      return {
        ...state,
        refill: action.value
      };
    case ActionTypes.SET_NEW_LOGO:
      return {
        ...state,
        logo: action.value
      };
    case ActionTypes.SET_SHOW_REFILL:
      return {
        ...state,
        showRefill: action.value
      };
    case ActionTypes.SET_STATE_CONTAINER:
      return {
        ...state,
        stateContainer: action.value
      };
    case ActionTypes.SET_LAUNCH_AD:
      return {
        ...state,
        launchAd: action.value
      };
    case ActionTypes.SET_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.value
      };
    case ActionTypes.NEW_CHAT:
      return {
        ...state,
        newChat: action.value
      };
    case ActionTypes.SET_NEW_PREMIUM_PARTNER:
      return {
        ...state,
        premiumPartner: action.value
      };
    case ActionTypes.SET_NEW_PARTNERS:
      return {
        ...state,
        partners: action.value
      };
    case ActionTypes.SET_AD_LAUNCHED:
      return {
        ...state,
        adLaunched: action.value
      };
    case ActionTypes.SET_ONLY_ADS:
      return {
        ...state,
        onlyAds: action.value
      };
    case ActionTypes.SET_BIRTHDAY:
      return {
        ...state,
        birthday: action.value
      };
    case ActionTypes.SET_SHOW_PARTNERS:
      return {
        ...state,
        showPartners: action.value
      };
    case ActionTypes.SET_EVENT:
      return {
        ...state,
        event: action.value
      };
    case ActionTypes.SET_START_NEW_YEAR:
      return {
        ...state,
        newYear: action.value
      };
    case ActionTypes.SET_IGNORE_PLUG:
      return {
        ...state,
        ignorePlug: action.value
      };
    case ActionTypes.SET_EMAIL:
      return {
        ...state,
        emails : action.value
      };
    default:
      return state
  }
}
