import React from "react";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import './css/product.css';
import {AssetsLink} from "../../constants/global";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import i18next from "i18next";
import {connect} from "react-redux";

/**
 * This component is the one that represents a Product inside the Cart component.
 */
const Product = (props) => {

  const {
    picture,
    price,
    category,
    name,
    description,
    state,
    remove,
    softs
  } = props;

  return (
    <>
      <Grid container style={{padding: "1em"}} spacing={2} justify={"center"} alignItems={"center"}
            alignContent={"center"} className={"productCartContainer"}
      >
        <Grid item xs={3}
              style={{
                backgroundImage: props.icon ? `url("${props.icon}")` : `url("${AssetsLink}${picture}")`,
                backgroundSize: props.icon ? "60%" : ""
              }}
              className={"productImageCart"}>
          <div className={"shapeBGCart"}
               style={{backgroundImage: `url(/assets/img/menu/blob${Math.floor(Math.random() * Math.floor(3)) + 1}.png)`}}/>
        </Grid>
        <Grid item xs={7} className={"productDescription"}>
          <p className={"category"} style={{
            color: props.colors.fontAndCategoryColor
          }}>{i18next.t(`categories.${category}`)} - {name}
            {props.bottle_name ? ` (${props.bottle_name})` : ""}</p>

          <p className={"descriptionCart"} style={{
            color: props.colors.fontAndCategoryColor
          }}>
            {props.bottle_size ?
              props.bottle_size : description}
          </p>

          <Grid container className={"softCartContainer"} spacing={4}>
            {
              softs && (
                softs.map(soft => (
                  <Grid item xs={4} className={"softCart"} style={{
                    color: props.colors.fontAndCategoryColor
                  }}>
                    <img alt="product" className={"softCartImage"} src={`${AssetsLink}${soft.article_img}`}/>
                    <br/> <span style={{
                    color: props.colors.fontAndCategoryColor
                  }}>{soft.name_article}</span>
                    <div className={"softPriceArticle"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>
                      +{soft.price_article}{props.currency}
                    </div>
                  </Grid>
                ))
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={2} className={"productPriceCart"}>
          <div className={"productPriceCartEuro"} style={{
            color: props.colors.fontAndCategoryColor
          }}>{props.currency}</div>
          <span style={{
            color: props.colors.fontAndCategoryColor,
            fontWeight: "bold"
          }} className={"priceCartArticle"}>
            {
              state === "3" ? <s>{(parseFloat(price) * parseInt(props.quantity)).toFixed(0)}</s> : (parseFloat(price) * parseInt(props.quantity)).toFixed(0)
            }
          </span>
        </Grid>

        {
          <div className={"quantityContainer quicksand"} style={{
            color: props.colors.fontAndCategoryColor,
            fontWeight: "bold"
          }}>
            x{props.quantity}
          </div>
        }


        {
          !props.noActionButton ? (
            <div className={"actionCartProduct"}>
              {state === "0" && (<CloseIcon className={"closeIconCart"} onClick={remove}/>)}
              {state === "1" && (<HourglassEmptyIcon className={"hourglassEmptyIconCart"}/>)}
              {state === "2" && (<CheckIcon className={"checkIconCart"}/>)}
              {state === "3" && (<span className={"paidCart"} style={{
                color: props.colors.fontAndCategoryColor
              }}>{i18next.t('cart.paid')}</span>)}
            </div>
          ) : (
            state === "3" &&
            (<div className={"actionCartProduct"}><span className={"paidCart"} style={{
              color: props.colors.fontAndCategoryColor
            }}>{i18next.t('cart.paid')}</span></div>)
          )
        }

      </Grid>
    </>
  )
};
const mapStateToProps = (state) => ({colors: state.session.colors});

export default connect(mapStateToProps)(Product)
