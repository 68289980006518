import React from "react";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const BackGround = `/assets/img/menu/bg.png`;

const Loading = () =>
  <React.Fragment>
    <Grid
      style={{backgroundImage: `url(${BackGround})`, height: "100vh",}}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={11} md={6} xl={4}>
        <CircularProgress/>
      </Grid>
    </Grid>
  </React.Fragment>
;

export default Loading;
