import React from "react"
import {connect} from "react-redux";
import "./css/billing.css"
import Link from "@material-ui/core/Link";
import {setShowBill} from "../../actions/billing";
import {ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos, Close, KeyboardArrowDown} from "@material-ui/icons";
import i18next from "i18next";
import axios from "axios";
import {AdMediaLink, BackEndLink, loggedParams} from "../../constants/global";
import Grid from "@material-ui/core/Grid";
import Product from "../Cart/Product";
import Fade from "@material-ui/core/Fade";
import PinField from "react-pin-field";
import BackspaceIcon from "@material-ui/icons/Backspace";
import Zoom from "@material-ui/core/Zoom";
import {Slide} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setBackDrop, setShowError, setShowSuccess} from "../../actions/menu";
import {setNewRoute} from "../../actions/route";
import CheckMark from "./SmallProps/CheckMark";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Review from "./Review";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import Tips from "./Tips";

const Billing = (props) => {

  const [review, setReview] = React.useState(false)
  const [tips, setTips] = React.useState(false)
  const [customerTips, setCustomerTips] = React.useState(0.00)
  const [step, setStep] = React.useState(0);
  const [pay, setPay] = React.useState(0);
  const [paidArticles, setPaidArticles] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [showProducts, setShowProducts] = React.useState(false);
  const [showActions, setShowActions] = React.useState(false);
  const [service, setService] = React.useState(0);
  const [showPin, setShowPin] = React.useState(false);
  const [products, setProducts] = React.useState({});
  const [meanPayment, setMeanPayment] = React.useState(null);
  const [discount, setDiscount] = React.useState(0);
  const ref = React.useRef();
  const [numberSplit, setNumberSplit] = React.useState("2")
  const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [partner, setPartner] = React.useState(false);
  const [selectedPartner, SetSelectedPartner] = React.useState(null);

  const cleanPin = () => {
    setNumberSplit("");
    ref.current[0].value = "";
    ref.current[1].value = "";
    ref.current[2].value = "";
  }

  const changeCodePin = (action) => {
    if (numberSplit.length <= 2) {
      ref.current[numberSplit.length].value = action.i;
      setNumberSplit(numberSplit + action.i);
    }
  }

  const refreshBill = () => {
    setLoading(true);
    axios.post(`${BackEndLink}/cart/get`, loggedParams).then(result => {
        if (result.data.articles) {
          setProducts(result.data.articles);
          let tempPaidArticles = 0;

          result.data.articles.forEach(article => {
            if (parseInt(article.state_orders_lines) === 3) {
              tempPaidArticles += parseFloat(article.price_article) * parseInt(article.quantity, 10);
            }
          });

          setPaidArticles(tempPaidArticles);
        }

        if (result.data.discount) {
          setDiscount(result.data.discount)
        }

        if (result.data.customer_tips) {
          setCustomerTips(parseFloat(result.data.customer_tips))
        }

        if (result.data.service)
          setService(parseFloat(result.data.service));

        setLoading(false);

        setTimeout(() => {
          if (props.showBill) {
            setShowProducts(true);
            setShowActions(true);
          }
        }, 1100)
      }
    )
  }

  const askBill = () => {
    setBackDrop(true);
    const params = loggedParams.valueOf();
    params.set("type_bill", meanPayment);
    axios.post(`${BackEndLink}/bill/ask`, params).then(result => {
      params.delete("type_bill");
      setBackDrop(false);
      if (result.data.error === 1) {
        setShowError(i18next.t(result.data.message))
      } else {
        setShowSuccess(i18next.t("bill.approved"))
        setNewRoute(4);
        setShowBill(false)
      }
    });
  }

  const cancelBill = () => {
    setBackDrop(true);
    axios.post(`${BackEndLink}/bill/cancel_bill`, loggedParams).then(result => {
      props.checkToken();
      setBackDrop(false);
    });
  }

  React.useEffect(() => {
    if (props.showBill) {
      refreshBill()
    }
    SetSelectedPartner(null)
    setStep(0);
    setShowProducts(false);
    setShowActions(false);
    setShowPin(false);

  }, [props.showBill]);

  React.useEffect(() => {
    if (props.type !== "asking") {
      setShowActions(false);
      setShowBill(true);
      setReview(true)
      setShowSuccess(i18next.t("bill.approve"))
    }
  }, [props.type])

  React.useEffect(() => {
    if (products && Object.keys(products).length > 0) {
      let totalCalcul = 0.00;
      Object.keys(products).forEach(product => {
        totalCalcul += parseFloat(products[product].price_article) * parseInt(products[product].quantity);
      });
      setTotal((Math.round(totalCalcul * 100) / 100));
    } else {
      setTotal(0);
    }
  }, [products]);

  const backPartner = () => {
    if (parseInt(selectedPartner, 0) === 0) {
      const newVal = props.partners.length - 1;
      SetSelectedPartner(newVal.toString());
    } else {
      const newVal = parseInt(selectedPartner, 0) - 1;
      SetSelectedPartner(newVal.toString());
    }
  }

  const forwardPartner = () => {
    if (parseInt(selectedPartner, 0) === props.partners.length - 1) {
      SetSelectedPartner("0");
    } else {
      const newVal = parseInt(selectedPartner, 0) + 1;
      SetSelectedPartner(newVal.toString());
    }
  }

  const toDecimal = (number, precision) => {
    return Math.round(number * (Math.pow(10, precision))) / (Math.pow(10, precision));
  }

  return (
    <>
      {
        props.showBill && props.type === "asking" &&
        <Link onClick={() => setShowBill(false)}>
          <div className={"emptyZone"}/>
        </Link>
      }

      {
        props.type !== "asking" && (
          <CheckMark/>
        )
      }

      <div
        className={props.type !== "asking" ? "BillingContainerFull" : props.showBill ? "BillingContainer BillingContainerShow" : "BillingContainer"}>

        {
          props.type === "asking" && (
            <Link onClick={() => setShowBill(false)}>
              <div className={"BillingLess"}>
                <KeyboardArrowDown className={"BillingLessIcon"}/>
              </div>
            </Link>
          )
        }

        {
          props.type !== "asking" && (
            <div className={"nbPartners"}>
              {localStorage.getItem("name_establishment")} {i18next.t("partners.nb")}
            </div>
          )
        }

        <div className={"billingTitle"}>
          {i18next.t("bill.title")}
        </div>


        {
          props.type !== "asking" && (
            <div className={pay === 0 ? "arrowBillingTop" : "arrowBillingBottom"}>
              <img alt={"arrow"} src={`/assets/img/menu/arrow_bill.png`}/>
            </div>
          )
        }

        {
          !loading && props.showBill && props.type === "asking" && (
            <div className={pay === 0 ? "arrowBillingTopShort" : "arrowBillingBottomShort"}>
              <img alt={"arrow"} src={`/assets/img/menu/arrow_bill.png`}/>
            </div>
          )
        }

        <Link onClick={() => {
          if (step === 0) {
            if (!showPin) {
              setPay(0);
              setShowPin(false);
              setShowProducts(true);
            } else {
              setNumberSplit(2)
              setPay(0);
              setShowPin(false);
              setShowProducts(true);
            }
          } else if (step === 1) {
            if (!showPin) {
              setShowActions(true)
              setStep(0);
              setPay(0);
              setShowPin(false);
              setShowProducts(true);
            }
          }
        }}>
          <div className={pay === 0 ? "paySolo paySoloSelected" : "paySolo"}>
            <div className={"paySoloLabel"}>
              {i18next.t("bill.paySolo")}
            </div>
            <div className={"pricePerP"}>
              <div className={"pricePerPeuro"}>{props.currency}</div>
              <span
                className={"pricePerPPrice"}>{parseFloat(parseFloat(total) - parseFloat(paidArticles) + parseFloat(service) + parseFloat(customerTips)).toFixed(2)}</span>
            </div>
          </div>
        </Link>

        <Link onClick={() => {
          if (step === 0)
            setPay(1)
          else if (step === 1) {
            setShowActions(true)
            setStep(0);
            setPay(1);
            setShowPin(false);
            setShowProducts(true);
          }
        }}>
          <div className={pay === 1 ? "payMultiple payMultipleSelected" : "payMultiple"}>
            <div className={"payMultipleLabel"}>
              {i18next.t("bill.slitBill")} <Fade
              in={numberSplit > 0 && pay === 1}><span>{`${i18next.t("bill.slitBillWith")} ${numberSplit}`}</span></Fade>
            </div>
            <div className={"pricePerP"}>
              {
                toDecimal((total - paidArticles + service + customerTips) / parseInt(numberSplit, 0), 2) > 0 && !(numberSplit === "0" || numberSplit === "00" || numberSplit === "000")
                  ? (
                    <>
                      <div className={"pricePerPeuro"}>{props.currency}</div>
                      <div className={"pricePerPLabel"}>/{i18next.t("bill.perPerson")}</div>
                      <span
                        className={"pricePerPPrice"}>{toDecimal((total - paidArticles + service + customerTips) / parseInt(numberSplit, 0), 2)}</span>
                    </>
                  ) : (
                    <>
                      <div className={"pricePerPeuro"}>{props.currency}</div>
                      <span className={"pricePerPPrice"}>0</span>
                    </>
                  )
              }
            </div>
          </div>
        </Link>

        <Slide direction={"left"} in={step === 0}>
          <div className={"billingProductsContainer"}>
            <Zoom in={showPin}>
              <div className={!showProducts ? "pinContainer" : "hidePinContainer"}>
                <div className={"BillingRightPinAction"}>
                  <Link onClick={() => {
                  }}>
                    <div className={numberSplit > 0
                      ? "waiterButtonAction persButton yellowPersButton"
                      : "waiterButtonAction persButton yellowPersButton disabledYellow"
                    }>
                      <ArrowForward className={"iconPersButton"} onClick={() => {
                        if (!numberSplit > 0 || numberSplit === "0" || numberSplit === "00" || numberSplit === "000") {
                          setShowError(i18next.t("bill.splitError"))
                        } else {
                          setShowPin(!showPin);
                          setShowProducts(!showProducts);
                        }
                      }}/>
                    </div>
                  </Link>
                </div>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{width: "70%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>
                  <Grid item xs={12} style={{textAlign: "center"}}>
                    <div className={"pinResult"}>
                      <PinField
                        type={""}
                        ref={ref}
                        validate="0123456789"
                        disabled={true}
                        onChange={setNumberSplit}
                        onComplete={() => {
                        }}
                        style={{}}
                        className={"pinField"}
                        length={3}
                      />
                    </div>
                  </Grid>
                  {
                    numberArray.map((i) =>
                      <Grid key={i} className={"activatedNumber"} item xs={4}
                            style={{textAlign: "center", marginTop: "1em"}}>
                        <Link underline={"none"} onClick={() => changeCodePin({i})}>
                          <div className={"badge"}>
                            {i}
                          </div>
                        </Link>
                      </Grid>
                    )
                  }
                  <Grid item xs={4} style={{textAlign: "center", marginTop: "1em"}}/>
                  <Grid className={"activatedNumber"} item xs={4}
                        style={{textAlign: "center", marginTop: "1em"}}>
                    <Link underline={"none"} onClick={() => changeCodePin({i: 0})}>
                      <div className={"badge"}>
                        0
                      </div>
                    </Link>
                  </Grid>
                  <Grid className={"activatedNumber"} item xs={4}
                        style={{textAlign: "center", marginTop: "1em"}}>
                    <Link onClick={() => cleanPin()}>
                      <div className={"badge"}>
                        <BackspaceIcon/>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Zoom>

            {
              loading && (
                <div className={"BillingLoading"}>
                  <CircularProgress/>
                </div>
              )
            }

            {
              !showPin && (
                <Zoom in={showProducts}>
                  <div>

                    {
                      (service > 0) && (
                        <Grid item xs={12} className={"discount"}>
                          {
                            i18next.t("cart.service")
                          }
                          {`${service}`}{props.currency}
                        </Grid>
                      )
                    }

                    {
                      ((discount > 0 || discount < 0) && service === 0) && (
                        <Grid style={{
                          marginTop: "2vh"
                        }} item xs={12} className={"discount"}>
                          {
                            discount < 0 ? (
                              i18next.t("cart.discount")
                            ) : (
                              i18next.t("cart.tips")
                            )
                          }
                          {`${discount}%`}
                        </Grid>
                      )
                    }

                    {
                      parseInt(customerTips, 10) > 0 && (
                        <Product
                          quantity={1}
                          currency={props.currency}
                          noActionButton={false}
                          key={"tips"}
                          price={customerTips}
                          picture={""}
                          remove={() => {
                            setTips(true)
                          }}
                          name={i18next.t('tips.staff')}
                          icon={"/assets/img/menu/coin.png"}
                          category={"tips"}
                          state={"0"}
                        />
                      )
                    }

                    {products && Object.keys(products).length > 0 && products.map((product) => (
                      <Product
                        quantity={product.quantity}
                        currency={props.currency}
                        noActionButton={true}
                        softs={product.softs}
                        key={product.id_orders_lines}
                        price={product.price_article}
                        bottle_name={product.bottle_name}
                        bottle_size={product.bottle_size}
                        picture={product.article_img}
                        category={product.name_category_article}
                        name={product.name_article}
                        description={product.desc_article}
                        remove={() => {
                        }}
                        state={product.state_orders_lines}
                      />
                    ))}
                  </div>
                </Zoom>
              )
            }
          </div>
        </Slide>

        <Zoom in={step === 1}>
          <div className={"billigReturn"}>
            <Link onClick={() => {
              setStep(0);
              setShowActions(true);
            }}>
              <div className={"waiterButtonAction persButton yellowPersButton"}>
                <ArrowBack className={"iconPersButton"}/>
              </div>
            </Link>
          </div>
        </Zoom>

        <Slide direction={"right"} in={step === 1}>
          <div className={"billingContainerCenter"}>
            <Link onClick={() => setMeanPayment(1)}>
              <div className={meanPayment === 1 ? "billingMeanLeft billingMeanSelected" : "billingMeanLeft"}>
                <img className={"billingMeanImage"} alt="cash.png" src={`/assets/img/menu/cash.png`}/>
                <div className={"billingLabel billingMeanLabelCash"}>
                  {i18next.t("bill.cash")}
                </div>
              </div>
            </Link>
            <Link onClick={() => setMeanPayment(0)}>
              <div className={meanPayment === 0 ? "billingMeanRight billingMeanSelected" : "billingMeanRight"}>
                <img className={"billingMeanImage"} alt="cb.png" src={`/assets/img/menu/cb.png`}/>
                <div className={"billingLabel billingMeanLabelCB"}>
                  {i18next.t("bill.cb")}
                </div>
              </div>
            </Link>
            <Link onClick={() => setMeanPayment(2)}>
              <div className={meanPayment === 2 ? "billingMeanLeftPush billingMeanSelected" : "billingMeanLeftPush"}>
                <img className={"billingMeanImage"} alt="cbcash.png" src={`/assets/img/menu/cbcash.png`}/>
                <div className={"billingLabel billingMeanLabelBoth"}>
                  {i18next.t("bill.cashcb")}
                </div>
              </div>
            </Link>
          </div>
        </Slide>

        <Zoom in={((props.showBill || props.type !== "asking") && showActions && pay === 1 && !showPin && step === 0)}>
          <div className={"BillingleftAction"}>
            <Link underline={"none"} onClick={() => {
              cleanPin();
              setShowPin(true);
              setShowProducts(false);
            }}>
              <div className={"yellowButtonFull splitButtonBill"}>
                {i18next.t("bill.editSplit")}
              </div>
            </Link>
          </div>
        </Zoom>

        <Zoom in={((props.type !== "asking") && showActions && pay !== 1 && !showPin)}>
          <div className={"BillingleftAction"}>
            <Link underline={"none"} onClick={() => {
              cancelBill()
            }}>
              <div className={"yellowButtonFull splitButtonBill"}>
                {i18next.t("bill.cancelBill")}
              </div>
            </Link>
          </div>
        </Zoom>

        <Zoom in={showActions && !showPin && total > 0 && props.type === "asking"}>
          <div className={"BillingRightAction"}>
            <Link onClick={() => {
            }}>
              <div className={props.notValidated === 0
                ? "waiterButtonAction persButton yellowPersButton"
                : "waiterButtonAction persButton yellowPersButton"
              }>
                <ArrowForward className={"iconPersButton"} onClick={() => {
                  if (props.notValidated > 0) {
                    setShowError(i18next.t("bill.notValidated"))
                  } else if ((total - paidArticles) === 0) {
                    setShowError(i18next.t("bill.empty"))
                  } else {
                    setStep(1);
                    setShowActions(false);
                  }
                }}/>
              </div>
            </Link>
          </div>
        </Zoom>

        <Zoom in={meanPayment !== null && !showActions && props.type === "asking"}>
          <div className={"BillingRightAction"}>
            <Link onClick={() => {
            }}>
              <div className={"waiterButtonAction persButton yellowPersButton"}>
                <ArrowForward className={"iconPersButton"} onClick={() => {
                  if (meanPayment !== null) {
                    askBill()
                  }
                }}/>
              </div>
            </Link>
          </div>
        </Zoom>


        <Review open={review} setReview={setReview} setTips={setTips}/>

        <Tips open={tips} setTips={setTips} refreshBill={refreshBill}/>

        {
          (props.type !== "asking" && props.partners && props.partners.length > 0) && (
            <div className={"partnerBillBottom"}>
              <div className={"titleBillingPartner"}>
                {i18next.t("partners.main")}
              </div>
              <div className={"textBillingPartnerBottom"}>
                {i18next.t("partners.text")}
              </div>

              <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}
                    className={"partnerBillingContainerBottom"}>
                {
                  props.partners && props.partners.length > 0 && Object.keys(props.partners).map((i) => (
                    <Grid key={i} onClick={() => {
                      if (selectedPartner === i)
                        SetSelectedPartner(null)
                      else {
                        SetSelectedPartner(i)
                        setPartner(true)
                      }
                    }} item xs={2}
                          className={selectedPartner === i ? "partnerBillingPicture partnerBillingSelected" : "partnerBillingPicture"}
                          alt={"partner"}>
                      {
                        props.partners[i].premium_partner === "1" &&
                        <div
                          className={selectedPartner === i ? "premiumPartner premiumPartnerSelected" : "premiumPartner"}>
                          <WhatshotIcon style={{color: "#F6BC41", fontSize: "2em"}}/>
                        </div>
                      }
                      <img alt={"imagePatners"} src={`${AdMediaLink}${props.partners[i].media_link}`}
                           className={selectedPartner === i ? "partnerImage partnerImageSelected" : "partnerImage"}/>
                    </Grid>
                  ))
                }
                <div className={"barPartners"}/>
              </Grid>
            </div>
          )
        }

        {partner && <div className={"backdropMainPartners"}/>}

        <Dialog
          hideBackdrop
          PaperProps={{
            className: "mainPartnersDialog",
          }} maxWidth={"xl"} spacing={4} fullWidth={true}
          onClose={() => {
            setPartner(false)
            SetSelectedPartner(null)
          }}
          aria-labelledby="customized-dialog-title"
          open={partner && props.type !== "asking"}>
          <DialogContent>
            <div className={"titleBillingPartner tw"}>
              {i18next.t("partners.main")}
            </div>
            <div className={"mainPartnerDialogText tw"}>
              {i18next.t("partners.text")}
            </div>

            <Link onClick={() => setPartner(false)}>
              <div className={"closeDialog"}>
                <Close style={{fontSize: "2em"}}/>
              </div>
            </Link>

            <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}
                  className={"partnerBillingContainer"}>
              {
                Object.keys(props.partners).map((i) => (
                  <Grid key={i} onClick={() => {
                    if (selectedPartner === i)
                      SetSelectedPartner(null)
                    else
                      SetSelectedPartner(i)
                  }} item xs={2}
                        className={selectedPartner === i ? "partnerBillingPicture partnerBillingSelected" : "partnerBillingPicture"}
                        alt={"partner"}>
                    {
                      props.partners[i].premium_partner === "1" &&
                      <div
                        className={selectedPartner === i ? "premiumPartner premiumPartnerSelected" : "premiumPartner"}>
                        <WhatshotIcon style={{color: "#F6BC41", fontSize: "2em"}}/>
                      </div>
                    }
                    <img alt={"imagePatners"} src={`${AdMediaLink}${props.partners[i].media_link}`}
                         className={selectedPartner === i ? "partnerImage partnerImageSelected" : "partnerImage"}/>
                  </Grid>
                ))
              }
              <div className={"barPartnersMain"}/>
            </Grid>

            {
              selectedPartner && (
                <>
                  <div className={"selectionPartner"}>
                    <Zoom in={selectedPartner}>
                      <div className={"textCirclePartner"}>
                        <div className={"textCirclePartnerText"}>
                          {selectedPartner && props.partners[selectedPartner].desc_message_partner}
                        </div>
                      </div>
                    </Zoom>

                    <Zoom in={selectedPartner}>
                      <div className={"yellowButtonFull yellowButtonContainedPartner"}>
                        {selectedPartner && props.partners[selectedPartner].message_partner}
                      </div>
                    </Zoom>

                  </div>

                  <Link onClick={() => {
                    backPartner()
                  }}>
                    <IconButton className={"backPartner"} aria-label="delete" color="primary">
                      <ArrowBackIos/>
                    </IconButton>
                  </Link>

                  <Link onClick={() => forwardPartner()}>
                    <IconButton className={"forwardPartner"} aria-label="delete" color="primary">
                      <ArrowForwardIos/>
                    </IconButton>
                  </Link>


                </>
              )
            }
          </DialogContent>
        </Dialog>

      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  showBill: state.billing.showBill,
  currency: state.session.currency,
  notValidated: state.cart.notValidated,
  partners: state.session.partners
});

export default connect(mapStateToProps)(Billing)
