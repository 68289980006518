import React from 'react'
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import './css/header.css' ;
import './css/footer.css' ;

import {setLanguage} from "../../actions/language";
import {Link} from "@material-ui/core";
import {setShowCart} from "../../actions/cart";
import Badge from "@material-ui/core/Badge";
import {LogoLink} from "../../constants/global";
import Chat from "./Chat";
import withStyles from "@material-ui/core/styles/withStyles";

const Header = (props) => {

  const [open, setOpen] = React.useState(false);
  const [flags, setFlags] = React.useState();
  const [currentFlag, setCurrentFlag] = React.useState();
  const [arrow, setArrow] = React.useState();

  const handleMenu = (language) => {
    if (open) {
      arrow.style.transform = "rotate(0deg)";
      flags.style.width = "11vw";
      setOpen(!open);
      if (language) {
        setTimeout(() => {
          currentFlag.style.opacity = 0;
        }, 2000);
        setTimeout(() => {
          setLanguage(language);
          currentFlag.style.opacity = 1;
        }, 2500);
      }

    } else {
      arrow.style.transform = "rotateY(180deg)";
      flags.style.width = "90vw";
      setOpen(!open);
      setTimeout(() => {
        arrow.style.transform = "rotate(0deg)";
        flags.style.width = "11vw";
        setOpen(false);
      }, 15000)

    }
  };

  const StyledBadge = withStyles(() => ({
    badge: {
      backgroundColor: props.colors.fontAndCategoryColor,
      color: "white"
    },
  }))(Badge);

  return (
    <React.Fragment>

      {
        props.logo && (
        <div className={"logoEstaContainer"}>
          <div className={"logoEsta"} style={{backgroundImage: `url("${LogoLink}${props.logo}")`}}/>
        </div>
       )
      }


      <div className={"headerContainer"}>
        <div className={"header"}>

          <div className={"center"} style={{zIndex: 0}}>
            <h4 className={"logoHeader"}>
              {
                !props.logo && (
                  localStorage.getItem("name_establishment")
                )
              }
            </h4>
          </div>

          <Chat />

          {
            !props.onlyAds && (
              <Link onClick={() => setShowCart((!props.showCart))}>
                <div className={"right"}>
                  <img className={"cartHeader"} src={`/assets/img/menu/cart_yellow.png`}
                       alt={"French"}/>

                  <StyledBadge className={"pinCart"} badgeContent={props.notValidated}/>
                </div>
              </Link>
            )
          }

          <Box className={"left flags"} display="flex" alignItems="center" ref={e => setFlags(e)}
               style={{width: "7vw", height: "8vh"}}>
            <Box onClick={() => handleMenu()}>
              <img ref={e => setCurrentFlag(e)} src={`/assets/img/roundflag/${props.language}.png`}
                   style={{height: "5vh", transition: "all 0.5s ease"}} alt={props.language}/>
            </Box>
            <Box>
              <div ref={e => setArrow(e)} className={"arrowRight"}/>
            </Box>
            <Box className={"selectableFlags"} display="flex" alignItems="center">
              <>
                <Box onClick={() => {
                  handleMenu("fr")
                }}>
                  <img src={`/assets/img/roundflag/fr.png`} style={{height: "5vh", marginLeft: "3vw"}}
                       alt={"French"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("en")
                }}>
                  <img src={`/assets/img/roundflag/en.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"English"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("de")
                }}>
                  <img src={`/assets/img/roundflag/de.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"German"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("es")
                }}>
                  <img src={`/assets/img/roundflag/es.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Spanish"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("it")
                }}>
                  <img src={`/assets/img/roundflag/it.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Italian"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("pt")
                }}>
                  <img src={`/assets/img/roundflag/pt.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Portuguese"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("ru")
                }}>
                  <img src={`/assets/img/roundflag/ru.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Russian"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("cn")
                }}>
                  <img src={`/assets/img/roundflag/cn.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Chinese"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("ae")
                }}>
                  <img src={`/assets/img/roundflag/ae.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Arab"}/>
                </Box>
                <Box onClick={() => {
                  handleMenu("jp")
                }}>
                  <img src={`/assets/img/roundflag/jp.png`} style={{height: "5vh", marginLeft: "2vw"}}
                       alt={"Japanese"}/>
                </Box>
                <div style={{opacity: 0}}>
                  en
                </div>
              </>
            </Box>
          </Box>
        </div>
      </div>
      <div className={"fakeHeader"}/>
    </React.Fragment>
  )

};

const mapStateToProps = (state) => ({
  token: state.session.token,
  onlyAds: state.session.onlyAds,
  language: state.language.language,
  showCart: state.cart.showCart,
  notValidated: state.cart.notValidated,
  logo: state.session.logo,
  newChat: state.session.newChat,
  colors: state.session.colors,
  refreshChat: state.session.refreshChat
});

export default connect(mapStateToProps)(Header)


