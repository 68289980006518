import DialogTitle from "@material-ui/core/DialogTitle";
import i18next from "i18next";
import Grid from "@material-ui/core/Grid";
import {DialogActions, Link} from "@material-ui/core";
import {ArrowForward, Close} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {BackEndLink, loggedParams} from "../../constants/global";
import axios from "axios";
import {connect} from "react-redux";
import {setBackDrop, setShowError, setShowSuccess, setSos, setSosChoice} from "../../actions/menu";
import Zoom from "@material-ui/core/Zoom";

const Sos = (props) => {


  const help = () => {
    if (!props.sosChoice) {
      return;
    }

    setSosChoice(null);
    setSos(false);
    setBackDrop(true);

    const params = loggedParams.valueOf();
    params.set("choice", props.sosChoice);
    axios.post(`${BackEndLink}/features/help`, params).then(result => {

      params.delete("choice");
      setBackDrop(false);
      if (result.data.error === 0) {
        setShowSuccess(i18next.t("sos.success"))
      } else {
        setShowError(i18next.t("sos.error"))
      }
    })
  };

  return (
    <>

      <Dialog maxWidth={"xl"} fullWidth={true} onClose={() => {
        setSos(false);
        setSosChoice(null)
      }} aria-labelledby="simple-dialog-title" open={props.sos} className={"dialogSOS"}
              PaperProps={{
                style: {
                  backgroundColor: 'rgba(255,255,255,0)',
                  boxShadow: 'none',
                },
              }}
      >
        <DialogTitle style={{textAlign: "center"}} id="simple-dialog-title"><span
          style={{
            fontSize: "1.8em",
            color: "white",
            marginBottom: "5vh"
          }}
        >{i18next.t("sos.title")}</span></DialogTitle>

        <Link onClick={() => setSos(false)}>
          <div className={"closeDialog"}>
            <Close style={{fontSize: "2em"}}/>
          </div>
        </Link>

        <Grid container direction="row"
              justify="center"
              alignItems="center" style={{marginTop: "7vh"}}>
          <Grid item xs={6}>
            <div className={"waiterButtons"}>
              <Link onClick={() => {
                setSosChoice("ice")
              }}>
                <img alt={"ice"}
                     className={
                       !props.sosChoice ? ("waiterButtonsImages")
                         : (props.sosChoice === "ice" ? "waiterButtonsImages selected" : "waiterButtonsImages notSelected")
                     }
                     src={`/assets/img/menu/ice.png`}
                />
                <div className={
                  !props.sosChoice ? ("waiterButtonsLegends")
                    : (props.sosChoice === "ice" ? "waiterButtonsLegends selectedLegend" : "waiterButtonsLegends notSelectedLegend")
                }>
                  {i18next.t("sos.ice")}
                </div>
              </Link>
            </div>
          </Grid>
          {
            /*
            <Grid item xs={6}>
              <div className={"waiterButtons"}>
                <Link onClick={() => {
                  setSosChoice("pin")
                }}>
                  <img alt={"pin"}
                       className={
                         !props.sosChoice ? ("waiterButtonsImages")
                           : (props.sosChoice === "pin" ? "waiterButtonsImages selected" : "waiterButtonsImages notSelected")
                       }
                       src={`/assets/img/menu/pin.png`}
                  />
                  <div className={
                    !props.sosChoice ? ("waiterButtonsLegends waiterButtonsLegendsPin")
                      : (props.sosChoice === "pin" ? "waiterButtonsLegends selectedLegend waiterButtonsLegendsPin" : "waiterButtonsLegends notSelectedLegend waiterButtonsLegendsPin")
                  }>
                    {i18next.t("sos.pin")}
                  </div>
                </Link>
              </div>
            </Grid>
            */
          }
          <Grid item xs={6}>
            <div className={"waiterButtons"}>
              <Link onClick={() => {
                setSosChoice("glasses")
              }}>
                <img alt={"info"}
                     className={
                       !props.sosChoice ? ("waiterButtonsImages")
                         : (props.sosChoice === "glasses" ? "waiterButtonsImages selected" : "waiterButtonsImages notSelected")
                     }
                     src={`/assets/img/menu/glass.png`}
                />
                <div className={
                  !props.sosChoice ? ("waiterButtonsLegends")
                    : (props.sosChoice === "glasses" ? "waiterButtonsLegends selectedLegend" : "waiterButtonsLegends notSelectedLegend")
                }>
                  {i18next.t("sos.glasses")}
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={"waiterButtons"}>
              <Link onClick={() => {
                setSosChoice("fight")
              }}>
                <div
                  className={
                    !props.sosChoice ? ("waiterButtonsImages waiterButtonsFight")
                      : (props.sosChoice === "fight" ? "waiterButtonsImages selected waiterButtonsFight" : "waiterButtonsImages notSelected waiterButtonsFight")
                  }
                />
                <div className={
                  !props.sosChoice ? ("waiterButtonsLegends waiterButtonsLegendsPin")
                    : (props.sosChoice === "fight" ? "waiterButtonsLegends selectedLegend waiterButtonsLegendsPin" : "waiterButtonsLegends notSelectedLegend waiterButtonsLegendsPin")
                }>
                  {i18next.t("sos.fight")}
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item xs={6} style={{marginTop: "1em"}}>
            <div className={"waiterButtons"}>
              <Link onClick={() => {
                setSosChoice("others")
              }}>
                <div alt={"info"}
                     className={
                       !props.sosChoice ? ("waiterButtonsImages waiterButtonsOther")
                         : (props.sosChoice === "others" ? "waiterButtonsImages selected waiterButtonsOther" : "waiterButtonsImages notSelected waiterButtonsOther")
                     }
                />
                <div className={
                  !props.sosChoice ? ("waiterButtonsLegends")
                    : (props.sosChoice === "others" ? "waiterButtonsLegends selectedLegend" : "waiterButtonsLegends notSelectedLegend")
                }>
                  {i18next.t("sos.other")}
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>

        <DialogActions>
          <Zoom in={props.sosChoice !== null}>
            <div className={"waiterButtonAction persButton yellowPersButton"} style={{
              backgroundColor: props.colors.buttonOrderColor,
              color: props.colors.fontAndCategoryColor,
            }}>
              <ArrowForward className={"iconPersButton"} onClick={() => help()}/>
            </div>
          </Zoom>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  sos: state.menu.sos,
  colors: state.session.colors,
  sosChoice: state.menu.sosChoice
});

export default connect(mapStateToProps)(Sos)

