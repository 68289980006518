import React from "react"
import "./css/validationAlert.css"
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import {Close} from "@material-ui/icons";
import {Link} from "@material-ui/core";


const DigitalWaitressAlert = (props) => {

  const {
    size,
    image,
    buttonLeft,
    buttonLeftCallback,
    buttonRight,
    buttonRightCallback,
    callbackClose,
    message,
    open
  } = props

  return (
    <>
      <Dialog
        maxWidth={size}
        fullWidth={true}
        open={open}
        onClose={callbackClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255,255,255,0.8)',
            boxShadow: 'none',
          },
        }}
      >
        <div className={"closeButtonAlert"}>
          <Link onClick={callbackClose}>
            <Close/>
          </Link>
        </div>

        <div className={"validationAlertContent"}>
          <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}>
            <Grid item xs={4}>
              <img className={"validationAlertImage"} alt={"warning"} src={image}/>
            </Grid>
            <Grid className={"validationAlertText"} item xs={7}>
              {message}
            </Grid>
          </Grid>
        </div>
        <div className={"validationAlertActions"}>
          <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}>
            <Grid underline={"none"} item xs={5}>
              <Link underline={"none"} onClick={buttonLeftCallback}>
                <div className={"yellowButtonContained"}>{buttonLeft}</div>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link underline={"none"} onClick={buttonRightCallback}>
                <div className={"yellowButtonFull"}>{buttonRight}</div>
              </Link>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  )
}


const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(DigitalWaitressAlert)

