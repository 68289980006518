import React from 'react'
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './css/header2.css';
import {CART_ANIMATION, ColorLuminance, LogoLink, MENU_CHOICE, MenuMediaLink} from "../../constants/global";
import {setAnimateCart, setShowCart} from "../../actions/cart";
import {CircularProgress, LinearProgress, Link} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import i18next from "i18next";
import {setStateContainer} from "../../actions/session";
import Box from "@material-ui/core/Box";
import Flicking from "@egjs/react-flicking";
import Chat from "./Chat";
import {setShowError} from "../../actions/menu";
import withStyles from "@material-ui/core/styles/withStyles";
import Confetti from "react-confetti";

const Header2 = (props) => {

    const [cart, setCart] = React.useState(CART_ANIMATION.NOTHING);
    const [ready, setReady] = React.useState(true);

  const checkConditionalDisplayAfter = (conditional_display_article) => {
    if (!conditional_display_article)
      return 1;

    const minHour = parseInt(conditional_display_article.split(":")[0], 10);
    const minMinute = parseInt(conditional_display_article.split(":")[1], 10);

    const currentDate = new Date();
    const currentHour = parseInt(currentDate.getHours(), 10);
    const currentMinute = parseInt(currentDate.getMinutes(), 10);

    if (currentHour < minHour) {
      return 0;
    }

    if (currentHour === minHour && currentMinute < minMinute) {
      return 0;
    }

    return 1;
  }

  const checkConditionalDisplayBefore = (conditional_display_article) => {
    if (!conditional_display_article)
      return 1;

    const maxHour = parseInt(conditional_display_article.split(":")[0], 10);
    const maxMinute = parseInt(conditional_display_article.split(":")[1], 10);

    const currentDate = new Date();
    const currentHour = parseInt(currentDate.getHours(), 10);
    const currentMinute = parseInt(currentDate.getMinutes(), 10);

    if (currentHour > maxHour) {
      return 0;
    }

    if (currentHour === maxHour && currentMinute >= maxMinute) {
      return 0;
    }

    return 1;
  }

    const menu = [];
    menu[MENU_CHOICE.SODA] = {
      label: `${i18next.t(`categories.Soda`)}`,
      menuChoice: MENU_CHOICE.SODA,
      image: "soda.png",
    }
    menu[MENU_CHOICE.JUICE] = {
      label: `${i18next.t(`categories.Juice`)}`,
      menuChoice: MENU_CHOICE.JUICE,
      image: "fruits.png",
    }
    menu[MENU_CHOICE.WATER] = {
      label: `${i18next.t(`categories.Water`)}`,
      menuChoice: MENU_CHOICE.WATER,
      image: "water.png",
    }
    menu[MENU_CHOICE.SWEETS] = {
      label: `${i18next.t(`categories.Sweets`)}`,
      menuChoice: MENU_CHOICE.SWEETS,
      image: "sweets.png",
    }
    menu[MENU_CHOICE.CIGARETTES] = {
      label: `${i18next.t(`categories.Cigarettes`)}`,
      menuChoice: MENU_CHOICE.CIGARETTES,
      image: "cigs.png",
    }
    menu[MENU_CHOICE.BALLOONS] = {
      label: `${i18next.t(`categories.Balloons`)}`,
      menuChoice: MENU_CHOICE.BALLOONS,
      image: "balloon.png",
    }
    menu[MENU_CHOICE.CHICHAS] = {
      label: `${i18next.t(`categories.Chichas`)}`,
      menuChoice: MENU_CHOICE.CHICHAS,
      image: "chichas.png",
    }

    menu[MENU_CHOICE.FOOD] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.FOOD}`)}`,
      menuChoice: MENU_CHOICE.FOOD,
      image: "snacking.svg",
    }

    menu[MENU_CHOICE.STARTER] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.STARTER}`)}`,
      menuChoice: MENU_CHOICE.STARTER,
      image: "starters.svg",
    }

    menu[MENU_CHOICE.DESERT] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.DESERT}`)}`,
      menuChoice: MENU_CHOICE.DESERT,
      image: "desert.svg",
    }

    menu[MENU_CHOICE.MAIN_FOOD] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.MAIN_FOOD}`)}`,
      menuChoice: MENU_CHOICE.MAIN_FOOD,
      image: "mains_foods_n.svg",
    }
    menu[MENU_CHOICE.PACK] = {
      label: `${i18next.t(`categories.PACK`)}`,
      menuChoice: MENU_CHOICE.PACK,
      image: "bucket.svg",
    }
    menu[MENU_CHOICE.VODKA] = {
      label: `${i18next.t(`categories.VODKA`)}`,
      menuChoice: MENU_CHOICE.VODKA,
      image: "vodka.png",
    }
    menu[MENU_CHOICE.GIN] = {
      label: `${i18next.t(`categories.GIN`)}`,
      menuChoice: MENU_CHOICE.GIN,
      image: "gin.png",
    }
    menu[MENU_CHOICE.TEQUILA] = {
      label: `${i18next.t(`categories.TEQUILA`)}`,
      menuChoice: MENU_CHOICE.TEQUILA,
      image: "tequila.png",
    }
    menu[MENU_CHOICE.WHISKY] = {
      label: `${i18next.t(`categories.WHISKY`)}`,
      menuChoice: MENU_CHOICE.WHISKY,
      image: "whisky.png",
    }
    menu[MENU_CHOICE.CHAMPAGNE] = {
      label: `${i18next.t(`categories.CHAMPAGNE`)}`,
      menuChoice: MENU_CHOICE.CHAMPAGNE,
      image: "champagne.png",
    }
    menu[MENU_CHOICE.RHUM] = {
      label: `${i18next.t(`categories.RHUM`)}`,
      menuChoice: MENU_CHOICE.RHUM,
      image: "rum.png",
    }
    menu[MENU_CHOICE.LIQUOR] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.LIQUOR}`)}`,
      menuChoice: MENU_CHOICE.LIQUOR,
      image: "liquor.svg",
    }
    menu[MENU_CHOICE.WINE_RED] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.WINE_RED}`)}`,
      menuChoice: MENU_CHOICE.WINE_RED,
      image: "wine.png"
    }
    menu[MENU_CHOICE.WINE_WHITE] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.WINE_WHITE}`)}`,
      menuChoice: MENU_CHOICE.WINE_WHITE,
      image: "wine.png"
    }
    menu[MENU_CHOICE.WINE_ROSE] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.WINE_ROSE}`)}`,
      menuChoice: MENU_CHOICE.WINE_ROSE,
      image: "wine.png"
    }
    menu[MENU_CHOICE.BEER] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.BEER}`)}`,
      menuChoice: MENU_CHOICE.BEER,
      image: "beer.png"
    }
    menu[MENU_CHOICE.COCKTAIL] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.COCKTAIL}`)}`,
      menuChoice: MENU_CHOICE.COCKTAIL,
      image: "cocktail.png"
    }
    menu[MENU_CHOICE.SHOT] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.SHOT}`)}`,
      menuChoice: MENU_CHOICE.SHOT,
      image: "shot.svg"
    }
    menu[MENU_CHOICE.GLASS] = {
      label: `${i18next.t(`categories.${MENU_CHOICE.GLASS}`)}`,
      menuChoice: MENU_CHOICE.GLASS,
      image: "glass.svg"
    }

    React.useEffect(() => {
      if (props.animate !== "") {

        setCart(props.animate);

        switch (props.animate) {
          case CART_ANIMATION.LOADING :
            break;
          case CART_ANIMATION.SUCCESS:
            if (ready) {
              setReady(false);
              setTimeout(() => {
                setAnimateCart(CART_ANIMATION.NOTHING);
                setReady(true);
              }, 4000)
            }
            break;
          case CART_ANIMATION.FAIL:
            break;
          default :
            break;
        }
      }
    }, [props.animate])

    const category = (c,currentMenu) => {

      let showCategory = true ;

      if(currentMenu.categories) {
        Object.keys(currentMenu.categories).forEach(checkCategory => {
          if(checkCategory === c.menuChoice) {
            const mainCategory = currentMenu.categories[checkCategory] ;
            if(mainCategory.articles) {
              showCategory = Object.keys(mainCategory.articles).filter(productWrapper => {
                const product = mainCategory.articles[productWrapper] ;
                if((props.onlyAds && product.hide_on_bar_article === "1") || (!props.onlyAds && product.hide_on_orders_article === "1"))
                  return 0 ;

                return checkConditionalDisplayAfter(product.conditional_display_article_after) && checkConditionalDisplayBefore(product.conditional_display_article_before)
              }).length > 0
            }
          }
        })
      }

      if(!showCategory) return (
        <></>
      )

      return (
        <Link key={c.label} underline="none" onClick={() => {
          if (props.menuChoice !== c.menuChoice) {
            props.changeMenuChoice(c.menuChoice)
          }
        }}>
          <Box
            className={props.menuChoice === c.menuChoice ? "categoryContainer categoryContainerSelected" : "categoryContainer"}
            style={{
              textAlign: "center",
              backgroundColor: props.menuChoice === c.menuChoice ? props.colors.fontAndCategoryColor : "",
              borderColor: props.menuChoice === c.menuChoice ? ColorLuminance(props.colors.fontAndCategoryColor, -0.15) : ""
            }}>
            <img alt={c.label}
                 className={props.menuChoice === c.menuChoice ? "categoryContainerImg categoryContainerImgSelected" : "categoryContainerImg"}
                 src={`/assets/img/menu/${c.image}`}
            />
            <div
              className={props.menuChoice === c.menuChoice ? "categoryContainerLabel categoryContainerLabelSelected" : "categoryContainerLabel"}
              style={{color: props.menuChoice === c.menuChoice ? props.colors.fontAndCategoryColor : ""}}>
              {c.label}
            </div>
          </Box>
        </Link>
      )
    }


    const upperCategory = (menuChoice, currentMenu) => {
      if (menuChoice.indexOf(props.menuChoice) !== -1) {

        if (props.menuChoice === MENU_CHOICE.VODKA) {
          if (currentMenu.categories && Object.keys(currentMenu.categories).length > 0) {
            if (Object.keys(currentMenu.categories).indexOf(props.menuChoice) === -1)
              props.changeMenuChoice(Object.keys(currentMenu.categories)[0])
          } else {
            props.setCurrentMenu(0);
            setShowError(i18next.t('global.no_product'));
          }
        }

        if (props.menuChoice === MENU_CHOICE.SWEETS) {
          if (currentMenu.categories && Object.keys(currentMenu.categories).length > 0) {
            if (Object.keys(currentMenu.categories).indexOf(props.menuChoice) === -1)
              props.changeMenuChoice(Object.keys(currentMenu.categories)[0])
          } else {
            props.setCurrentMenu(0);
            setShowError(i18next.t('global.no_product'));
          }
        }

        if (props.menuChoice === MENU_CHOICE.SODA) {
          if (currentMenu.categories && Object.keys(currentMenu.categories).length > 0) {
            if (Object.keys(currentMenu.categories).indexOf(props.menuChoice) === -1)
              props.changeMenuChoice(Object.keys(currentMenu.categories)[0])
          } else {
            props.setCurrentMenu(0);
            setShowError(i18next.t('global.no_product'));
          }
        }

        if (props.menuChoice === MENU_CHOICE.FOOD) {
          if (currentMenu.categories && Object.keys(currentMenu.categories).length > 0) {
            if (Object.keys(currentMenu.categories).indexOf(props.menuChoice) === -1)
              props.changeMenuChoice(Object.keys(currentMenu.categories)[0])
          } else {
            props.setCurrentMenu(0);
            setShowError(i18next.t('global.no_product'));
          }
        }

        if (currentMenu.count > 5) {
          return (
            <>
              <Flicking
                circular={true} moveType="freeScroll" className={"flickingHeader"}>
                {
                  Object.keys(currentMenu.categories).map((c) => category(menu[c],currentMenu))
                }
              </Flicking>
            </>
          )
        }

        return (
          <Box display="flex" alignItems="center"
               className={"categoriesContainer categoriesContainerLessThanFive"}>
            {
              currentMenu.categories && Object.keys(currentMenu.categories).map((c) => category(menu[c],currentMenu))
            }
          </Box>
        )
      }

      return ""
    }

    const StyledBadge = withStyles(() => ({
      badge: {
        backgroundColor: props.colors.fontAndCategoryColor,
        color: "white"
      },
    }))(Badge);

    return (
      <React.Fragment>

        {
          props.logo && (
            <div className={"logoEstaContainer"}>
              <div className={"logoEsta"} style={{backgroundImage: `url("${LogoLink}${props.logo}")`}}/>
            </div>
          )}


        <div className={"headerContainer"}>
          <div className={"header"}>

            <div className={"leftHeader"}>
              {
                props.chat ? (
                  <Link onClick={() => setStateContainer("menu")}>
                    <ArrowBackIosIcon className={"headerButton"}/>
                  </Link>
                ) : (
                  <Link onClick={() => props.setCurrentMenu(0)}>
                    <ArrowBackIosIcon className={"headerButton"}/>
                  </Link>
                )
              }

            </div>

            {
              !props.onlyAds && (
                <>
                  <Chat/>

                  <Link onClick={() => setShowCart(true)}>
                    <div className={"right"}>

                      <img src={`/assets/img/menu/cart_yellow.png`} className={"cartHeader"}
                           alt={"French"}/>

                      <StyledBadge className={"pinCart"} badgeContent={props.notValidated}/>

                    </div>
                  </Link>
                </>
              )
            }

            {
              !props.logo && (
                <div className={"center logoHeader"} style={{zIndex: 0}}>
                  <h4 className={"logoHeader"}>
                    {localStorage.getItem("name_establishment")}
                  </h4>
                </div>
              )
            }
          </div>
        </div>

        {
          upperCategory(MENU_CHOICE.ALCOOLS, props.menu.ALCOOLS)
        }
        {
          upperCategory(MENU_CHOICE.SOFTS, props.menu.SOFTS)
        }
        {
          upperCategory(MENU_CHOICE.OTHERS, props.menu.OTHERS)
        }
        {
          upperCategory(MENU_CHOICE.FOODS, props.menu.FOODS)
        }

        <Confetti numberOfPieces={props.animate === CART_ANIMATION.SUCCESS ? 100 : 0} />

        {props.animate === CART_ANIMATION.SUCCESS && (
          <>
            <div className={"actionPin"}>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          </>
        )}

      </React.Fragment>
    );
  }
;

const mapStateToProps = (state) => ({
  token: state.session.token,
  language: state.language.language,
  animate: state.cart.animate,
  notValidated: state.cart.notValidated,
  logo: state.session.logo,
  colors: state.session.colors,
  onlyAds: state.session.onlyAds,
  menu: state.menu.menu
});

export default connect(mapStateToProps)(Header2)
