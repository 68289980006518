let BackEnd;

if (process.env.NODE_ENV === 'development') {
  BackEnd = "https://dev.api.evenights.com/api/tablet";
} else {
  BackEnd = "https://api.evenights.com/api/tablet";
}

export const appVersion = "v1.2.0"

export const BackEndLink = BackEnd;

export const AssetsLink = "https://files.evenights.com/assets/img/establishment/articles/";
export const LogoLink = "https://files.evenights.com/assets/img/establishment/logo/";
export const AdMediaLink = "https://files.evenights.com/assets/ad/";
export const MenuMediaLink = "https://files.evenights.com/assets/menu/";
export const FlagsMediaLink = "https://files.evenights.com/assets/flags/";
export const AssetsMediaLink = "https://files.evenights.com/assets/";

const param = new URLSearchParams();
param.append("token", localStorage.getItem("session_tablet_customer"));
param.append("id_table", localStorage.getItem("id_table"));
param.append("id_tablet_customer", localStorage.getItem("id_tablet_customer"));
param.append("id_establishment", localStorage.getItem("id_establishment"));

export const loggedParams = param;

export const MOTHER_CATEGORIES = {};

MOTHER_CATEGORIES.ALCOOLS = "ALCOOLS"
MOTHER_CATEGORIES.SOFTS = "SOFTS"
MOTHER_CATEGORIES.SOFT_GLASS = "SOFT_GLASS"
MOTHER_CATEGORIES.OTHERS = "OTHERS"
MOTHER_CATEGORIES.FOODS = "FOODS"
MOTHER_CATEGORIES.CHOICES = "CHOICES"

export const MENU_CHOICE = {};

MENU_CHOICE.VODKA = "VODKA";
MENU_CHOICE.WHISKY = "WHISKY";
MENU_CHOICE.GIN = "GIN";
MENU_CHOICE.RHUM = "RHUM";
MENU_CHOICE.TEQUILA = "TEQUILA";
MENU_CHOICE.WINE_RED = "WINE_RED";
MENU_CHOICE.WINE_ROSE = "WINE_ROSÉ";
MENU_CHOICE.WINE_WHITE = "WINE_WHITE";
MENU_CHOICE.BEER = "BEER";
MENU_CHOICE.COCKTAIL = "COCKTAIL";
MENU_CHOICE.LIQUOR = "LIQUOR";
MENU_CHOICE.SHOT = "SHOT";
MENU_CHOICE.CHAMPAGNE = "CHAMPAGNE";
MENU_CHOICE.PACK = "PACK";
MENU_CHOICE.SOFT_GLASS = "SOFT_GLASS";
MENU_CHOICE.GLASS = "GLASS";
MENU_CHOICE.OTHER_BOTTLES = "OTHER_BOTTLES";

MENU_CHOICE.ALCOOLS = [
  MENU_CHOICE.VODKA,
  MENU_CHOICE.WHISKY,
  MENU_CHOICE.PACK,
  MENU_CHOICE.GIN,
  MENU_CHOICE.RHUM,
  MENU_CHOICE.TEQUILA,
  MENU_CHOICE.WINE_RED,
  MENU_CHOICE.WINE_ROSE,
  MENU_CHOICE.WINE_WHITE,
  MENU_CHOICE.BEER,
  MENU_CHOICE.COCKTAIL,
  MENU_CHOICE.LIQUOR,
  MENU_CHOICE.SHOT,
  MENU_CHOICE.CHAMPAGNE,
  MENU_CHOICE.GLASS
];


MENU_CHOICE.SODA = "Soda";
MENU_CHOICE.JUICE = "Juice";
MENU_CHOICE.WATER = "Water";

MENU_CHOICE.SOFTS = [
  MENU_CHOICE.SODA,
  MENU_CHOICE.JUICE,
  MENU_CHOICE.WATER
]


MENU_CHOICE.SWEETS = "Sweets";
MENU_CHOICE.CIGARETTES = "Cigarettes";
MENU_CHOICE.BALLOONS = "Balloons";
MENU_CHOICE.CHICHAS = "Chichas";

MENU_CHOICE.FOODS = [
  MENU_CHOICE.FOOD = "FOOD",
  MENU_CHOICE.STARTER = "STARTER",
  MENU_CHOICE.MAIN_FOOD = "MAIN_FOOD",
  MENU_CHOICE.DESERT = "DESERT"
];

MENU_CHOICE.OTHERS = [
  MENU_CHOICE.SWEETS,
  MENU_CHOICE.CIGARETTES,
  MENU_CHOICE.BALLOONS,
  MENU_CHOICE.CHICHAS,
]


MENU_CHOICE.SOFT = "soft";
MENU_CHOICE.SOFT_GLASS = "SOFT_GLASS";

export const CART_ANIMATION = {};

CART_ANIMATION.LOADING = "loading";
CART_ANIMATION.SUCCESS = "success";
CART_ANIMATION.FAIL = "fail";
CART_ANIMATION.NOTHING = "";

export const adTimeout = 50000;
export const nextAdTimeout = 600000;

export const cartTimeout = 60000;

export const annoucementTimeout = 3600000;
export const annoucementClose = 300000;

export function ColorLuminance(inHex, inLum) {

  let hex = String(inHex).replace(/[^0-9a-f]/gi, '');

  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const lum = inLum || 0;

  // convert to decimal and change luminosity
  let rgb = "#", c, i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += (`00${c}`).substr(c.length);
  }

  return rgb;
}

function rgbToYIQ({r, g, b}) {
  return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

const rgbToHex = (rgb) => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

function hexToRgb(hex) {
  if (!hex || hex === '') {
    return undefined;
  }

  const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
  const match = matchColors.exec(hex);
  if (match !== null) {
    return {
      r: parseInt(match[1], 16),
      g: parseInt(match[2], 16),
      b: parseInt(match[3], 16)
    }
  }

  const matchColorsRGBA = /rgba\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
  const matchRGBA = matchColorsRGBA.exec(hex);
  if (matchRGBA !== null) {
    return {
      r: parseInt(match[1], 16),
      g: parseInt(match[2], 16),
      b: parseInt(match[3], 16)
    }
  }

  let toCalcul = hex;

  if (hex.length === 9) {
    toCalcul = hex.slice(0, 7)
  }

  const result =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(toCalcul);

  if (result) return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  }

  return ""
}

export function contrast(colorHex, threshold = 128) {
  if (colorHex === undefined) {
    return '#000';
  }

  const rgb = hexToRgb(colorHex);

  if (rgb === undefined) {
    return '#000';
  }

  return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
}

const matchColorsRGBA = /rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(\d{1,3})\)/;
const matchColorsRGB = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;

const parseRgbAtoHEX = (input) => {

  let rgb = {
    r: null,
    g: null,
    b: null
  }

  const match = matchColorsRGB.exec(input.replace(' ', ''));
  if (match !== null) {
    rgb = {
      r: parseInt(match[1], 10),
      g: parseInt(match[2], 10),
      b: parseInt(match[3], 10),
    }
  }

  const matchRGBA = matchColorsRGBA.exec(input.replace(' ', ''));
  if (matchRGBA !== null) {
    rgb = {
      r: parseInt(matchRGBA[1], 10),
      g: parseInt(matchRGBA[2], 10),
      b: parseInt(matchRGBA[3], 10),
    }
  }

  return `#${rgbToHex(rgb.r)}${rgbToHex(rgb.g)}${rgbToHex(rgb.b)}`
}

export const invertColor = (col) => {

  let toCalcul = col;

  if (col.length === 9) {
    toCalcul = col.slice(0, 7)
  }

  if (matchColorsRGBA.exec(toCalcul.replace(' ', '')) !== null || matchColorsRGB.exec(toCalcul.replace(' ', '')) !== null) {
    toCalcul = parseRgbAtoHEX(toCalcul);
  }

  const colors = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
  let inverseColor = '#'
  toCalcul.toLowerCase().replace('#', '').split('').forEach(i => {
    const index = colors.indexOf(i)
    inverseColor += colors.reverse()[index]
  })

  return inverseColor
}
