import React from "react";
import "./css/bottleSize.css"
import Link from "@material-ui/core/Link";
import {KeyboardArrowDown} from "@material-ui/icons";
import {connect} from "react-redux";
import "./css/choiceSofts.css"
import Grid from "@material-ui/core/Grid";
import {AssetsLink, BackEndLink, CART_ANIMATION, loggedParams} from "../../constants/global";
import {
  setBackDrop,
  setChoice,
  setSelectedSoft,
  setShowError,
  setShowSoft,
  setShowValidateModal
} from "../../actions/menu";
import {Zoom} from "@material-ui/core";
import i18next from "i18next";
import {addNotValidated, setAnimateCart} from "../../actions/cart";
import axios from "axios";
import {setStateContainer} from "../../actions/session";

const ChoiceSofts = (props) => {

  const [booleanTransition, setBooleanTransition] = React.useState([]);
  const [activate, setActivate] = React.useState(true);
  const [showButton, setShowButton] = React.useState(true);
  const [countSoft, setCountSoft] = React.useState(3);

  React.useEffect(() => {
    if (props.countSoft === 0) {
      setCountSoft(3)
    } else {
      setCountSoft(parseInt(props.countSoft, 0))
    }
  }, [props.countSoft]);

  let counter = 0;
  let counterChoice = 0;

  const {
    soft,
    selectedSoft,
    addingChoice
  } = props;

  const addCartChoiceSoft = () => {
    const idArticles = props.selectedSoft.map(s => s.id_article).join(",");
    setBackDrop(true);
    setAnimateCart(CART_ANIMATION.LOADING);
    const params = loggedParams.valueOf();
    params.set("id_article", `${props.idArticleSelected},${idArticles}`);
    params.set("quantity", props.sizeChoice.quantity);
    axios.post(`${BackEndLink}/cart/add`, params).then(result => {
      setShowSoft(false);
      params.delete("id_article");
      params.delete("quantity");
      setBackDrop(false);
      if (result.data.error === 0) {
        setTimeout(() => {
          setAnimateCart(CART_ANIMATION.SUCCESS);
          setShowValidateModal(true);
          setShowButton(true)
        }, 800)
        setActivate(true);
        addNotValidated();
        setChoice(false);
      } else {
        setStateContainer("waiting");
      }
    })
  };

  const addCartMenuChoiceSoft = () => {

    setBackDrop(true);
    setAnimateCart(CART_ANIMATION.LOADING);
    const params = loggedParams.valueOf();

    const idArticles = props.selectedSoft.map(s => s.id_article).join(",");
    setShowSoft(false);

    params.set("id_article", `${addingChoice.idArticleChoice},${idArticles}`);
    params.set("id_menu_choice", addingChoice.sizeBottle.id_menu_choice);
    params.set("id_article_choice", addingChoice.sizeBottle.id_article);
    params.set("quantity", props.sizeChoice.quantity);

    axios.post(`${BackEndLink}/cart/addChoice`, params).then(result => {
      setBackDrop(false);
      params.delete("id_article");
      params.delete("id_menu_choice");
      params.delete("id_article_choice");
      params.delete("quantity");
      if (result.data.error === 0) {
        setTimeout(() => {
          setAnimateCart(CART_ANIMATION.SUCCESS);
          setShowValidateModal(true);
          setActivate(true);
          setShowButton(true)
        }, 800)
        addNotValidated();
        setChoice(false);
      } else {
        setStateContainer("waiting");
      }
    })
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!props.showSoft) {
        setSelectedSoft([])
      }
    }, 1000)
    if (props.showSoft) {
      setShowButton(true);
    }
  }, [props.showSoft])

  const addCartSoftComponent = () => {
    setActivate(false);
    setShowButton(false);
    if (props.choice) {
      addCartMenuChoiceSoft()
    } else {
      addCartChoiceSoft()
    }
  }

  const handleClose = () => {
    setShowSoft(false);
  }

  const addSoft = (newSoft) => {
    if (newSoft.state_article === "1") {
      return;
    }

    if (selectedSoft.length >= countSoft) {
      return;
    }
    const newSelectedSoft = [...selectedSoft];
    newSelectedSoft.push(newSoft);
    setSelectedSoft(newSelectedSoft);
  };

  const removeSoft = (softToRemove) => {
    const newSelectedSoft = [...selectedSoft];
    let deleted = false;
    newSelectedSoft.forEach((choice, idx) => {
      if (softToRemove.id_article === choice.id_article && !deleted) {
        newSelectedSoft.splice(idx, 1);
        deleted = true;
      }
    })
    setSelectedSoft(newSelectedSoft);
  }

  return (
    <>
      {
        props.showSoft &&
        <Link underline={"none"} onClick={handleClose}>
          <div className={"emptyZone"}/>
        </Link>
      }

      <div className={props.showSoft ? "choiceSoftContainer choiceSoftContainerShow" : "choiceSoftContainer"}>

        <div className={"bottleSizeLess"}>
          <KeyboardArrowDown/>
        </div>

        <div className={"choiceSoftTitle"} style={{color: props.colors.fontAndCategoryColor}}>
          {
            selectedSoft.length < countSoft ? (
              selectedSoft.length < countSoft ? `${i18next.t('choiceSoft.someSelected')} ${countSoft - selectedSoft.length} ${i18next.t('choiceSoft.someSelected_2')}`
                : `${i18next.t('choiceSoft.someSelected')} ${countSoft - selectedSoft.length} ${i18next.t('choiceSoft.someSelected_2_sing')}`

            ) : (
              i18next.t('choiceSoft.fullSelected')
            )
          }
        </div>

        {
          activate && (
            <Zoom in={selectedSoft.length > 0}>
              <div className={"choiceSoftAction"}>
                <Link underline={"none"} onClick={() => {
                  if (activate && selectedSoft.length === countSoft) {
                    addCartSoftComponent()
                  } else {
                    setShowError(`${i18next.t('choiceSoft.someSelected')} ${countSoft - selectedSoft.length} ${countSoft - selectedSoft.length === 1 ? i18next.t('choiceSoft.someSelected_2_sing') : i18next.t('choiceSoft.someSelected_2')}`)
                  }
                }}>
                  <div className={selectedSoft.length === countSoft ? "yellowButtonFull" : "yellowButtonDisabled"}
                       style={{
                         color: selectedSoft.length === countSoft ? props.colors.fontAndCategoryColor : "",
                         backgroundColor: selectedSoft.length === countSoft ? props.colors.buttonOrderColor : "",
                       }}>
                    {i18next.t("cart.add")}
                  </div>
                </Link>
              </div>
            </Zoom>
          )
        }

        <Grid container className={"choiceSoftContainerSofts"}
              direction="column"
              alignItems={"center"} justify={"center"}
        >
          {
            Object.keys(soft).map((choice) => {

              counter += 1;

              if (Object.keys(soft).length === 3) {
                return (
                  <Link key={`choice_${soft[choice].id_article}`} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={"choiceSoftSizeChoiceContainerBig choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>

                )
              }
              /*---------------------------------------*/
              if (Object.keys(soft).length === 4) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 4
                            ? "choiceSoftSizeChoiceContainerBig choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>

                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 5) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 2 || counter === 4 || counter === 5
                            ? "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerBig choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>

                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 6) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={"choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 7) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 2 || counter === 6 || counter === 7
                            ? "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 8) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 2 || counter === 3 || counter === 6 || counter === 7 || counter === 8
                            ? "choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 9) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={"choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              if (counter > 9)
                return "";

              else if (Object.keys(soft).length >= 9) {
                return (
                  <Link key={soft[choice].id_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={"choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }

              return ""
            })
          }
        </Grid>

        {
          selectedSoft.length > 0 && (
            <div className={"choiceSoftSelectedSofts"}>
              {
                Object.keys(selectedSoft).map((choice, idx) => {

                  selectedSoft[choice].uniqueID = counterChoice += 1

                  return (
                    <div className={"choiceSoftSizeChoiceSelectedContainer"}>
                      <Link key={`choice_${selectedSoft[choice].uniqueID}`} onClick={() => {
                        const tempBooleanArray = [...booleanTransition];
                        tempBooleanArray[selectedSoft[choice].uniqueID] = true;
                        setBooleanTransition(tempBooleanArray);
                        setTimeout(() => {
                          removeSoft(selectedSoft[choice]);
                          setBooleanTransition([])
                        }, 100)
                      }}>
                        <Grid zeroMinWidth item xs={4}
                              className={"choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                        >
                          <div className={"choiceSoftSizeChoice choiceSoftSizeChoiceSelected"}>
                            <img alt="product" className={"choiceSoftImage"}
                                 src={`${AssetsLink}${selectedSoft[choice].article_img}`}/>
                            <div className={"choicePricePlus"}>
                              <div
                                className={"choicePricePlusText"}>+{selectedSoft[choice].price_article}{props.currency}</div>
                            </div>

                          </div>
                        </Grid>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          )
        }

      </div>

    </>
  )
}

const mapStateToProps = (state) => ({
  showSoft: state.menu.showSoft,
  selectedSoft: state.menu.selectedSoft,
  choice: state.menu.choice,
  countSoft: state.menu.countSoft,
  addingChoice: state.menu.addingChoice,
  idArticleSelected: state.menu.idArticleSelected,
  currency: state.session.currency,
  colors: state.session.colors,
  sizeChoice: state.menu.sizeChoice
});

export default connect(mapStateToProps)(ChoiceSofts)
