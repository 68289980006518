const effects = [
  { name: "normal", filter: "none", overlays: [] },
  {
    name: "clarendon",
    filter: "contrast(1.2) saturate(1.35)",
    overlays: [
      {
        backgroundColor: "rgba(127, 187, 227, 0.2)",
        mixBlendMode: "overlay"
      }
    ]
  },
  {
    name: "gingham",
    filter: "brightness(1.05) hue-rotate(-10deg)",
    overlays: [
      {
        backgroundColor: "#e6e6fa",
        mixBlendMode: "soft-light"
      }
    ]
  },
  {
    name: "moon",
    filter: "grayscale(1) contrast(1.1) brightness(1.1)",
    overlays: [
      {
        backgroundColor: "#a0a0a0",
        mixBlendMode: "soft-light"
      },
      {
        backgroundColor: "#383838",
        mixBlendMode: "lighten"
      }
    ]
  },
  {
    name: "lark",
    filter: "contrast(0.9)",
    overlays: [
      {
        backgroundColor: "#22253f",
        mixBlendMode: "color-dodge"
      },
      {
        backgroundColor: "rgba(242, 242, 242, 0.8)",
        mixBlendMode: "darken"
      }
    ]
  }
];

export default effects;
