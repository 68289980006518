import React from "react"
import {connect} from "react-redux";
import axios from "axios";
import {BackEndLink, loggedParams} from "../../constants/global";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import './css/review.css'
import i18next from "i18next";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {setBackDrop} from "../../actions/menu";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const Review = (props) => {

  const [tips, setTips] = React.useState(0)

  const sendReview = () => {

    const params = loggedParams.valueOf();
    params.set("tips", tips);

    setBackDrop(true)

    axios.post(`${BackEndLink}/features/tips`, params).then(result => {
      setBackDrop(false)
      props.setTips(false)
      props.refreshBill()
    }).catch(e => setBackDrop(false))
  }

  const showTips = value => (
    <Grid item className={"tc"}>
      <Avatar alt="Remy Sharp" src="/broken-image.jpg"
              className={parseInt(tips) === value ? "tips tipsSelected" : "tips"} onClick={() => setTips(value)}>
        {value}{props.currency}
      </Avatar>
    </Grid>
  )

  const notDefaultValue = (value) => {
    value = parseInt(value)
    return value !== 0 && value !== 10 && value !== 20 && value !== 30 && value !== 50 && value !== 100
  }

  return <Dialog
    maxWidth={"sm"}
    fullWidth={true}
    open={props.open}
    onClose={() => props.setTips(false)}
  >
    <div className="reviewTitle">{i18next.t('tips.title')}</div>
    <DialogContent>
      <DialogContentText className={"tc"}>
        <Grid spacing={2} container alignContent={"center"} justify={"center"} alignItems={"center"}>

          {showTips(0)}
          {showTips(10)}
          {showTips(20)}
          {showTips(30)}
          {showTips(50)}
          {showTips(100)}

          <div className="tipsManual">{i18next.t('tips.manual')}</div>

          <Grid item xs={12} className={"tc"}>
            <TextField
              value={tips}
              onChange={e => {
                if (e.target.value.length <= 4) {
                  setTips(e.target.value)
                }
              }}
              variant="outlined"
              InputProps={{
                classes: {
                  input: notDefaultValue(tips) ? "tipsField tipsFieldSelected" : "tipsField",
                  root: notDefaultValue(tips) ? "tipsFieldRoot tipsFieldRootSelected" : "tipsFieldRoot"
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <span style={{fontSize: "2em", color: "#f1c40f"}}>{props.currency}</span>
                  </InputAdornment>
                ),
              }}
              className={"tipsField"}
            />
          </Grid>

          <Grid item xs={4} className={"tc"}>
            <Link onClick={() => props.setTips(false)}>
              <div className={"yellowButtonContained reviewButton"}>
                {i18next.t("global.ignore")}
              </div>
            </Link>
          </Grid>


          <Grid item xs={8} className={"tc"}>
            <Link onClick={sendReview}>
              <div className={"persButton yellowPersButton addToCart reviewButton"}>
                {i18next.t("global.send")}
              </div>
            </Link>
          </Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
  </Dialog>

}

const mapStateToProps = (state) => ({
  currency: state.session.currency,
});
export default connect(mapStateToProps)(Review)
