import React from 'react'
import axios from "axios";
import {BackEndLink} from "../../constants/global";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import CameraFeed from "./CameraFeed";
import {connect} from "react-redux";
import {setStateContainer} from "../../actions/session";

const Selfie = (props) => {

  const [percent, setPercent] = React.useState(0) ;
  const [uploading, setUploading] = React.useState(false) ;
  const [backendProcessing, setBackendProcessing] = React.useState(false) ;
  const [success, setSuccess] = React.useState(false) ;
  const [kill, setKill] = React.useState(false) ;

  const handleKill = () => {
    setKill(true) ;
    setStateContainer("menu") ;
  }

  React.useEffect(() => {
    return () => handleKill()  ;
  }, [])

  // Upload to local seaweedFS instance
  const uploadImage = async (file, email, deal, filter) => {

    setUploading(true) ;

    const formData = new FormData();
    formData.append("filter", filter);
    formData.append("file", file);
    formData.append("email", email);
    formData.append("deal", deal);
    formData.append("token", localStorage.getItem("session_tablet_customer")) ;
    formData.append("id_table", localStorage.getItem("id_table")) ;
    formData.append("id_tablet_customer", localStorage.getItem("id_tablet_customer")) ;
    formData.append("id_establishment", localStorage.getItem("id_establishment")) ;
    formData.append("id_order", props.order.id_order) ;

    const options = {
      onDownloadProgress: event => {
        const progress = Math.floor(((event.loaded / 2) / file.size) * 100) ;
        if(progress > 100) {
          setPercent(100) ;
          setBackendProcessing(true) ;
          setUploading(false) ;
          setTimeout(() => {
            setSuccess(false) ;
            setPercent(0) ;
          }, 3000)
        } else  {
          setPercent(progress) ;
        }
      }
    }

    axios.post(`${BackEndLink}/features/selfie`, formData , options)
      .then((response) => {
        setBackendProcessing(false) ;
        setSuccess(true) ;
        setUploading(false) ;
        setTimeout(() => {
          setSuccess(false) ;
          setPercent(0) ;
        }, 3000)
      })
      .catch(() => {
      });

  };

  return (
    <>

      {!kill && <CameraFeed logo={props.logo} handleKill={handleKill} kill={kill} sendFile={uploadImage}/>}
      {
        success && (
          <div className={"successDialogContent"}>
            <div className={"successDialog"}>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          </div>
        )
      }

      {
        uploading && (
          <div className={"successDialogContent"}>
            <div className={"successDialog"}>
              <LinearProgress style={{width:"90vw"}} variant="determinate" value={percent} />
              <div style={{paddingTop:"1vh", color: "white", fontSize: "1.6em"}}>{percent}%</div>
            </div>
          </div>
        )
      }

      {
        backendProcessing && (
          <div className={"successDialogContent"}>
            <div className={"successDialog"}>
              <CircularProgress />
            </div>
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  logo: state.session.logo,
  order: state.session.order
});

export default connect(mapStateToProps)(Selfie)

