import React from "react"
import './css/secondMenu.css'
import Header2 from "../Header/Header2";
import Flicking from "@egjs/react-flicking";
import {Fade} from "@egjs/flicking-plugins";
import Grid from "@material-ui/core/Grid";
import Product from "./Product";
import {connect} from "react-redux";
import {AssetsLink, MENU_CHOICE,} from "../../constants/global";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setSos} from "../../actions/menu";
import BottleSizeChoice from "./BottleSizeChoice";
import ChoiceSofts from "./ChoiceSofts";
import ChoiceSoftsGlass from "./ChoiceSoftsGlass";

const SecondMenu = (props) => {

  const [progress, setProgress] = React.useState();
  const [soft, setSoft] = React.useState([]);
  const [softGlass, setSoftGlass] = React.useState([]);
  const [ready, setReady] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const flicking = React.createRef();

  const plugins = [new Fade()];
  const height = 80;
  const width = 45;

  const changeMenuChoice = (choice) => {
    props.setMenuChoice(choice);
    setProducts([]);
    setReady(false);
  };

  const checkConditionalDisplayAfter = (conditional_display_article) => {
    if (!conditional_display_article)
      return 1;

    const minHour = parseInt(conditional_display_article.split(":")[0], 10);
    const minMinute = parseInt(conditional_display_article.split(":")[1], 10);

    const currentDate = new Date();
    const currentHour = parseInt(currentDate.getHours(), 10);
    const currentMinute = parseInt(currentDate.getMinutes(), 10);

    if (currentHour < minHour) {
      return 0;
    }

    if (currentHour === minHour && currentMinute < minMinute) {
      return 0;
    }

    return 1;
  }

  const checkConditionalDisplayBefore = (conditional_display_article) => {
    if (!conditional_display_article)
      return 1;

    const maxHour = parseInt(conditional_display_article.split(":")[0], 10);
    const maxMinute = parseInt(conditional_display_article.split(":")[1], 10);

    const currentDate = new Date();
    const currentHour = parseInt(currentDate.getHours(), 10);
    const currentMinute = parseInt(currentDate.getMinutes(), 10);

    if (currentHour > maxHour) {
      return 0;
    }

    if (currentHour === maxHour && currentMinute >= maxMinute) {
      return 0;
    }

    return 1;
  }

  React.useEffect(() => {

    if (props.menu.CHOICES)
      setSoft(props.menu.CHOICES);

    if (props.menu.SOFT_GLASS)
      setSoftGlass(props.menu.SOFT_GLASS);

    if (ready)
      changeMenuChoice(props.menuChoice);




  }, [props.menu])

  React.useEffect(() => {
    if(ready) {
      if(props.articleFocus) {
        let index = 0 ;
        let ctr = 0 ;
        products.forEach(p => {
          if(checkConditionalDisplayAfter(p.conditional_display_article_after) && checkConditionalDisplayBefore(p.conditional_display_article_before) && p.hide_on_orders_article !== "1") {
            if (parseInt(props.articleFocus, 10) === parseInt(p.id_article, 10)) {
              index = ctr;
            }
            ctr += 1 ;
          }
        })
        flicking.current.moveTo(index)
      }
    }
  }, [ready])

  React.useEffect(() => {

    if (products.length > 0)
      return;

    if (MENU_CHOICE.ALCOOLS.indexOf(props.menuChoice) > -1) {
      if (props.menu.ALCOOLS.categories && props.menu.ALCOOLS.categories[props.menuChoice])
        Object.keys(props.menu.ALCOOLS.categories[props.menuChoice].articles).forEach(article => {
          products.push(props.menu.ALCOOLS.categories[props.menuChoice].articles[article]);
        });
    }
    if (MENU_CHOICE.SOFTS.indexOf(props.menuChoice) > -1) {
      if (props.menu.SOFTS.categories && props.menu.SOFTS.categories[props.menuChoice])
        Object.keys(props.menu.SOFTS.categories[props.menuChoice].articles).forEach(article => {
          products.push(props.menu.SOFTS.categories[props.menuChoice].articles[article]);
        });
    }

    if (MENU_CHOICE.OTHERS.indexOf(props.menuChoice) > -1) {
      if (props.menu.OTHERS.categories && props.menu.OTHERS.categories[props.menuChoice])
        Object.keys(props.menu.OTHERS.categories[props.menuChoice].articles).forEach(article => {
          products.push(props.menu.OTHERS.categories[props.menuChoice].articles[article]);
        });
    }

    if (MENU_CHOICE.FOODS.indexOf(props.menuChoice) > -1) {
      if (props.menu.FOODS.categories && props.menu.FOODS.categories[props.menuChoice])
        Object.keys(props.menu.FOODS.categories[props.menuChoice].articles).forEach(article => {
          products.push(props.menu.FOODS.categories[props.menuChoice].articles[article]);
        });
    }

    setTimeout(() => {
      setReady(true);
    }, 100)


  }, [products]);


  return (
    <div style={{width: "100vw", overflow: "hidden"}}>
      <Header2 changeMenuChoice={changeMenuChoice} setMenuChoice={props.setMenuChoice}
               menuChoice={props.menuChoice} setCurrentMenu={props.setCurrentMenu}/>

      <div className="progress">
        <div
          className="thumb"
          ref={e => {
            setProgress(e);
          }}
        />
        <div className={"line"}/>
      </div>

      <Grid
        container
        className={"flickingContainer"}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} style={{zIndex: 0}}>
          {ready &&
          <Flicking
            ref={flicking}
            tag="div"
            viewportTag="div"
            cameraTag="div"
            onNeedPanel={(e) => {
              const flicking = e.currentTarget;
              const currentProgress = e.progress * 100;
              progress.style.marginLeft = `${currentProgress}%`;
              flicking.getAllPanels(true).forEach(panel => {
                const newHeight = height * panel.getVisibleRatio().toFixed(2);
                // eslint-disable-next-line no-param-reassign
                panel.getElement().style.height = `${newHeight}vh`;
                if (panel.getVisibleRatio() >= 1) {
                  const legend = panel.getElement().childNodes.item(0).childNodes.item(2).style;
                  legend.opacity = 1;
                } else {
                  const legend = panel.getElement().childNodes.item(0).childNodes.item(2).style;
                  legend.opacity = 0;
                }
              });
            }}
            onMoveEnd={(e) => {
              const flicking = e.currentTarget.getCurrentPanel().getElement();
              const legend = flicking.childNodes.item(0).childNodes.item(2).style;
              legend.opacity = 1;
            }}
            onMove={(e) => {
              const flicking = e.currentTarget;
              const currentProgress = e.progress * 100;
              progress.style.marginLeft = `${currentProgress}%`;
              flicking.getAllPanels(true).forEach(panel => {
                const legend = panel.getElement().childNodes.item(0).childNodes.item(2).style;
                legend.opacity = 0;
                const newHeight = height * panel.getVisibleRatio().toFixed(2);
                // eslint-disable-next-line no-param-reassign
                panel.getElement().style.height = `${newHeight}vh`;
              });
            }}
            circular={products.length > 1}
            infinite={true}
            duration={200}
            inputType={["touch", "mouse"]}
            collectStatistics={false}
            plugins={plugins}
            gap={30}
          >
            {
              products && products.length > 0 && products.filter(product => {

                  if((props.onlyAds && product.hide_on_bar_article === "1") || (!props.onlyAds && product.hide_on_orders_article === "1"))
                    return 0 ;

                  return checkConditionalDisplayAfter(product.conditional_display_article_after) && checkConditionalDisplayBefore(product.conditional_display_article_before)
              }).map(product => (
                  <Product
                    key={`${product.name_article} ${product.article_img} ${Math.random()}`}
                    width={width} height={height}
                    image={`${AssetsLink}${product.article_img}`}
                    idArticle={product.id_article}
                    name={product.name_article}
                    stateArticle={product.state_article}
                    category={props.menuChoice}
                    description={product.desc_article}
                    price={`${product.price_article}`}
                    choices={product.choices ? product.choices : null}
                    countSoft={product.soft_count_article}
                    promoted={product.promote}
                    endPromote={product.end_promote}
                    premiumPromote={product.premium_promote}
                    conditionalDisplay={product.conditional_display_article}
                  />
                )
              )
            }
          </Flicking>
          }
          {
            !ready && (
              <center><CircularProgress disableShrink/></center>
            )
          }
        </Grid>
      </Grid>
      {

      }

      {
        !props.onlyAds && (
          <Link onClick={() => setSos(true)}>
            <div className={"sosLeft"} style={{
              backgroundColor: props.colors.fontAndCategoryColor
            }}>
              <p className={"sosLeft_1"}>SOS</p>
              <p className={"sosLeft_2"}>Serveur</p>
            </div>
          </Link>
        )
      }

      <ChoiceSofts soft={soft}/>

      <ChoiceSoftsGlass soft={softGlass}/>

      <BottleSizeChoice/>

    </div>)
};

const mapStateToProps = (state) => ({
  menu: state.menu.menu,
  colors: state.session.colors,
  onlyAds: state.session.onlyAds,
});

export default connect(mapStateToProps)(SecondMenu)
