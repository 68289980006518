import React from "react"
import {connect} from "react-redux";
import axios from "axios";
import {BackEndLink, loggedParams} from "../../constants/global";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import './css/review.css'
import i18next from "i18next";
import Rating from "@material-ui/lab/Rating";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {setBackDrop} from "../../actions/menu";

const Review = (props) => {

  const [rateEstablishment, setRateEstablishment] = React.useState({
    rating: 5,
    comment: ""
  })

  const [rateApplication, setRateApplication] = React.useState({
    rating: 5,
    comment: ""
  })

  const sendReview = () => {

    const params = loggedParams.valueOf();
    params.set("ratingApplication", rateApplication.rating);
    params.set("commentApplication", rateApplication.comment);
    params.set("ratingEstablishment", rateEstablishment.rating);
    params.set("commentEstablishment", rateEstablishment.comment);

    setBackDrop(true)

    axios.post(`${BackEndLink}/features/review`, params).then(result => {
      setBackDrop(false)
      props.setReview(false)
      props.setTips(true)
    }).catch(e => setBackDrop(false))
  }

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon className={"reviewIcon"}/>,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon className={"reviewIcon"}/>,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon className={"reviewIcon"}/>,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon className={"reviewIcon"}/>,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon className={"reviewIcon"}/>,
      label: 'Very Satisfied',
    },
  };

  const IconContainer = (props) => {
    const {value, ...other} = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  const closeReview = () => {
    props.setReview(false)
    props.setTips(true)
  }

  return <Dialog
    maxWidth={"sm"}
    fullWidth={true}
    open={props.open}
    onClose={closeReview}
  >
    <div className="reviewTitle">{i18next.t('review.title')}</div>
    <DialogContent>
      <DialogContentText className={"tc"}>
        <Grid spacing={2} container alignContent={"center"} justify={"center"} alignItems={"center"}>
          <Grid item xs={12} className={"reviewTitlePart"}>
            {i18next.t('review.establishment')}
          </Grid>
          <Grid item>
            <Rating
              className={`rate_${rateEstablishment.rating}`}
              size="large"
              value={rateEstablishment.rating}
              onChange={(e, v) => setRateEstablishment({...rateEstablishment, rating: v})}
              getLabelText={(value) => customIcons[value].label}
              IconContainerComponent={IconContainer}
            />
          </Grid>
          <Grid item xs={12} className={"tc"}>
            <textarea value={rateEstablishment.comment}
                      onChange={e => setRateEstablishment({...rateEstablishment, comment: e.target.value})}
                      className={"reviewTextArea"} placeholder={i18next.t('review.placeholder')}/>
          </Grid>

          <Grid item xs={12} className={"reviewDivider"}/>

          <Grid item xs={12} className={"reviewTitlePart"}>
            {i18next.t('review.application')}
          </Grid>

          <Grid item>
            <Rating
              size="large"
              className={`rate_${rateApplication.rating}`}
              value={rateApplication.rating}
              onChange={(e, v) => setRateApplication({...rateApplication, rating: v})}
              getLabelText={(value) => customIcons[value].label}
              IconContainerComponent={IconContainer}
            />
          </Grid>

          <Grid item xs={12} className={"tc reviewBottom"}>
            <textarea value={rateApplication.comment}
                      onChange={e => setRateApplication({...rateApplication, comment: e.target.value})}
                      className={"reviewTextArea"} placeholder={i18next.t('review.placeholder')}/>
          </Grid>

          <Grid item xs={12} className={"tc reviewBottom"}>
            <img style={{width:"40%"}} src="https://evenights.com/assets/images/logo.png" alt="Logo" />
          </Grid>

          <Grid item xs={4} className={"tc"}>
            <Link onClick={closeReview}>
              <div className={"yellowButtonContained reviewButton"}>
                {i18next.t("global.ignore")}
              </div>
            </Link>
          </Grid>


          <Grid item xs={8} className={"tc"}>
            <Link onClick={sendReview}>
              <div className={"persButton yellowPersButton addToCart reviewButton"}>
                {i18next.t("global.send")}
              </div>
            </Link>
          </Grid>

        </Grid>

      </DialogContentText>
    </DialogContent>
    <DialogActions>

    </DialogActions>
  </Dialog>

}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Review)
