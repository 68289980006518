import ActionTypes from '../constants/actionTypes'

const initialState = {
  language: localStorage.getItem("language") === null || localStorage.getItem("language") === undefined ? "fr" : localStorage.getItem("language"),
} ;

export default function session(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_NEW_LANG:
      return {
        ...state,
        language: action.value
      } ;
    default:
      return state
  }
}
