import React from "react";
import Language from "../Features/Language";
import Waiting from "../Features/Waiting.jsx";
import Ad from "../Features/Ad";
import {connect} from "react-redux";
import axios from "axios";
import {BackEndLink, loggedParams} from "../../constants/global";
import Menu from "./Menu";
import Loading from "../Features/Loading";
import {setMenu, setShowValidateModal} from "../../actions/menu";
import {setNewNotValidated, setShowCart} from "../../actions/cart";
import {refreshMessage, setEmail, setMinPrice, setOrder, setPin, setStateContainer} from "../../actions/session";
import "./../../styles/buttons.css"
import {Dialog, DialogActions, DialogContentText, DialogTitle, Link} from "@material-ui/core";
import Selfie from "../Features/Selfie";
import Chat from "../Features/Chat";
import Preload from "react-preload";
import CircularProgress from "@material-ui/core/CircularProgress";
import i18next from "i18next";
import PlugBattery from "../Features/PlugBattery";
import Cart from "../Cart/Cart"
import Sos from "../Features/Sos";
import DigitalWaitressAlert from "../Features/DigitalWaitressAlert";
import ErrorMark from "../Features/SmallProps/ErrorMark";
import Billing from "../Features/Billing";
import SuccessMark from "../Features/SmallProps/CheckMark";
import LegalMention from "../Features/LegalMention";
import ErrorMarkRefused from "../Features/SmallProps/ErrorMarkRefused";

const MenuContainer = (props) => {

  const [showCharge, setShowCharge] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);
  const timeoutRef = React.useRef();
  // const [imageLink, setImageLink] = React.useState(null);
  // const [announcement, setAnnouncement] = React.useState(false);

  // let image = [];

  const startNewOrder = () => {
    props.newChat.splice(0, props.newChat.length)
    refreshMessage({})
    setStateContainer("loading");
    setEmail({
      email: "",
      email2: "",
      email3: "",
      email4: "",
      email5: ""
    })
    if (!props.menu) {
      axios.post(`${BackEndLink}/menu/get`, loggedParams).then(result => {
        setMenu(result.data);
        axios.post(`${BackEndLink}/Order/create`, loggedParams).then(resultCreate => {
          if (resultCreate.data.error === 0) {
            setStateContainer("menu");
            localStorage.setItem("pin", resultCreate.data.pin);
            setPin(resultCreate.data.pin);
            setShowPin(false);
            setOrder(resultCreate.data.order);
            setNewNotValidated(0);
            setMinPrice(resultCreate.data.order.min_order_table);
          } else {
            setStateContainer("waiting");
          }
        }).catch((e) => {
          setStateContainer("waiting");
          alert(e)
        });
      });
    } else {
      axios.post(`${BackEndLink}/Order/create`, loggedParams).then(resultCreate => {
        if (resultCreate.data.error === 0) {
          setStateContainer("menu");
          localStorage.setItem("pin", resultCreate.data.pin);
          setPin(resultCreate.data.pin);
          setShowPin(false);
          setOrder(resultCreate.data.order);
          setNewNotValidated(0);
        } else {
          setStateContainer("waiting");
        }
      }).catch((e) => {
        setStateContainer("waiting");
        alert(e)
      });
    }
  };

  React.useEffect(() => {
    if (props.stateContainer === "ad" || props.stateContainer === "language")
      return;
    if (props.order) {
      setStateContainer("menu");
    } else {
      setStateContainer("waiting")
    }
  }, [props.order]);


  const handleClose = () => {
    setShowPin(false);
  };

  /**
   const launchAnnoucement = (isForce = false) => {

    if(imageLink !== null && !isForce)
      return ;

    axios.post(`${BackEndLink}/Announcement/get`, loggedParams).then(result => {
      if (result.data.media_link && result.data.media_link.match(/gif|png|jpeg|jpg/)) {
        image = [`${AdMediaLink}${result.data.media_link}`];
        setImageLink(image[0]);
      }
    })

    setTimeout(() => {
      if (props.stateContainer !== "waiting") {
        setAnnouncement(true);
        setTimeout(() => {
          setAnnouncement(false)
        }, annoucementClose);
        launchAnnoucement(true)
      }
    }, annoucementTimeout)
  }
   */

  const notCharging = (timeout = 600000) => {
    timeoutRef.current = setTimeout(() => {
      notCharging();
      setShowCharge(true);
    }, timeout)
  }

  const batteryCheckInit = () => {
    if (navigator.getBattery && !props.ignorePlug)
      navigator.getBattery().then((batteryManager) => {

        if (batteryManager.level * 100 <= 20 && !batteryManager.charging) {
          axios.post(`${BackEndLink}/features/unpluged`, loggedParams);
          setShowCharge(true)
          notCharging(100000)
        } else if (!batteryManager.charging) {
          axios.post(`${BackEndLink}/features/unpluged`, loggedParams);
          setShowCharge(true)
          notCharging(600000)
        }

        batteryManager.addEventListener('levelchange', () => {
          if (batteryManager.level * 100 <= 20 && !batteryManager.charging) {
            axios.post(`${BackEndLink}/features/unpluged`, loggedParams);
            setShowCharge(true)
            notCharging()
          }
        });

        batteryManager.addEventListener('chargingchange', () => {
          if (batteryManager.charging) {
            axios.post(`${BackEndLink}/features/pluged`, loggedParams);
            clearTimeout(timeoutRef.current)
            setShowCharge(false)
          } else if (batteryManager.level * 100 <= 20 && !batteryManager.charging) {
            axios.post(`${BackEndLink}/features/unpluged`, loggedParams);
            setShowCharge(true)
            notCharging(100000)
          } else {
            axios.post(`${BackEndLink}/features/unpluged`, loggedParams);
            setShowCharge(true)
            notCharging(600000)
          }
        });

      })
  }


  React.useEffect(() => {
    batteryCheckInit()
  }, [])

  return (
    <>
      <ErrorMark/>
      <ErrorMarkRefused/>
      <LegalMention/>
      <SuccessMark/>
      <Sos/>
      <Cart/>
      <DigitalWaitressAlert
        size={"xl"}
        open={props.showValidateCart}
        callbackClose={() => setShowValidateModal(false)}
        message={i18next.t("cart.alert.sentence")}
        buttonLeft={i18next.t("cart.alert.left")}
        buttonLeftCallback={() => {
          setTimeout(() => setShowCart(true), 200)
          setShowValidateModal(false)
        }}
        buttonRight={i18next.t("cart.alert.right")}
        buttonRightCallback={() => {
          setShowValidateModal(false)
        }}
        image={`/assets/img/menu/warning.png`}
      />

      <Billing type={props.variant !== "onlyBill" ? "asking" : null}
               checkToken={props.variant === "onlyBill" ? props.checkToken : () => {
               }}/>

      <Ad variant={props.variant === "onlyAds" ? "onlyAds" : ""}/>

      {props.stateContainer === "loading" && <Loading/>}
      {props.stateContainer === "language" && <Language/>}
      {props.stateContainer === "menu" && <Menu/>}
      {props.stateContainer === "waiting" && <Waiting startNewOrder={startNewOrder}/>}
      {props.stateContainer === "selfie" && <Selfie/>}
      {props.stateContainer === "chat" && <Chat/>}

      {(showCharge && !props.ignorePlug) && <PlugBattery setShowCharge={setShowCharge}/>}

      {/**
        announcement && imageLink && (
          <Link onClick={() => setAnnouncement(false)}>
            <div className={"announcements"}>
              {imageLink.match(/gif|png|jpeg|jpg/) && (
                <Preload
                  loadingIndicator={<CircularProgress variant="determinate"/>}
                  images={image}
                  autoResolveDelay={3000}
                  onError={() => setAnnouncement(false)}
                >
                  <img alt="az" style={{height: "100vh", width: "100vw"}} src={imageLink}/>
                </Preload>
              )}
            </div>
          </Link>
        )
      */}

      <Dialog
        id={"d"}
        open={showPin}
        className={"pinDialogMain"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: "pinContainerDialog"
        }}
      >
        <div className={"pinContent"}>
          <DialogTitle className={"pinTitle"} id="alert-dialog-title">
            <div className={"pinTitle"}>{i18next.t("pin.important")}</div>
          </DialogTitle>
          <div className={"pinSentenceContainer"} id="alert-dialog-description">
            {i18next.t("pin.message_1")}
          </div>
          <br/>
          <div className={"pinContainer"} id="alert-dialog-description">
            <p className={"pinCode"}>{props.pin}</p>
          </div>
          <div className={"pinSentenceContainer"} id="alert-dialog-description">
            {i18next.t("pin.message_2")}<br/>{i18next.t("pin.message_3")}
          </div>
          <DialogActions>
            <div onClick={handleClose} className={"yellowButtonFull pinBtn"}>{i18next.t("pin.remember")}</div>
          </DialogActions>
          <DialogContentText className={"pinNB"} id="alert-dialog-description">
            <p className={"pinLabel"}>{i18next.t("pin.nb")}</p>
          </DialogContentText>
        </div>
      </Dialog>
    </>
  )
};

const mapStateToProps = (state) => ({
  order: state.session.order,
  menu: state.menu.menu,
  refill: state.session.refill,
  pin: state.session.pin,
  notValidated: state.cart.notValidated,
  showValidateCart: state.menu.showValidateCart,
  stateContainer: state.session.stateContainer,
  language: state.language.language,
  backdrop: state.menu.backdrop,
  newChat: state.session.newChat,
  ignorePlug: state.session.ignorePlug
});

export default connect(mapStateToProps)(MenuContainer)

