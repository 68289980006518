import React from "react";
import {connect} from "react-redux";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import './css/chat.css'
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import i18next from "i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {setLaunchAd, setSelectedChat, setStateContainer} from "../../actions/session";

const Chat = props => {
  const [show, setShow] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newMessages, setNewMessages] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (idTable) => {
    setSelectedChat(idTable)
    setLaunchAd(false);
    setStateContainer("chat")
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (props.newChat.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
    let count = 0;
    props.newChat.forEach(chat => {
      if (chat.newMessages === 1)
        count += 1;
    })

    setNewMessages(count);
  }, [props.refreshChat])

  React.useEffect(() => {
    if (props.launchAd) {
      setAnchorEl(null)
    }
  }, [props.launchAd])

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  let classname = "notifMessageContainer"

  if (newMessages > 0 && props.variant === "ads") {
    classname = "shakeHeader notifMessageContainerBottom"
  } else if (newMessages > 0 && props.variant !== "ads")
    classname = "shakeHeader notifMessageContainer"
  else if (newMessages === 0 && props.variant === "ads")
    classname = "notifMessageContainer"

  return (
    <>
      {
        show && (
          <>
            <div className={classname}>
              <Badge className={"notifMessagePin"} badgeContent={newMessages} color={"primary"}>
                <MailOutlineRoundedIcon onClick={handleClick} className={"notifMessageIcon"}/>
              </Badge>
            </div>

            <Menu
              className={"notifMessageMenu"}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {
                props.newChat.map(table => (
                  <StyledMenuItem key={table.table} onClick={() => handleClose(table.table)} className={"notifMessageItem"}>
                    <ListItemIcon>
                      <MailOutlineRoundedIcon fontSize="small"/>
                    </ListItemIcon>

                    <ListItemText>{table.newMessages === 1 ? i18next.t('chat.newChatFrom') : i18next.t('chat.messageFrom')} {table.table}</ListItemText>
                  </StyledMenuItem>
                ))
              }
            </Menu>
          </>
        )
      }

    </>
  )
}

const mapStateToProps = (state) => ({
  newChat: state.session.newChat,
  refreshChat: state.session.refreshChat,
  launchAd: state.session.launchAd
});

export default connect(mapStateToProps)(Chat)
