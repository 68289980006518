import React from "react";
import "./css/bottleSize.css"
import Link from "@material-ui/core/Link";
import {KeyboardArrowDown} from "@material-ui/icons";
import {connect} from "react-redux";
import "./css/choiceSofts.css"
import Grid from "@material-ui/core/Grid";
import {AssetsLink, BackEndLink, CART_ANIMATION, loggedParams} from "../../constants/global";
import {
  setBackDrop,
  setChoice,
  setSelectedSoft,
  setShowError,
  setShowSoftGlass,
  setShowValidateModal
} from "../../actions/menu";
import {Slide, Zoom} from "@material-ui/core";
import i18next from "i18next";
import {addNotValidated, setAnimateCart} from "../../actions/cart";
import axios from "axios";
import {setStateContainer} from "../../actions/session";

const ChoiceSofts = (props) => {

  const [booleanTransition, setBooleanTransition] = React.useState([]);
  const [activate, setActivate] = React.useState(true);
  const [showButton, setShowButton] = React.useState(true);

  let counter = 0;
  let counterChoice = 0;
  /*
  let again = true ;
  const [yo, setYo] = React.useState("y")
   */

  const {
    soft,
    selectedSoft,
    addingChoice
  } = props;

  const addCartChoiceSoft = () => {
    const idArticles = props.selectedSoft.map(s => s.id_article).join(",");
    setBackDrop(true);
    setAnimateCart(CART_ANIMATION.LOADING);
    const params = loggedParams.valueOf();
    params.set("id_article", `${props.idArticleSelected},${idArticles}`);
    axios.post(`${BackEndLink}/cart/add`, params).then(result => {
      setShowSoftGlass(false);
      params.delete("id_article");
      setBackDrop(false);
      if (result.data.error === 0) {
        setTimeout(() => {
          setAnimateCart(CART_ANIMATION.SUCCESS);
          setShowValidateModal(true);
          setShowButton(true)
        }, 800)
        setActivate(true);
        addNotValidated();
        setChoice(false);
      } else {
        setStateContainer("waiting");
      }
    })
  };

  const addCartMenuChoiceSoft = () => {

    setBackDrop(true);
    setAnimateCart(CART_ANIMATION.LOADING);
    const params = loggedParams.valueOf();

    const idArticles = props.selectedSoft.map(s => s.id_article).join(",");
    setShowSoftGlass(false);

    params.set("id_article", `${addingChoice.idArticleChoice},${idArticles}`);
    params.set("id_menu_choice", addingChoice.sizeBottle.id_menu_choice);
    params.set("id_article_choice", addingChoice.sizeBottle.id_article);

    axios.post(`${BackEndLink}/cart/addChoice`, params).then(result => {
      setBackDrop(false);
      params.delete("id_article");
      params.delete("id_menu_choice");
      params.delete("id_article_choice");
      if (result.data.error === 0) {
        setTimeout(() => {
          setAnimateCart(CART_ANIMATION.SUCCESS);
          setShowValidateModal(true);
          setActivate(true);
          setShowButton(true)
        }, 800)
        addNotValidated();
        setChoice(false);
      } else {
        setStateContainer("waiting");
      }
    })
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!props.showSoftGlass) {
        setSelectedSoft([])
      }
    }, 1000)
    if (props.showSoftGlass) {
      setShowButton(true);
    }
  }, [props.showSoftGlass])

  const addCartSoftComponent = () => {
    setActivate(false);
    setShowButton(false);
    if (props.choice) {
      addCartMenuChoiceSoft()
    } else {
      addCartChoiceSoft()
    }
  }

  const handleClose = () => {
    setShowSoftGlass(false);
  }

  const addSoft = (newSoft) => {
    if (newSoft.state_article === "1") {
      return;
    }

    if (selectedSoft.length >= 1) {
      return;
    }
    const newSelectedSoft = [...selectedSoft];
    newSelectedSoft.push(newSoft);
    setSelectedSoft(newSelectedSoft);
  };

  const removeSoft = (softToRemove) => {
    const newSelectedSoft = [...selectedSoft];
    let deleted = false;
    newSelectedSoft.forEach((choice, idx) => {
      if (softToRemove.id_article === choice.id_article && !deleted) {
        newSelectedSoft.splice(idx, 1);
        deleted = true;
      }
    })
    setSelectedSoft(newSelectedSoft);
  }

  return (
    <>
      {
        props.showSoftGlass &&
        <Link underline={"none"} onClick={handleClose}>
          <div className={"emptyZone"}/>
        </Link>
      }

      <div className={props.showSoftGlass ? "choiceSoftContainer choiceSoftContainerShow" : "choiceSoftContainer"}>

        <div className={"bottleSizeLess"}>
          <KeyboardArrowDown/>
        </div>

        <div className={"choiceSoftTitle"} style={{
          color: props.colors.fontAndCategoryColor
        }}>
          {
            selectedSoft.length === 0 ? `${i18next.t('choiceSoft.someSelected')} ${1 - selectedSoft.length} ${i18next.t('choiceSoft.someSelected_2_sing')}` : i18next.t('choiceSoft.fullSelected')
          }
        </div>

        {
          activate && (
            <Zoom in={selectedSoft.length > 0}>
              <div className={"choiceSoftAction"}>
                <Link underline={"none"} onClick={() => {
                  if (activate && selectedSoft.length === 1) {
                    addCartSoftComponent()
                  } else {
                    setShowError(`${i18next.t('choiceSoft.someSelected')} ${1 - selectedSoft.length} ${1 - selectedSoft.length === 1 ? i18next.t('choiceSoft.someSelected_2_sing') : i18next.t('choiceSoft.someSelected_2')}`)
                  }
                }}>
                  <div className={selectedSoft.length === 1 ? "yellowButtonFull" : "yellowButtonDisabled"} style={{
                    color: selectedSoft.length === 1 ? props.colors.fontAndCategoryColor : "",
                    backgroundColor: selectedSoft.length === 1 ? props.colors.buttonOrderColor : "",
                  }}>
                    {i18next.t("cart.add")}
                  </div>
                </Link>
              </div>
            </Zoom>
          )
        }

        <Grid container className={"choiceSoftContainerSofts"}
              direction="column"
              alignItems={"center"} justify={"center"}
        >
          {
            Object.keys(soft).map((choice) => {

              counter += 1;

              if (Object.keys(soft).length === 1) {
                return (
                  <Link key={`choice_${soft[choice].name_article}`} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4} key={soft[choice].name_article}
                          className={"choiceSoftSizeChoiceContainerBig choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>

                )
              }
              /*---------------------------------------*/
              if (Object.keys(soft).length === 4) {
                return (
                  <Link key={soft[choice].name_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 4
                            ? "choiceSoftSizeChoiceContainerBig choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>

                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 5) {
                return (
                  <Link key={soft[choice].name_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 2 || counter === 4 || counter === 5
                            ? "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerBig choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>

                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 6) {
                return (
                  <Link key={soft[choice].name_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={"choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 7) {
                return (
                  <Link key={soft[choice].name_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 2 || counter === 6 || counter === 7
                            ? "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 8) {
                return (
                  <Link key={soft[choice].name_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={counter === 1 || counter === 2 || counter === 3 || counter === 6 || counter === 7 || counter === 8
                            ? "choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"
                            : "choiceSoftSizeChoiceContainerMedium choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }
              /*---------------------------------------*/
              else if (Object.keys(soft).length === 9) {
                return (
                  <Link key={soft[choice].name_article} onClick={() => {
                    addSoft(soft[choice]);
                  }}>
                    <Grid zeroMinWidth item xs={4}
                          className={"choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                    >
                      <div
                        className={soft[choice].state_article === "0" ? "choiceSoftSizeChoice" : "choiceSoftSizeChoice choiceSoftSizeChoiceOS"}>
                        <div className={"choicePricePlus"}>
                          <div className={"choicePricePlusText"}>+{soft[choice].price_article}{props.currency}</div>
                        </div>
                        {
                          soft[choice].desc_article.length > 0 && (
                            <div className={"choiceDescription"}>
                              {soft[choice].desc_article}
                            </div>
                          )
                        }
                        <img alt="product" className={"choiceSoftImage"}
                             src={`${AssetsLink}${soft[choice].article_img}`}/>
                      </div>
                    </Grid>
                  </Link>
                )
              }

              return ""
            })
          }
        </Grid>

        <Grid container alignContent={"center"} alignItems={"center"} justify={"center"}
              className={"choiceSoftSelectedSofts"}>
          {
            Object.keys(selectedSoft).map((choice) => {

              selectedSoft[choice].uniqueID = counterChoice += 1
              return (
                <>
                  <Slide key={choice}
                         direction={(booleanTransition[selectedSoft[choice].uniqueID] === undefined) ? "left" : "right"}
                         in={(booleanTransition[selectedSoft[choice].uniqueID] === undefined)}>
                    <Grid className={"choiceSoftSizeChoiceSelectedContainer"} item xs={4} key={selectedSoft[choice]}>
                      <Link onClick={() => {
                        const tempBooleanArray = [...booleanTransition];
                        tempBooleanArray[selectedSoft[choice].uniqueID] = true;
                        setBooleanTransition(tempBooleanArray);
                        setTimeout(() => {
                          removeSoft(selectedSoft[choice]);
                          setBooleanTransition([])
                        }, 100)
                      }}>
                        <Grid zeroMinWidth item xs={4}
                              className={"choiceSoftSizeChoiceContainerSmall choiceSoftSizeChoiceContainer"}
                        >
                          <div className={"choiceSoftSizeChoice choiceSoftSizeChoiceSelected"}>
                            <img alt="product" className={"choiceSoftImage"}
                                 src={`${AssetsLink}${selectedSoft[choice].article_img}`}/>
                            <div className={"choicePricePlus"}>
                              <div
                                className={"choicePricePlusText"}>+{selectedSoft[choice].price_article}{props.currency}</div>
                            </div>

                          </div>
                        </Grid>
                      </Link>
                    </Grid>
                  </Slide>
                </>
              )
            }
            )
          }
        </Grid>

      </div>

    </>
  )
}

const mapStateToProps = (state) => ({
  showSoftGlass: state.menu.showSoftGlass,
  selectedSoft: state.menu.selectedSoft,
  choice: state.menu.choice,
  addingChoice: state.menu.addingChoice,
  idArticleSelected: state.menu.idArticleSelected,
  currency: state.session.currency,
  colors: state.session.colors,
});

export default connect(mapStateToProps)(ChoiceSofts)
