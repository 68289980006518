import React from "react";
import i18next from "i18next";

const PlugBattery = (props) => (
  <div style={{
    position: "fixed",
    top: "0",
    zIndex: 1000000,
    background: "red",
    height: "100vh",
    width: "100vw",
  }} onClick={() => props.setShowCharge(false)}>
    <div style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "white",
      fontSize: "2.6em",
      width: "100vw",
      padding: "1em",
      borderRadius: "2px"
    }}>
      {i18next.t('global.plug')}
      <br/>
      <img
        alt={"Hard"}
        src={`/assets/img/menu/plug.gif`}
        style={{
          marginTop: "4.8vh",
          height: "auto",
          width: "46%"
        }}
      />
    </div>
  </div>
)

export default PlugBattery;
