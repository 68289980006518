import React from 'react';
import "./css/marks.css"
import {connect} from "react-redux";
import {setErrorRefused} from "../../../actions/menu";
import {Link} from "@material-ui/core";
import i18next from "i18next";

const ErrorMarkRefused = (props) => {

  return (
    <>
      {
        props.errorRefused != null && (
          <>
            <Link underline={"none"} onClick={() => setErrorRefused(null)}>
              <div className={"blackBGAlert"}/>
              <div className={"markText"}>
                {i18next.t("order.refused")} {props.errorRefused}
              </div>
              <div className="markContainer swal2-icon swal2-error swal2-animate-error-icon" style={{display: "flex"}}>
                <span className="swal2-x-mark">
                <span className="swal2-x-mark-line-left"/>
                <span className="swal2-x-mark-line-right"/>
                </span>
              </div>
            </Link>
          </>
        )
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  errorRefused: state.menu.errorRefused
});

export default connect(mapStateToProps)(ErrorMarkRefused)

