import React from 'react';
import "./css/marks.css"
import {connect} from "react-redux";
import {setShowError} from "../../../actions/menu";
import {Link} from "@material-ui/core";

const ErrorMark = (props) => {

  React.useEffect(() => {
    if (props.error) {
      setTimeout(() => {
        if (props.error != null) {
          setShowError(null);
        }
      }, 5000)
    }
  }, [props.error])

  return (
    <>
      {
        props.error != null && (
          <div className={"foreground"}>
            <Link underline={"none"} onClick={() => setShowError(null)}>
              <div className={"blackBGAlert"}/>
              <div className={"markText"}>
                {props.error}
              </div>
              <div className="markContainer swal2-icon swal2-error swal2-animate-error-icon" style={{display: "flex"}}>
                <span className="swal2-x-mark">
                <span className="swal2-x-mark-line-left"/>
                <span className="swal2-x-mark-line-right"/>
                </span>
              </div>
            </Link>
          </div>
        )
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  error: state.menu.error
});

export default connect(mapStateToProps)(ErrorMark)

