import ActionTypes from '../constants/actionTypes'

const initialState = {
  showBill: false
} ;

export default function billing(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_SHOW_BILL:
      return {
        ...state,
        showBill: action.value
      } ;
    default:
      return state
  }
}
