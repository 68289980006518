import React, {useRef} from "react" ;
import {connect} from "react-redux";
import "./css/promo.css"
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import i18next from "i18next";
import {AssetsLink, BackEndLink, loggedParams, MENU_CHOICE} from "../../constants/global";
import {setMenu, setPromos, setSos} from "../../actions/menu";
import {setShowLegalMention, setStateContainer} from "../../actions/session";
import axios from "axios";

const Promo = (props) => {

  const [promo, setPromo] = React.useState(null)
  const [show, setShow] = React.useState(false)
  const [leftTime, setLeftTime] = React.useState(0)
  const [endDate, setEndDate] = React.useState(null)
  const [initialLeftTime, setInitialLeftTime] = React.useState(0)
  let reverse = true;
  const animation = useRef(null);

  const animateFunction = () => {

    let timeout = 250 ;

    if(!reverse)
      timeout = 1250

    const timeoutSave = setTimeout(() => {

      if (animation.current === null) {
        clearTimeout(timeoutSave);
        return;
      } else if (reverse) {
        animation.current.style.backgroundColor = "#000000";
        animation.current.style.color = "white";
      } else {
        animation.current.style.backgroundColor = "#ffffff";
        animation.current.style.color = "transparent";
      }

      reverse = !reverse;

      animateFunction();

    }, timeout)

  }

  const getPromos = () => {

    if(props.route === 4) {
        return ;
    }

    setPromos(null)
    props.setCurrentMenu(0)
    setShow(false)

    axios.post(`${BackEndLink}/features/get_promos`, loggedParams).then(result => {
      if (result.data.error !== "wrong_token")
        setPromos(result.data);
    });
  }

  React.useEffect(() => {
    if (props.promos && props.promos.length > 0) {
      const promos = props.promos.reverse();
      setPromo(promos[0])
    }
  }, [props.promos])

  React.useEffect(() => {
    getPromos()
  }, [props.refreshPromos])

  React.useEffect(() => {
    if (promo) {
      const nowDate = new Date()
      const dateStart = new Date(promo.date_promo)
      const dateEnd = new Date(promo.end_promo)
      const leftTime = parseInt(dateEnd.getTime() / 1000 - nowDate.getTime() / 1000, 10);

      if (leftTime >= 300)
        setLeftTime(300);
      else
        setLeftTime(leftTime);

      setEndDate(dateEnd)
      setInitialLeftTime(parseInt(dateEnd.getTime() / 1000 - dateStart.getTime() / 1000, 10));
      setShow(true)
      setSos(false)
      setShowLegalMention(false)
      animateFunction()
    }
  }, [promo])


  return (
    (promo && show) ? (
      <div className={"promo"} style={{

      }} ref={animation} onClick={() => {
        props.changeMenu(1, promo.name_category_article, promo.id_article)
        setShow(false)
      }}>
        <div className={"promoContainer"}>
          <CountdownCircleTimer
            isPlaying
            duration={leftTime}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[60, 40, 20, 0]}
            size={400}
            strokeWidth={20}
            onComplete={() => setShow(false)}
          >
            {({remainingTime}) => <div className={"tc"}>
              <h2 style={{fontSize: "35px", fontWeight: "bold"}}>{i18next.t('promo.title')}</h2>
              <h1 style={{fontSize: "27px"}}>{promo.name_article}</h1>
              <h1 style={{fontSize: "27px"}}>{promo.name_bottle && promo.name_bottle}{promo.size_bottle && ` (${promo.size_bottle})`}</h1>
              <h1 className={"promoPercentage"}>-{promo.promo}%</h1>
            </div>}
          </CountdownCircleTimer>
          {
            endDate && (
              <div className={"tc"}><h1>
                {i18next.t('promo.until')} {`${endDate.getHours()}:${endDate.getMinutes()}`}</h1></div>
            )
          }
        </div>
        {
          <div className={"promoImageContainer"} style={{
            backgroundImage: `url("${AssetsLink}${promo.article_img}")`
          }}/>
        }
      </div>
    ) : <></>

  )
}

const mapStateToProps = (state) => ({
  launchAd: state.session.launchAd,
  promos: state.menu.promos,
  refreshPromos: state.menu.refreshPromos,
  stateContainer: state.session.stateContainer,
  route: state.route.route,
  order: state.session.order
});


export default connect(mapStateToProps)(Promo)
