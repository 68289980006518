import DialogTitle from "@material-ui/core/DialogTitle";
import i18next from "i18next";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {connect} from "react-redux";
import "./css/refill.css"
import {setShowRefill} from "../../actions/session";
import Grid from "@material-ui/core/Grid";
import CountUp from 'react-countup';
import {MENU_CHOICE} from "../../constants/global";

const Refill = (props) => {

  const [progress, setProgress] = React.useState(0);
  const refillProgress = React.createRef();
  const refillPercents = React.createRef();
  const refillImgNotHappy = React.createRef();
  const refillImgHappy = React.createRef();

  const reload = () => {
    setProgress(100)
    refillProgress.current.style.width = "99%";
    refillProgress.current.style.backgroundColor = "#65CA00";
    refillPercents.current.style.color = "white";
    refillImgNotHappy.current.style.opacity = 0;
    refillImgHappy.current.style.opacity = 1;

    props.changeMenu(1, MENU_CHOICE.VODKA)

    setTimeout(() => {
      setShowRefill(false);
    }, 2500)

    setTimeout(() => {
      setProgress(0);
    }, 2700)
  }

  React.useEffect(() => {
    if (props.showRefill && (props.order === null || props.order === undefined) && !props.onlyAds) {
      setShowRefill(false);
    } else  {
      setTimeout(() => {
        setShowRefill(false);
      }, 60000)
    }
  }, [props.showRefill])


  return (
    <>
      <Dialog fullWidth={true} open={props.showRefill && props.order !== null && props.order !== undefined && !props.onlyAds}
              onClose={() => {
                setShowRefill(false)
              }} aria-labelledby="simple-dialog-title"
              PaperProps={{
                style: {
                  background: "rgba(255, 255, 255, 0.9)"
                }
              }}
      >
        <DialogTitle style={{textAlign: "center"}} id="simple-dialog-title"><span
          className={"refillTitle"}>{i18next.t("refill.title")}</span></DialogTitle>

        <Grid container justify={"center"}>
          <Grid item xs={4}>
            <div className={"refillBox"}>
              <div ref={refillProgress} className={"refillProgress"}/>
              <div ref={refillPercents} className={"refillPercents"}>
                {
                  progress > 0 ? (
                    <CountUp
                      start={0}
                      end={100}
                      duration={2}
                      separator=" "
                      suffix=" %"
                    />

                  ) : "0%"
                }
              </div>
            </div>
          </Grid>
        </Grid>

        <div style={{height: "25vh"}}/>

        <div ref={refillImgNotHappy} className={"refillImg"} style={{
          backgroundImage: `url("/assets/img/menu/not_happy.png")`
        }}/>

        <div ref={refillImgHappy} className={"refillImg"} style={{
          opacity: 0,
          backgroundImage: `url("/assets/img/menu/happy.png")`
        }}/>

        <div onClick={reload} className={"yellowButtonFull"}>{i18next.t("refill.button")}</div>

      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  refill: state.session.refill,
  showRefill: state.session.showRefill,
  order: state.session.order,
  onlyAds: state.session.onlyAds,
});

export default connect(mapStateToProps)(Refill)

