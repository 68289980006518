import React, {useEffect} from 'react'
import Link from "@material-ui/core/Link";
import './css/alertSecurity.css';
import i18next from "i18next";

const AlertFire = (props) => {

  const switchText = React.useRef(0)
  const timeout = 2500

  const languageText = {
    fire: i18next.t('alert.fire'),
    fireNB : i18next.t('alert.fireNB')
  } ;

  const englishText = {
    fire: "Fire",
    fireNB : "Evacuate the area"
  }

  const [text, setText] = React.useState({...languageText})

  const changeLanguage = () => {
    if (props.alertFire) {
      if(switchText.current === 0) {
        setText({...englishText})
        switchText.current = 1
      } else  {
        setText({...languageText})
        switchText.current = 0
      }

      setTimeout(() => {
        changeLanguage()
      }, timeout)
    }
  }

  useEffect(() => {
    if (props.alertFire)
      setTimeout(() => {
        props.setAlertFire(false)
      }, 60000 * 10)

    setTimeout(() => {
      changeLanguage()
    }, timeout)
  }, [])

  return (
    <Link onClick={() => {}}>
      <div className={"alertSecurity"} style={{background: "red"}}>
        <div className={"center content"} style={{color: "white"}}>
          {text.fire} <br />
          <span className={"name"}>{text.fireNB}</span>
        </div>
      </div>
    </Link>
  );

};

export default AlertFire;
