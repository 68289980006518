import ActionTypes from '../constants/actionTypes'

const initialState = {
  notValidated: 0,
  animate: "",
  showCart: false
} ;

export default function cart(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_NEW_NOT_VALIDATED:
      return {
        ...state,
        notValidated: action.value
      } ;
    case ActionTypes.ADD_ONE_NOT_VALIDATED:
      return {
        ...state,
        notValidated: state.notValidated + 1
      } ;
    case ActionTypes.SET_ANIMATION_TO_DO:
      return {
        ...state,
        animate: action.value
      } ;
    case ActionTypes.SET_SHOW_CART:
      return {
        ...state,
        showCart: action.value
      } ;
    default:
      return state
  }
}
