import React from 'react'
import {Route, Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import styled from '@emotion/styled'
import Login from "../components/Login/Login";
import {connect} from "react-redux";
import i18next from "i18next";
import axios from "axios";
import {adTimeout, appVersion, BackEndLink, loggedParams} from "../constants/global";
import Pause from '../components/Features/Pause'
import {
  refreshMessage, setBirthday,
  setChatOff,
  setColors,
  setCurrency, setEvent, setIgnorePlug,
  setLaunchAd,
  setMinPrice,
  setNameEstablishment,
  setNewLogo, setOnlyAds,
  setOrder,
  setPartners,
  setPremiumPartner,
  setShowLegalMention,
  setShowRefill, setStartNewYear,
  setStateContainer,
  setToken,
  setWelcome
} from "../actions/session";
import {setNewNotValidated, setShowCart} from "../actions/cart";

import MenuContainer from "../components/Menu/Container";
import {
  setErrorRefused,
  setMenu, setPromos, setRefreshPromos,
  setShowError,
  setShowSoft,
  setShowSoftGlass,
  setShowSuccess,
  setShowValidateModal,
  setSizeChoice
} from "../actions/menu";
import {setNewRoute} from "../actions/route";
import Birthday from "../components/Features/Birthday";
import * as html2canvas from 'html2canvas';
import {useIdleTimer} from 'react-idle-timer'

import en from "../locales/en/translation";
import fr from "../locales/fr/translation";
import it from "../locales/it/translation";
import ru from "../locales/ru/translation";
import ae from "../locales/ae/translation";
import cn from "../locales/cn/translation";
import de from "../locales/de/translation";
import es from "../locales/es/translation";
import hin from "../locales/in/translation";
import jp from "../locales/jp/translation";
import pt from "../locales/pt/translation";
import {setShowBill} from "../actions/billing";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LightedOff from "../components/Features/LightedOff";
import AlertSecurity from "../components/Features/AlertSecurity";
import Ad from "../components/Features/Ad";
import Event from "../components/Features/Event";
import NewYear from "../components/Features/NewYear";
import {w3cwebsocket as W3CWebSocket} from "websocket";
import {setLanguage} from "../actions/language";
import AlertFire from "../components/Features/AlertFire";

export const history = createBrowserHistory();

const Container = styled.div`
  text-align: center;
`;


i18next.init({
  lng: localStorage.getItem("language") === null ? "fr" : localStorage.getItem("language"),
  debug: false,
  fallbackLng: "fr",
  resources: {
    en: {
      translation: en
    },
    fr: {
      translation: fr
    },
    ae: {
      translation: ae
    },
    ru: {
      translation: ru
    },
    de: {
      translation: de
    },
    es: {
      translation: es
    },
    jp: {
      translation: jp
    },
    pt: {
      translation: pt
    },
    it: {
      translation: it
    },
    in: {
      translation: hin
    },
    cn: {
      translation: cn
    },

  }
});

let currency = null;
let notValidated = null;


const Routes = (props) => {

  const [returnCode, setReturnCode] = React.useState(null);
  const [socketState, setSocketState] = React.useState(false);
  const [alertSecurity, setAlertSecurity] = React.useState(false);
  const [alertSecurityColor, setAlertSecurityColor] = React.useState("red");
  const [alertFire, setAlertFire] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const route = React.createRef();
  const order = React.createRef();
  const untilOnlyBarTimestamp = React.createRef();
  const pingInterval = React.createRef();
  const pingResponse = React.createRef(false);
  const countConnections = React.createRef();
  const socket = React.useRef(null);

  const idleTimer = useIdleTimer({
    timeout: adTimeout,
    onIdle: event => {
      if (!props.launchAd)
        setLaunchAd(true)
    },
    onActive: () => {
      setLaunchAd(false)
    },
    onAction: () => {
      setLaunchAd(false)
    },
    debounce: 500
  })

  const launchPromo = (promos) => {
    setPromos(promos)
  }

  const checkToken = () => {

    if (!props.token) return;

    const params = new URLSearchParams();
    params.append('token', localStorage.getItem("session_tablet_customer"));
    params.append('id_table', localStorage.getItem("id_table"));
    params.append('id_tablet_customer', localStorage.getItem("id_tablet_customer"));
    params.append('id_establishment', localStorage.getItem("id_establishment"));

    axios.post(`${BackEndLink}/Login/checkToken`, params).then(response => {

      const data = response.data;

      setNameEstablishment(data.nameEstablishment)
      setReturnCode(data.returnCode)

      if(data.defaultLanguage) {
        localStorage.setItem("defaultLanguage",data.defaultLanguage) ;
      }

      if (data.returnCode === -1 && data.earlyOpenTimeout > 0) {

        if (untilOnlyBarTimestamp.current) {
          clearTimeout(untilOnlyBarTimestamp.current)
        }

        untilOnlyBarTimestamp.current = setTimeout(() => {
          checkToken();
        }, data.earlyOpenTimeout)
      }

      if (data.returnCode === 2 || data.returnCode === 3) {
        setToken(undefined);
        setNewRoute(-1)

        localStorage.removeItem("id_tablet_customer")
        localStorage.removeItem("id_establishment")
        localStorage.removeItem("name_establishment")
        localStorage.removeItem("id_table")
        localStorage.removeItem("session_tablet_customer")

        if (window.Android) {
          window.Android.removeIdentity();
        }

        return 0;
      } else if (parseInt(data.updateMenu, 2) === 1 || !props.menu) {
        axios.post(`${BackEndLink}/menu/get_menu`, params).then(result => {
          if (result.data.error !== "wrong_token")
            setMenu(result.data);

          if (data.order) {
            setStateContainer("menu")
          }
        });
      }

      if(data.ignorePlug) {
        setIgnorePlug(data.ignorePlug)
      }

      if (data.order) {
        if (!order.current) {
          order.current = data.order;
          setOrder(data.order)
          setMinPrice(data.order.min_order_table);
        } else if (order.current.id_order !== data.order.id_order) {
          setOrder(data.order)
          setMinPrice(data.order.min_order_table);
        }

        if (data.promos && data.promos.length > 0) {
          launchPromo(data.promos);
        }

      } else {
        setStateContainer("waiting")
        setOrder(undefined);
        setShowBill(false)
        setShowLegalMention(false)
        setShowRefill(false)
        setShowCart(false)
        setShowValidateModal(false)
        setShowSoft(false)
        setShowSoftGlass(false)
        setShowError(null)
        setShowSuccess(null)
        setSizeChoice({})
        setNewRoute(0);
      }

      if (route.current !== data.returnCode) {
        route.current = data.returnCode;
        setNewRoute(data.returnCode);
      }

      if (data.colors)
        setColors(data.colors)

      setChatOff(data.chatOff)

      if (currency !== data.currency) {
        setCurrency(data.currency);
        currency = data.currency
      }

      if (data.notValidated !== notValidated) {
        notValidated = data.notValidated;
        setNewNotValidated(data.notValidated);
      }

      setNewLogo(data.logoEstablishment);

      if (data.welcome) {
        setWelcome(data.welcome);
      } else {
        setWelcome("");
      }

      if (data.launchRefill) {
        setShowRefill(true);
      }

      if (data.refused) {
        setErrorRefused(data.refused);
        setShowCart(false);
        setShowCart(true)
      }

      if (data.partners) {
        setPartners(data.partners)
      }

      if (data.premiumPartner) {
        setPremiumPartner(data.premiumPartner)
      }

      if (data.messages) {
        props.newChat.splice(0, props.newChat.length)
        data.messages.forEach(message => props.newChat.push(message))
        refreshMessage({});
      }

      if (data.returnCode === -2) {
        setLaunchAd(true);
        setOnlyAds(true)
        setOrder("ads")
      }

      if (data.returnCode === -1) {
        setLaunchAd(true);
        setOnlyAds(true)
        setOrder("ads")
      }

      if (data.orderVersion !== appVersion && parseInt(data.checkVersion, 0) === 1) {
        if (window.Android) {
          window.Android.clearCacheWS()
          window.Android.clearCacheWebviewWS()
        }
      }

      setReady(true);
    })
  };

  let refreshPromo = 0;

  const messageAction = (newMessage, from = null) => {
    if (newMessage.order) {

      if (newMessage.order === "newPromo") {
        refreshPromo += 1;
        setRefreshPromos(refreshPromo)
      } else if (newMessage.order === "alertSecurity") {
        if(newMessage.color) {
          setAlertSecurityColor(newMessage.color) ;
        } else  {
          setAlertSecurityColor("red")
        }
        setAlertSecurity(true)
      } else if (newMessage.order === "checkToken") {
        window.location.reload(false);
      } else if (newMessage.order === "newYear") {
        setStartNewYear(true)
      }  else if (newMessage.order === "alertFire") {
        setAlertFire(true)
      } else if (newMessage.order === "refill") {
        setShowRefill(true);
      } else if (newMessage.order === "pause") {
        route.current = 1;
        setNewRoute(1);
      } else if (newMessage.order === "unpause") {
        route.current = 0;
        setNewRoute(0);
      } else if (newMessage.order.reason) {
        setErrorRefused(newMessage.order.reason);
        setShowCart(false);
        setShowCart(true)
      } else if (newMessage.order.event) {
        setEvent(newMessage.order.event)
        setTimeout(() => {
          setEvent({
            event: null,
            name: null
          });
        }, 90000)
      } else if (newMessage.order.currency) {
        setCurrency(newMessage.order.currency);
      } else if (newMessage.order === "closed") {
        idleTimer.reset()
        setOrder(null);
        order.current = null;

        if(localStorage.getItem("defaultLanguage") !== null) {
          setLanguage(localStorage.getItem("defaultLanguage"), false)
        }

        checkToken()
      } else if (newMessage.order === "validated") {
        setShowCart(false);
        setShowCart(true)
      } else if (newMessage.order.minPrice) {
        setMinPrice(parseFloat(newMessage.order.minPrice));
      } else if (newMessage.order.chatOff) {
        setChatOff(parseInt(newMessage.order.chatOff, 0));
      } else if (newMessage.order.colors) {
        setColors(newMessage.order.colors);
      }
    } else if (newMessage.welcome) {
      setWelcome(newMessage.welcome)
    } else if (newMessage.sos) {
      if (newMessage.sos === "validated")
        setShowSuccess(i18next.t("global.sosValidated"))
    } else if (newMessage.message) {
      if (newMessage.message.to !== "public") {
        let found = false;
        props.newChat.forEach(chat => {
          if (chat.table === newMessage.message.from) {
            found = true;
            chat.newMessages = 1;
          }
        })
        if (!found) {
          const newArray = []
          newArray.table = newMessage.message.from
          newArray.newMessages = 1
          props.newChat.push(newArray);
        }
      }
      refreshMessage(newMessage.message);
    } else if (newMessage.birthday) {
      setBirthday(newMessage.birthday);
      setTimeout(() => {
        setBirthday(null);
      }, 90000)
    } else if (newMessage.restart) {
      if (window.Android)
        window.Android.restartWS()
    } else if (newMessage.clearWvCache) {
      if (window.Android)
        window.Android.clearCacheWebviewWS()
    } else if (newMessage.clearAllCache) {
      if (window.Android)
        window.Android.clearCacheWS()
    } else if (newMessage.clearAllDataApp) {
      if (window.Android)
        window.Android.clearAppDataWS()
    } else if (newMessage.showLogs) {
      if (window.Android)
        window.Android.showLogsWS()
    } else if (newMessage.setType) {
      if (window.Android)
        window.Android.setTypeWS(newMessage.setType)
    } else if (newMessage.removeTempTopic) {
      if (window.Android)
        window.Android.removeTempTopicWS()
    } else if (newMessage.screen && !from.match(/id_establishment/)) {
      html2canvas(document.body, {useCORS: true}).then((canvas) => {
        canvas.toBlob(data => {
          const formData = new FormData();
          formData.append("file", data);
          formData.append("token", localStorage.getItem("session_tablet_customer"));
          formData.append("id_table", localStorage.getItem("id_table"));
          formData.append("id_tablet_customer", localStorage.getItem("id_tablet_customer"));
          formData.append("id_establishment", localStorage.getItem("id_establishment"));
          axios.post(`${BackEndLink}/features/screenshot`, formData);
        })
      });
    } else if (newMessage.brightness) {
      if (window.Android)
        window.Android.setBrightnessWS(parseFloat(newMessage.brightness));
    } else if (newMessage.menu) {
      if (newMessage.menu === "updated") {
        axios.post(`${BackEndLink}/menu/get_menu`, loggedParams).then(result => {
          if (result.data.error !== "wrong_token")
            setMenu(result.data);
        });
      }
    }
  }

  const onSocketMessage = (from, newMessage) => {

    if (newMessage.id && !from.match(/id_establishment/)) {

      const id = newMessage.id;
      const params = new URLSearchParams();
      params.append('token', localStorage.getItem("session_tablet_customer"));
      params.append('id_table', localStorage.getItem("id_table"));
      params.append('id_tablet_customer', localStorage.getItem("id_tablet_customer"));
      params.append('id_establishment', localStorage.getItem("id_establishment"));
      params.append('id', id);
      axios.post(`${BackEndLink}/Features/valid_ack`, params).then(response => {
      })
    }
    messageAction(newMessage, from);
  }

  React.useEffect(() => {
    if (props.token === undefined || !props.token) {
      setReady(true);
    }
  }, [props.token])

  React.useEffect(() => {
    checkToken();

    const now = new Date();
    const nowTimestamp = now.getTime();
    const newYear = new Date(`${now.getFullYear()}/12/31 23:59:00`).getTime();
    const dif = newYear - nowTimestamp;

    // Where 2147483647 is max input for 32bits timeout
    if (dif < 2147483647) {
      setTimeout(() => {
        if (newYear - nowTimestamp < 0)
          return;
        setStartNewYear(true)
      }, dif)
    }

    if (window.Android && localStorage.getItem("id_establishment")) {
      window.Android.changeIdentity(localStorage.getItem("id_establishment"), localStorage.getItem("id_tablet_customer"));
    }

    const socketFunction = () => {

      if (countConnections.current > 0) {
        console.log("Socket already connected")
        return;
      }

      const endpoint = `wss://socketv2.evenights.com:443?token=${props.token}&id_establishment=${localStorage.getItem("id_establishment")}`

      const client = new W3CWebSocket(endpoint);

      const ping = () => {
        pingInterval.current = setTimeout(() => {
          pingResponse.current = false;
          client.send(JSON.stringify({ping: 1}))
          setTimeout(() => {
            if (pingResponse.current) {
              ping()
            } else {
              console.log("Pong not received")
              client.close();
              setTimeout(() => {
                socketFunction()
              }, 2500);
            }
          }, 5000)
        }, 200000)
      }

      client.onopen = () => {

        if ((countConnections.current && countConnections.current === 0) || !countConnections.current) {
          console.log("First socket connection")
          countConnections.current += 1;
          setSocketState(true);
          ping()
        } else {
          countConnections.current += 1;
        }

        if (countConnections.current > 1) {
          console.log("Detected duplicate socket closing it")
          client.close();
        }
      };

      client.onmessage = (message) => {
        try {
          const messageToSend = JSON.parse(message.data)

          if (messageToSend.ping) {
            if (messageToSend.ping === "pong") {
              pingResponse.current = true;
            }
          } else {
            onSocketMessage(messageToSend.room, messageToSend)
          }
        } catch (e) {
          console.log(e)
        }
      };

      client.onclose = e => {
        if (countConnections.current) {
          countConnections.current -= 1;
        } else {
          countConnections.current = 0;
        }

        if (countConnections.current < 1)
          setSocketState(false);

        client.close();
        setTimeout(() => {
          socketFunction()
        }, 5000);
      }

      client.onerror = err => {
        if (countConnections.current < 1)
          setSocketState(false);

        client.close();

      };

    }

    window.asynchronousMessage = (message) => {
      if (message === "checkToken") {
        checkToken()
        return;
      }
      const newMessage = JSON.parse(message);
      messageAction(newMessage);
    }

    if(props.token !== undefined)
      socketFunction()

  }, []);

  if (returnCode === -1 || returnCode === -2) {
    setOnlyAds(true)
    setOrder("ads")
    setStateContainer("menu")
  } else {
    setOnlyAds(false)
  }

  if (returnCode === -2) {
    return <>
      {alertFire && <AlertFire alertFire={alertFire} setAlertFire={setAlertFire} />}
      {alertSecurity && <AlertSecurity alertSecurityColor={alertSecurityColor} alertSecurity={alertSecurity} setAlertSecurity={setAlertSecurity}/>}
      <Ad variant={"onlyAdsNoMenu"}/>
      <Birthday/>
      <Event/>
      <NewYear/>
    </>
  }

  return (
    <>
      {
        ready ? (
          <Router history={history}>
            <div className={"mainContainer"} style={{background: props.colors.backgroundColor}}>
              <div style={{textAlign: "center"}} id={"screen"}>
                {props.token === undefined ? (<Container><Route path="/" component={Login}/></Container>) :
                  <>
                    {props.route === -1 && <MenuContainer variant={"onlyAds"}/>}
                    {props.route === 0 && <MenuContainer/>}
                    {props.route === 1 && <Pause/>}
                    {props.route === 4 && <MenuContainer variant={"onlyBill"} checkToken={checkToken}/>}
                    {props.route === 5 && <LightedOff/>}
                  </>
                }

              </div>
            </div>
            {!socketState && <div className={"noSocket"}><img alt={"noSocket"} width={20} className={"noSocketImage"} src={"assets/img/menu/disconnected.svg"}/></div>}
            {alertSecurity && <AlertSecurity alertSecurityColor={alertSecurityColor} alertSecurity={alertSecurity} setAlertSecurity={setAlertSecurity}/>}
            <Birthday/>
            <Event/>
            <NewYear/>
            <Backdrop open={props.backdrop} className={"backdrop"}>
              <CircularProgress className={"backdropProgress"} color="inherit"/>
            </Backdrop>
            {alertFire && <AlertFire alertFire={alertFire} setAlertFire={setAlertFire} />}
          </Router>
        ) : (
          <>
            <div className="divLoaderBg"></div>
            <div className="logo"></div>
            <div className="divLoader">
              <svg className="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
                <path ng-attr-d="{{config.pathCmd}}" ng-attr-fill="{{config.color}}" stroke="none"
                      d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)">
                  <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51"
                                    keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
                </path>
              </svg>
            </div>
          </>
        )
      }
    </>
  )
};

const mapStateToProps = (state) => ({
  token: state.session.token,
  language: state.language.language,
  menu: state.menu.menu,
  route: state.route.route,
  order: state.session.order,
  newChat: state.session.newChat,
  notValidated: state.cart.notValidated,
  backdrop: state.menu.backdrop,
  refreshPromos: state.menu.refreshPromos,
  currency: state.session.currency,
  onlyAds: state.session.onlyAds,
  colors: state.session.colors,
  launchAd: state.session.launchAd
});

export default connect(mapStateToProps)(Routes)
