import React from "react";
import {Card, CardActions, Grid, Typography} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Language from "../Login/Language";
import i18next from "i18next";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";

const BackGround = `/assets/img/menu/bg.png`;

const Pause = () => {

  React.useState();

  return (
    <React.Fragment>
      <Grid
        style={{backgroundImage: `url(${BackGround})`, height: "100vh",}}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Card variant="outlined">
            <CardHeader
              style={{textAlign: "left"}}
              action={
                <Language/>
              }
              title={
                <Typography variant="h4" component="p">
                  {i18next.t("break.title")}
                </Typography>
              }
            />
            <CardContent>
              <Divider style={{marginTop: "-1em", marginBottom: "2em", width: "100%"}}/>
              <Grid item xs={12} style={{marginBottom: "1em"}}>
                <Typography variant="h5" component="p">
                  {i18next.t("break.body")}
                </Typography>
              </Grid>
            </CardContent>
            <CardActions/>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
};

export default Pause;
