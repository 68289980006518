import React from "react" ;
import {Grid} from "@material-ui/core";
import Preload from "react-preload" ;
import {AdMediaLink, BackEndLink, loggedParams, nextAdTimeout} from "../../constants/global"
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import axios from "axios";
import {setAdLaunched, setLaunchAd, setShowLegalMention, setShowRefill, setStateContainer} from "../../actions/session";
import {setShowCart} from "../../actions/cart";
import {setShowBill} from "../../actions/billing";
import {setShowSoft, setShowSoftGlass, setSizeChoice, setSos, setSosChoice} from "../../actions/menu";
import Chat from "../Header/Chat";
import Zoom from "@material-ui/core/Zoom";

const Ad = (props) => {

  const [ad, setAd] = React.useState(null);
  const [showAd, setShowAd] = React.useState(false);
  const timeoutRef = React.useRef();
  const dataRef = React.useRef();

  const launchAd = (type) => {

    if (props.stateContainer === "waiting")
      return;

    setSos(false)
    setShowLegalMention(false)

    let link = "/get";

    if (dataRef.current) {
      link = `/get/${dataRef.current}`
    }

    if (props.launchAd) {
      axios.post(`${BackEndLink}/Ad${link}`, loggedParams).then(result => {

        setAd(result.data);

        dataRef.current = result.data.id_advertisement

        if (type === "ad") {
          setStateContainer("ad");
          setShowCart(false);
          setShowLegalMention(false)
          setShowBill(false);
          setSizeChoice({});
          setShowSoft(false);
          setShowSoftGlass(false);
          setShowRefill(false);
          setSos(false);
          setSosChoice(null)
          setShowAd(true);
        }
      })
    }
  };

  const timeoutAd = () => {
    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      launchAd()
      timeoutAd()
    }, nextAdTimeout)
  }

  const closeAd = () => {

    if (props.variant === "onlyAdsNoMenu")
      return;

    setAdLaunched(false)
    setLaunchAd(false);
    setAd(null);
    setShowAd(false);
    dataRef.current = null;
    clearTimeout(timeoutRef.current);
  };

  React.useEffect(() => {
    if (props.launchAd) {
      if (props.stateContainer === "waiting")
        return;
      setStateContainer("menu");
      document.activeElement.blur()
      setAdLaunched(true)
      launchAd();
      timeoutAd();
    } else {
      closeAd()
      clearTimeout(timeoutRef.current)
    }
  }, [props.launchAd])

  React.useEffect(() => {
    if (props.stateContainer === "waiting") {
      closeAd();
    }
  }, [props.stateContainer])

  return (
    <React.Fragment>
      <Zoom in={ad && ad.media_link && ad.media_link.match(/gif|png|jpeg|jpg/).length > 0} unmountOnExit>
        <div className={"bgAd"}>
          {ad && ad.media_link && ad.media_link.match(/gif|png|jpeg|jpg/) && (
            <Preload
              loadingIndicator={<CircularProgress variant="determinate"/>}
              images={[`${AdMediaLink}${ad.media_link}`]}
              autoResolveDelay={3000}
              onSuccess={() => setShowAd(true)}
              onError={() => closeAd()}
            >
              <React.Fragment>
                {showAd &&
                <>
                  <Grid
                    onClick={() => closeAd()}
                    style={{backgroundImage: `url("/assets/img/menu/bg.png")`, height: "100vh",}}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={"ad"}
                  >
                    <img alt="ad" style={{height: "100vh", width: "100vw"}} src={[`${AdMediaLink}${ad.media_link}`]}/>
                  </Grid>
                  <Chat variant={"ads"}/>
                </>
                }
              </React.Fragment>
            </Preload>
          )}
        </div>
      </Zoom>


    </React.Fragment>

  )
}

const mapStateToProps = (state) => ({
  stateContainer: state.session.stateContainer,
  launchAd: state.session.launchAd
});


export default connect(mapStateToProps)(Ad)
