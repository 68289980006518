import React from "react"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import i18next from "i18next";
import {connect} from "react-redux";
import {setShowLegalMention} from "../../actions/session";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {appVersion} from "../../constants/global";

const numberArray = [...Array(100).keys()];
const debug = false;

const LegalMention = (props) => {

  return (
    <Dialog maxWidth={"lg"} onClose={() => {
      setShowLegalMention(false);
    }} aria-labelledby="customized-dialog-title" open={props.showLegalMention}>
      <DialogTitle id="customized-dialog-title" onClose={() => {
        setShowLegalMention(false)
      }}>
        {i18next.t('legal_mention.title')}
        <p className={"appVersion"}>{appVersion}</p>

        <div className={"closeCgvContainer"}>
          <IconButton aria-label="close" className={"closeCgv"} onClick={() => {
            setShowLegalMention(false);
          }}>
            <Close/>
          </IconButton>
        </div>

      </DialogTitle>
      <DialogContent dividers style={{textAlign: "justify"}}>
        {
          numberArray.map(i => {

            const normal =
              [1, 2, 8, 9, 10, 11, 12, 15, 17, 19, 21, 22, 23, 24, 27, 28, 30,
                31, 33, 34, 35, 37, 38, 41, 44, 45, 46, 47, 48, 55, 58, 59,
                60, 61, 62, 63, 64, 65, 66, 68, 70, 71, 72, 73, 74, 76, 77,
                78, 79, 81, 82, 89, 91, 93, 95, 98,99];

            const arraybrbottom =
              [6, 13, 26, 29, 39, 40, 43, 49, 50, 51, 52, 53, 54, 57, 83, 84, 85, 86, 87, 88, 97];

            const articles = [4, 16, 18, 20, 25, 32, 36, 42, 56, 67, 69, 75, 80, 90, 92, 94,96];
            const subTitles = [5, 7, 14];

            return (
              <React.Fragment key={`part_${i}`}>
                {
                  debug ? (
                    <>
                      {normal.includes(i) && (<>
                        <div dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)} (${i})`}}/>
                      </>)}

                      {arraybrbottom.includes(i) && (<>
                        <div dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)} (${i})`}}/>
                        <br/></>)}

                      {articles.includes(i) && (<><br/>
                        <div style={{fontWeight: "bold"}}
                             dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)} (${i})`}}/>
                        <br/></>)}

                      {subTitles.includes(i) && (<>
                        <div style={{textDecoration: "underline"}}
                             dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)} (${i})`}}/>
                        <br/></>)}
                    </>
                  ) : (
                    <>
                      {normal.includes(i) && (<>
                        <div dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)}`}}/>
                      </>)}

                      {arraybrbottom.includes(i) && (<>
                        <div dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)}`}}/>
                        <br/></>)}

                      {articles.includes(i) && (<><br/>
                        <div style={{fontWeight: "bold"}}
                             dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)}`}}/>
                        <br/></>)}

                      {subTitles.includes(i) && (<>
                        <div style={{textDecoration: "underline"}}
                             dangerouslySetInnerHTML={{__html: `${i18next.t(`legal_mention.text_${i}`)}`}}/>
                        <br/></>)}
                    </>
                  )
                }
              </React.Fragment>
            )
          })
        }
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
  showLegalMention: state.session.showLegalMention
});

export default connect(mapStateToProps)(LegalMention)
