import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const ProductLoading = () => (
  <Grid container style={{padding: "1em"}} spacing={2} justify={"center"} alignItems={"center"} alignContent={"center"}>
    <Grid item xs={3} className={"productImageCart"}>
      <Skeleton animation="wave" variant="rect" style={{width: "100%", height: "100%"}}/>
    </Grid>
    <Grid item xs={7} className={"productDescription"}>
      <Skeleton animation="wave" width={300} height={50}/>
      <Skeleton animation="wave" width={100} height={40}/>
    </Grid>
    <Grid item xs={2}>
      <Skeleton animation="wave" variant="rect" width={80} height={55}/>
    </Grid>
  </Grid>
);

export default ProductLoading;
