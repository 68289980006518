import React from "react"
import './css/products.css'
import './css/bottleSize.css'
import i18next from "i18next";
import Link from "@material-ui/core/Link";
import {BackEndLink, CART_ANIMATION, loggedParams, MENU_CHOICE} from "../../constants/global";
import {
  setBackDrop,
  setChoice,
  setCountSoft,
  setIdArticleSelected, setMenu,
  setShowSoft,
  setShowSoftGlass,
  setShowValidateModal,
  setSizeChoice
} from "../../actions/menu";
import {addNotValidated, setAnimateCart} from "../../actions/cart";
import axios from "axios";
import {setStateContainer} from "../../actions/session";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import {connect} from "react-redux";
import Preload from "react-preload";
import {CircularProgress, LinearProgress} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Add, Remove} from "@material-ui/icons";

const Product = (props) => {

  const [quantity, setQuantity] = React.useState(1)
  const [promo, setPromo] = React.useState(null)

  const {
    width,
    height,
    image,
    idArticle,
    category,
    name,
    description,
    price,
    choices,
    stateArticle,
    countSoft,
    promoted,
    premiumPromote,
    endPromote,
    conditionalDisplay
  } = props;

  React.useEffect(() => {
    if(endPromote) {
      const now = new Date().getTime()
      const endPromoteTimestamp = new Date(parseInt(endPromote,10) * 1000).getTime()

      if(now > endPromoteTimestamp) {
        axios.post(`${BackEndLink}/menu/get_menu`, loggedParams).then(result => {
          if (result.data.error !== "wrong_token")
            setMenu(result.data);
        });
      }
    }

    if(props.promos && props.promos.length > 0) {
      props.promos.forEach(p => {
        if(p.id_article === idArticle) {
          const end = new Date(p.end_promo).getTime()
          const now = new Date().getTime()

          if(end - now < 0)
            return ;

          setPromo(p) ;
        }

      })
    }

  }, [])

  const addCartNotHard = () => {
    setBackDrop(true);
    setAnimateCart(CART_ANIMATION.LOADING);
    const params = loggedParams.valueOf();
    params.set("id_article", idArticle);
    params.set("quantity", quantity);
    axios.post(`${BackEndLink}/cart/add`, params).then(result => {
      params.delete("id_article");
      setBackDrop(false);
      if (result.data.error === 0) {
        setQuantity(1)
        addNotValidated()
        setAnimateCart(CART_ANIMATION.SUCCESS);
        setShowValidateModal(true);
      } else {
        setStateContainer("waiting");
      }
    })
  };

  return (
    <>

      <div className={stateArticle === "0" ? "vertical-center" : "vertical-center productImageBlur"}
           style={{width: `${width}vw`, height: `${height}vh`, textAlign: "center"}}>

        <div className={"animate"} style={{height: "100%"}}>

          <Preload
            loadingIndicator={<div className={"productImage"}><CircularProgress className={"progressLoaderPosition"}/>
            </div>}
            images={[image]}
            resolveOnError={true}
            mountChildren={true}
          >
            <div className={"productImage"} style={{backgroundImage: `url("${image}")`}}/>
          </Preload>

          <div style={{marginTop: "10%"}}/>
          <div className={"legend"}>

            {
              premiumPromote === 1 && <div className="ribbon-wrapper">
                <div className="glow"/>
                <div className="ribbon-front">
                  <div className={"ribbon-rotate"}><LocalBarIcon className={"ribbon-icon"}/></div>
                </div>
                <div className="ribbon-edge-topleft"/>
                <div className="ribbon-edge-topright"/>
                <div className="ribbon-edge-bottomleft"/>
                <div className="ribbon-edge-bottomright"/>
              </div>
            }

            {
              promo && (<div className={"discount-position"} style={{backgroundColor: props.colors.fontAndCategoryColor}}>
                <div className="discount-label red"><span>-{promo.promo}%</span></div>
              </div>)
            }



            <p className={"category"} style={{
              color: props.colors.fontAndCategoryColor,
            }}>
              {i18next.t(`categories.${category}`)}
            </p>
            <p className={"name"} style={{
              color: props.colors.fontAndCategoryColor,
            }}>
              {name}
            </p>
            <p className={"description"}>
              {description}
            </p>

            <div className={"price"} style={{
              color: props.colors.fontAndCategoryColor,
            }}>
              {
                (promo && !promo.id_bottle) ? (
                  <><s>{price}</s> {price * (1 - (parseInt(promo.promo, 10) / 100))}</>
                ) : price
              }

              <p className={"euro"} style={{
                color: props.colors.fontAndCategoryColor,
              }}>{props.currency}</p>
            </div>

            {
              !props.onlyAds && (
                <div className={"quantitySelection"} style={{
                  color: props.colors.fontAndCategoryColor,
                }}>
                  <IconButton disabled={quantity <= 1} onClick={() => setQuantity(quantity - 1)} color="secondary" aria-label="add an alarm">
                    <Remove style={{fontSize: "1.5em", color: quantity <= 1 ? "" : props.colors.fontAndCategoryColor}} fontSize="large"/>
                  </IconButton>
                  x{quantity}
                  <IconButton color="secondary" onClick={() => setQuantity(quantity + 1)} aria-label="add an alarm">
                    <Add style={{fontSize: "1.5em", color: props.colors.fontAndCategoryColor}} fontSize="large"/>
                  </IconButton>
                </div>
              )
            }

            <div>

              {!choices && (

                stateArticle === "0" ? (
                  !props.onlyAds && (
                    <Link underline={"none"} onClick={() => {
                      setChoice(false);
                      setCountSoft(countSoft)
                      setSizeChoice({quantity})
                      if ((category === MENU_CHOICE.VODKA || category === MENU_CHOICE.TEQUILA || category === MENU_CHOICE.GIN || category === MENU_CHOICE.WHISKY || category === MENU_CHOICE.RHUM || category === MENU_CHOICE.PACK || category === MENU_CHOICE.LIQUOR) && countSoft > 0) {
                        setIdArticleSelected(idArticle);
                        setShowSoft(true);
                      } else if (category === MENU_CHOICE.GLASS || category === MENU_CHOICE.SHOT) {
                        setIdArticleSelected(idArticle);
                        setShowSoftGlass(true);
                      } else {
                        addCartNotHard();
                      }
                    }}
                    >
                      <div className={"waiterButtonAction persButton yellowPersButton addToCart"} style={{
                        color: props.colors.fontAndCategoryColor,
                        backgroundColor: props.colors.buttonOrderColor
                      }}>
                        {i18next.t("cart.add")}
                      </div>
                    </Link>
                  )
                ) : (
                  <div className={"waiterButtonAction persButton redPersButton addToCart"}>
                    {i18next.t("cart.outOfStock")}
                  </div>
                )

              )}

              {choices && (

                stateArticle === "0" ? (
                  <Link underline={"none"} onClick={() => {
                    setCountSoft(countSoft)
                    setSizeChoice({
                      ...props,
                      open: true,
                      choices,
                      image,
                      price,
                      height: "0vh",
                      selected: -1,
                      category,
                      idArticle,
                      quantity
                    });
                  }}>
                    <div className={"waiterButtonAction persButton yellowPersButton addToCart"} style={{
                      color: props.colors.fontAndCategoryColor,
                      backgroundColor: props.colors.buttonOrderColor
                    }}>
                      {
                        i18next.t("global.showSizes")
                      }
                    </div>
                  </Link>

                ) : (
                  <div className={"waiterButtonAction persButton redPersButton addToCart"}>
                    {i18next.t("cart.outOfStock")}
                  </div>
                )
              )}

            </div>
          </div>
          <div className={"shapeBG"}
               style={{backgroundImage: `url(/assets/img/menu/blob${Math.floor(Math.random() * Math.floor(3)) + 1}.png)`}}/>
        </div>
      </div>

    </>
  )
};

const mapStateToProps = (state) => ({
  currency: state.session.currency,
  onlyAds: state.session.onlyAds,
  colors: state.session.colors,
  promos: state.menu.promos
});

export default connect(mapStateToProps)(Product)
