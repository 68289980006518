import React from "react";
import Product from "./Product";
import ProductLoading from "./ProductLoading";
import axios from "axios";
import {BackEndLink, cartTimeout, loggedParams} from "../../constants/global";
import {connect} from "react-redux";
import i18next from "i18next";
import {setNewNotValidated, setShowCart} from "../../actions/cart";
import {toast} from "react-toastify";
import "./css/cart.css";
import {Grid, Link} from "@material-ui/core"
import Slide from "@material-ui/core/Slide";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LinearProgress from "@material-ui/core/LinearProgress";
import {setBackDrop, setShowError} from "../../actions/menu";
import {setShowBill} from "../../actions/billing";

const Cart = (props) => {

  const [error, setError] = React.useState("");
  const [isDisabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState({});
  const [total, setTotal] = React.useState(0.00);
  const [discount, setDiscount] = React.useState(0);
  const [service, setService] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState("")
  const [success, setSuccess] = React.useState(false);
  const [state1, setState1] = React.useState(0);
  const [state2, setState2] = React.useState(0);
  const [elapsedTime, setElapsedTime] = React.useState(0);
  const [paidArticles, setPaidArticles] = React.useState(0);
  const ref = React.useRef();
  const autoResfreshTimeout = React.useRef();

  const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const loadCart = () => {
    setTotal(0);
    setPaidArticles(0);
    setLoading(true);
    axios.post(`${BackEndLink}/cart/get`, loggedParams).then(result => {
      if (result.data.articles) {
        setProducts(result.data.articles);

        let tempPaidArticles = 0;

        result.data.articles.forEach(article => {
          if (parseInt(article.state_orders_lines, 0) === 3) {
            tempPaidArticles += parseFloat(article.price_article) * parseFloat(article.quantity);
          }
        });

        setPaidArticles(tempPaidArticles);

      }

      if (result.data.discount)
        setDiscount(result.data.discount);

      if (result.data.service)
        setService(parseFloat(result.data.service));

      setLoading(false);
      if (result.data.last_activity_order) {
        const date = new Date(result.data.last_activity_order);
        const lastActivityTimestamp = Math.floor(date.getTime() / 1000);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        setElapsedTime(currentTimestamp - lastActivityTimestamp);
      }
    })
  };

  const autoRefresh = () => {
    if (props.showCart) {
      if (!window.Android) {
        autoResfreshTimeout.current = setTimeout(() => {
          setShowCart(false)
        }, cartTimeout)
      }
    }
  }

  React.useEffect(() => {
    if (props.adLaunched)
      setOpen(false);
  }, [props.adLaunched])

  React.useEffect(() => {
    if (props.showCart) {
      loadCart();
      autoRefresh();
    } else {
      clearTimeout(autoResfreshTimeout.current)
    }
  }, [props.showCart]);

  React.useEffect(() => {
    if (Object.keys(products).length === 0) {
      // setNewNotValidated(0);
    } else {
      let buttonValidate = false;
      let totalCalcul = 0.00;

      let totalState1 = 0;
      let totalState2 = 0;

      Object.keys(products).forEach(product => {
        if (products[product].state_orders_lines === "0")
          buttonValidate = true;

        if (products[product].state_orders_lines === "1") {
          totalState1 += 1;
        } else if (products[product].state_orders_lines === "2") {
          totalState2 += 1;
        } else if (products[product].state_orders_lines === "3") {
          totalState2 += 1;
        }

        totalCalcul += parseFloat(products[product].price_article) * parseInt(products[product].quantity, 10);
        totalCalcul.toFixed(2)
      });

      setTotal(totalCalcul)
      setState1(totalState1)
      setState2(totalState2)
    }
  }, [products]);

  const cleanPin = () => {
    setCode("")
    setDisabled(false);
    if (ref.current) {
      ref.current[0].value = "";
      ref.current[1].value = "";
      ref.current[2].value = "";
    }
  }

  const validateCart = (codePin) => {

    const params = loggedParams.valueOf();
    params.set("pin", codePin);
    setBackDrop(true);
    axios.post(`${BackEndLink}/cart/validate`, params).then(result => {
      params.delete("pin");
      setDisabled(false);
      setBackDrop(false);
      if (result.data.error === 0) {
        setNewNotValidated(0);
        setOpen(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          loadCart();
        }, 2000)
        setSuccess(true);
        cleanPin();
      } else if (result.data.error === 2) {
        cleanPin();
      } else {
        toast.error(i18next.t("cart.validateError"));
      }
    })
  };

  const validatePin = (codePin) => Number(codePin) === Number(props.pin)

  const changeCodePin = (action) => {
    if (!isDisabled) {
      if (code.length <= 1) {
        ref.current[code.length].value = action.i;
        setCode(code + action.i);
      } else if (code.length === 2) {
        ref.current[code.length].value = action.i;
        setCode(code + action.i);
        setDisabled(true);
        if (validatePin(code + action.i)) {
          validateCart(code + action.i);
          setError("");
        } else {
          setShowError(i18next.t("cart.wrong"))
          setDisabled(true);
          setTimeout(() => {
            setError("");
            cleanPin();
          }, 2000)
        }
      }
    }
  }

  const removeCart = (idOrdersLines) => {
    setBackDrop(true)
    const params = loggedParams.valueOf();
    params.set("id_orders_lines", idOrdersLines);
    axios.post(`${BackEndLink}/cart/remove`, params).then(result => {
      params.delete("id_orders_lines");
      setBackDrop(false)
      loadCart();
      if (result.data.error === 0) {
        setNewNotValidated(props.notValidated - 1)
      } else {
        toast.error(i18next.t("cart.removeError"));
      }
    })
  };

  return (
    <>
      { /*
      {open && <Link onClick={() => setOpen(false)}>
        <div className={"backdropMainPin"}/>
      </Link>}
      {open && (
        <>
          <div className={"pinDialog"}>
            <DialogTitle id="simple-dialog-title"><span
              className={"titlePinCart"}>{i18next.t("pin.fill")}</span></DialogTitle>
            <Link onClick={() => setOpen(false)}>
              <div className={"closeDialog"}>
                <Close style={{fontSize: "2em"}}/>
              </div>
            </Link>
            <DialogContent>
              <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{width: "70%", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                  <div className={"pinResult"}>
                    <PinField
                      type={""}
                      ref={ref}
                      validate="0123456789"
                      disabled={true}
                      onChange={setCode}
                      className={"pinField"}
                      length={3}
                    />
                    <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}
                          className={"circlePinContainer"}>
                      <Grid item xs={1} className={"tc"}>
                        <div className={"circlePinPoint"}>
                          <div className={"circlePin"}>
                            <div className={code.length >= 1 ? "circlePinFull circlePinFullFilled" : "circlePinFull"}/>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={1} className={"tc"}>
                        <div className={"circlePinPoint"}>
                          <div className={"circlePin"}>
                            <div className={code.length >= 2 ? "circlePinFull circlePinFullFilled" : "circlePinFull"}/>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={1} className={"tc"}>
                        <div className={"circlePinPoint"}>
                          <div className={"circlePin"}>
                            <div className={code.length >= 3 ? "circlePinFull circlePinFullFilled" : "circlePinFull"}/>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid className={"pinDivider"} item xs={12}/>

                {
                  numberArray.map((i) =>
                    <Grid key={i} className={isDisabled ? "disabledNumber" : "activatedNumber"} item xs={4}
                          style={{textAlign: "center", marginTop: "1em"}}>
                      <Link underline={"none"} onClick={() => changeCodePin({i})}>
                        <div className={"badge"}>
                          {i}
                        </div>
                      </Link>
                    </Grid>
                  )
                }

                <Grid item xs={4} style={{textAlign: "center", marginTop: "1em"}}/>

                <Grid className={isDisabled ? "disabledNumber" : "activatedNumber"} item xs={4}
                      style={{textAlign: "center", marginTop: "1em"}}>
                  <Link underline={"none"} onClick={() => changeCodePin({i: 0})}>
                    <div className={"badge"}>
                      0
                    </div>
                  </Link>
                </Grid>

                <Grid className={isDisabled ? "disabledNumber" : "activatedNumber"} item xs={4}
                      style={{textAlign: "center", marginTop: "1em"}}>
                  <Link underline={"none"} onClick={() => cleanPin()}>
                    <div className={"badge"}>
                      <BackspaceIcon/>
                    </div>
                  </Link>
                </Grid>
              </Grid>

              <Link underline={"none"} onClick={() => {
                setSosChoice("pin")
                setSos(true)
              }}>
                <div className={"waiterButtonAction persButton yellowPersButton pinCartForgot buttonPinForgot"}>
                  {i18next.t("sos.pin")}
                </div>
              </Link>

            </DialogContent>
          </div>
        </>
      )}
      */}

      {
        success && (
          <div className={"successDialogContent"}>
            <div className={"successDialog"}>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          </div>
        )
      }

      <Slide direction="left" in={props.showCart} mountOnEnter unmountOnExit>

        <div className={"sideCart"}>
          <div className={"closeCart"}>
            <div className={"leftHeader"}>
              <Link onClick={() => setShowCart(false)}>
                <ArrowBackIosIcon className={"headerButton rotate180"}/>
              </Link>
            </div>
          </div>

          <div className={"titleCart"} style={{
            color: props.colors.fontAndCategoryColor
          }}>
            {i18next.t("cart.title")}
          </div>

          <div className={"progressCart"}>
            <Grid container>
              {
                products.length > 0 && props.notValidated > 0 && (
                  <>
                    <div className={"progressDescription_1"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>{i18next.t("cart.wait")}</div>

                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={0}/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={0}/>
                    </Grid>

                    <Grid className={"progressContainer"} item xs={12}>
                      <img alt={"hourglass"} className={"cartIconWaiting movingAnimation"}
                           src={`/assets/img/menu/hourglass.png`}/>
                    </Grid>
                  </>
                )
              }


              {
                products.length > 0 && props.notValidated === 0 && state1 > 0 && (
                  <>
                    <div className={"progressDescription_1"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>{i18next.t("cart.validation")}</div>

                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={100}/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={0}/>
                    </Grid>

                    <Grid className={"progressContainer"} item xs={12}>
                      <img alt={"hourglass"} className={"cartIconWaiting movingAnimation"}
                           src={`/assets/img/menu/hourglass.png`}/>
                    </Grid>

                  </>
                )
              }

              {
                products.length > 0 && props.notValidated === 0 && state1 === 0 && state2 > 0 && elapsedTime <= 600 && (
                  <>
                    <div className={"progressDescription_1"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>{i18next.t("cart.arriving")}</div>

                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={100}/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={100}/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress/>
                    </Grid>

                    <Grid className={"progressContainer"} item xs={12}>
                      <img alt={"hourglass"} className={"cartIconWaiting movingAnimation"}
                           src={`/assets/img/menu/comming_bottle.png`}/>
                    </Grid>

                  </>
                )
              }

              {
                products.length > 0 && props.notValidated === 0 && state1 === 0 && state2 > 0 && elapsedTime > 600 && (
                  <>
                    <div className={"progressDescription_1"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>{i18next.t("cart.finish")}</div>

                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={100}/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={100}/>
                    </Grid>
                    <Grid className={"progressContainer"} item xs={4}>
                      <LinearProgress variant="determinate" value={100}/>
                    </Grid>

                    <Grid className={"progressContainer"} item xs={12}>
                      <img alt={"hourglass"} className={"cartIconWaiting movingAnimation"}
                           src={`/assets/img/menu/tchin.png`}/>
                    </Grid>

                  </>
                )
              }
            </Grid>
          </div>

          <div className={"cartContainer"}>
            {
              props.minPrice > 0 && <div className={"minPriceOrder"} style={{
                color: props.colors.fontAndCategoryColor
              }}>{i18next.t("cart.minOrder")} : {props.minPrice}{props.currency}</div>
            }

            {
              !loading ? (
                <>
                  {
                    products.map((product) => {
                      return (
                        <Product
                          currency={props.currency}
                          softs={product.softs}
                          key={product.id_orders_lines}
                          price={product.price_article}
                          bottle_name={product.bottle_name}
                          quantity={product.quantity}
                          bottle_size={product.bottle_size}
                          picture={product.article_img}
                          category={product.name_category_article}
                          name={product.name_article}
                          description={product.desc_article}
                          remove={() => removeCart(product.id_orders_lines)}
                          state={product.state_orders_lines}
                        />
                      )
                    })
                  }
                </>
              ) : (
                <>
                  <ProductLoading/>
                  <ProductLoading/>
                  <ProductLoading/>
                </>
              )
            }

          </div>

          <div className={"actionCart"}>
            <div className={"actionCartCenter"}>
              <Grid container style={{height: "100%"}} justify={"center"} alignContent={"center"} alignItems={"center"}>
                {
                  (service > 0) && (
                    <Grid item xs={12} className={"discount"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>
                      {
                        i18next.t("cart.service")
                      }
                      {`${service}`}{props.currency}
                    </Grid>
                  )
                }

                {
                  ((discount > 0 || discount < 0) && service === 0) && (
                    <Grid item xs={12} className={"discount"} style={{
                      color: props.colors.fontAndCategoryColor
                    }}>
                      {
                        discount < 0 ? (
                          i18next.t("cart.discount")
                        ) : (
                          i18next.t("cart.tips")
                        )
                      }
                      {`${discount}%`}
                    </Grid>
                  )
                }
                <Grid item xs={6} className={"cartTotalLabel"} style={{
                  color: props.colors.fontAndCategoryColor
                }}>
                  Total
                </Grid>
                <Grid item xs={6} className={"cartActionPrice"} style={{
                  color: props.colors.fontAndCategoryColor
                }}>
                  <div className={"cartActionPriceEuro"} style={{
                    color: props.colors.fontAndCategoryColor
                  }}>{props.currency}</div>
                  {(Math.round(total * 100) / 100) - paidArticles + service}
                </Grid>

                {
                  (props.notValidated > 0) && (
                    <Grid item xs={6} className={"cartOrder"}>
                      <Link underline={"none"} onClick={() => {
                        if (props.minPrice > 0 && total < props.minPrice) {
                          setShowError(`${i18next.t("cart.minOrder")} : ${props.minPrice}${props.currency}`)
                          return;
                        }
                        validateCart()
                        // setOpen(true);
                      }}>
                        <div className={"waiterButtonAction persButton yellowPersButton addToCart validateOrder"}
                             style={{
                               color: props.colors.fontAndCategoryColor,
                               backgroundColor: props.colors.buttonOrderColor
                             }}>
                          {i18next.t("cart.validate")}
                        </div>
                      </Link>
                    </Grid>
                  )
                }

                {
                  (products.length === state2 && state2 > 0 && (total - paidArticles) > 0) && (
                    <Grid item xs={6} className={"cartOrder"}>
                      <Link underline={"none"} onClick={() => {
                        setShowCart(false)
                        setShowBill(true)
                      }}>
                        <div className={"waiterButtonAction persButton yellowPersButton addToCart validateOrder"}>
                          {i18next.t("bill.title")}
                        </div>
                      </Link>
                    </Grid>
                  )
                }
              </Grid>
            </div>

          </div>

        </div>

      </Slide>

      {
        props.showCart && (
          <>
            <Link underline={"none"} onClick={() => setShowCart(false)}>
              <div>

                <div className={"backgroundSideCart"}/>
              </div>
            </Link>
          </>
        )
      }

    </>
  )
};

const mapStateToProps = (state) => ({
  notValidated: state.cart.notValidated,
  pin: state.session.pin,
  currency: state.session.currency,
  minPrice: state.session.minPrice,
  showCart: state.cart.showCart,
  colors: state.session.colors,
  adLaunched: state.session.adLaunched
});

export default connect(mapStateToProps)(Cart)
