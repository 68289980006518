export default {
  // Counter
  INCREMENT_COUNTER: 'INCREMENT_COUNTER',
  DECREMENT_COUNTER: 'DECREMENT_COUNTER',

  // Session
  SET_NEW_TOKEN: "SET_NEW_TOKEN",
  SET_ORDER: "SET_ORDER",
  SET_PIN: "SET_PIN",
  SET_WELCOME: "SET_WELCOME",
  SET_REFILL: "SET_REFILL",

  // Language
  SET_NEW_LANG: "SET_NEW_LANG",

  SET_NEW_MENU: "SET_NEW_MENU",
  SET_SOS: "SET_SOS",

  // SET_NEW_NOT_VALIDATED
  SET_NEW_NOT_VALIDATED: "SET_NEW_NOT_VALIDATED",
  SET_ANIMATION_TO_DO: "SET_ANIMATION_TO_DO",

  SET_NEW_ROUTE: "SET_NEW_ROUTE",
  SET_SHOW_CART: "SET_SHOW_CART",

  SET_SHOW_VALIDATE_MODAL: "SET_SHOW_VALIDATE_MODAL",

  SET_SHOW_SUCCESS: "SET_SHOW_SUCCESS",
  SET_SHOW_ERROR: "SET_SHOW_ERROR",

  SET_SIZE_CHOICE: "SET_SIZE_CHOICE",

  SET_SHOW_BILL: "SET_SHOW_BILL",

  SET_SHOW_SOFT: "SET_SHOW_SOFT",
  SET_SHOW_SOFT_GLASS: "SET_SHOW_SOFT_GLASS",
  SET_SELECTED_SOFT: "SET_SELECTED_SOFT",
  SET_ID_ARTICLE_SELECTED: "SET_ID_ARTICLE_SELECTED",

  SET_CHOICE: "SET_CHOICE",
  SET_ADDING_CHOICE: "SET_ADDING_CHOICE",

  SET_NEW_LOGO: "SET_NEW_LOGO",
  ADD_ONE_NOT_VALIDATED: "ADD_ONE_NOT_VALIDATED",

  SHOW_LEGAL_MENTION: "SHOW_LEGAL_MENTION",
  SET_SHOW_REFILL: "SET_SHOW_REFILL",
  SET_STATE_CONTAINER: "SET_STATE_CONTAINER",
  SET_LAUNCH_AD: "SET_LAUNCH_AD",
  SET_SOS_CHOICE: "SET_SOS_CHOICE",
  SET_ERROR_REFUSED: "SET_ERROR_REFUSED",
  SET_CURRENCY: "SET_CURRENCY",

  SET_BACK_DROP: "SET_BACK_DROP",

  REFRESH_MESSAGES: "REFRESH_MESSAGES",
  NEW_CHAT: "NEW_CHAT",
  SET_SELECTED_CHAT: "SET_SELECTED_CHAT",
  SET_COUNT_SOFT: "SET_COUNT_SOFT",
  SET_MIN_PRICE: "SET_MIN_PRICE",
  SET_CHAT_OFF: "SET_CHAT_OFF",
  SET_COLORS: "SET_COLORS",
  SET_NAME_ESTABLISHMENT: "SET_NAME_ESTABLISHMENT",
  SET_NEW_PREMIUM_PARTNER: "SET_NEW_PREMIUM_PARTNER",
  SET_NEW_PARTNERS: "SET_NEW_PARTNERS",
  SET_AD_LAUNCHED: "SET_AD_LAUNCHED",

  SET_ONLY_ADS: "SET_ONLY_ADS",

  SET_BIRTHDAY: "SET_BIRTHDAY",
  SET_SHOW_PARTNERS: "SET_SHOW_PARTNERS",
  SET_EVENT: "SET_EVENT",
  SET_START_NEW_YEAR: "SET_START_NEW_YEAR",
  SET_EMAIL: "SET_EMAIL",
  SET_PROMOS: "SET_PROMOS",
  SET_REFRESH_PROMOS: "SET_REFRESH_PROMOS",
  SET_IGNORE_PLUG: "SET_IGNORE_PLUG"
}

