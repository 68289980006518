/*
  How the chat should maybe works :
    - save the last 20 messages
    - require new messages with sending all the id of the messages
 */

import React from 'react'
import {BackEndLink, loggedParams} from "../../constants/global";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import './css/chat.css';
import Header2 from "../Header/Header2";
import SeatingPlan from "./SeatingPlan";
import i18next from "i18next";
import {setBackDrop, setShowError} from "../../actions/menu";
import {connect} from "react-redux";
import {refreshMessage, setSelectedChat} from "../../actions/session";
import Icon from "@material-ui/core/Icon";
import LocalBarIcon from '@material-ui/icons/LocalBar';

const Chat = (props) => {

  const [messagesState, setMessagesState] = React.useState([]);
  const [openSeatingPlan, setOpenSeatingPlan] = React.useState(false);
  const [chatTable, setChatTable] = React.useState(null)
  const [chat, setChat] = React.useState("");
  const messagesEndRef = React.createRef()
  const idTablet = localStorage.getItem("id_tablet_customer")
  const idTable = localStorage.getItem("id_table")
  const inputRef = React.createRef()
  const messagesRef = React.createRef()

  const getChat = () => {
    if (props.selectedChat)
      return;
    axios.post(`${BackEndLink}/features/getChat`, loggedParams).then(result => {
      setMessagesState(result.data.messages);
      setBackDrop(false)
    })
  };

  const getPrivateChat = () => {

    props.newChat.forEach(t => {
      if (parseInt(t.table, 0) === parseInt(chatTable, 0)) {
        t.newMessages = 0;
      }
    })
    refreshMessage({})

    const params = loggedParams.valueOf();
    params.set("chatTable", chatTable);
    axios.post(`${BackEndLink}/features/getPrivateChat`, params).then(result => {
      params.delete("chatTable");
      setMessagesState(result.data.messages);
      setBackDrop(false)
    })
  };

  const sendChat = (e) => {
    e.preventDefault();
    if (chat.length === 0)
      return;
    setBackDrop(true)
    const params = loggedParams.valueOf();
    params.set("message", chat);
    setChat("");
    axios.post(`${BackEndLink}/features/sendChat`, params).then(result => {
      setBackDrop(false)
      params.delete("message");
      setMessagesState(result.data.messages);
    })
  };

  const sendPrivateChat = (e) => {
    e.preventDefault();
    if (chat.length === 0)
      return;
    setBackDrop(true)
    const params = loggedParams.valueOf();
    params.set("message", chat);
    params.set("chatTable", chatTable);
    setChat("");
    axios.post(`${BackEndLink}/features/sendPrivateChat`, params).then(result => {
      if (props.newChat.filter(d => parseInt(d.table, 0) === parseInt(chatTable, 0)).length === 0) {
        const newVal = []
        newVal.table = chatTable;
        newVal.newMessages = 0;
        props.newChat.push(newVal);
        refreshMessage({})
      }

      setBackDrop(false)
      params.delete("message");
      params.delete("chatTable");
      setMessagesState(result.data.messages);
      if (result.data.error) {
        setShowError(i18next.t(result.data.error))
      }
    })
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
  }

  React.useEffect(() => {
    setBackDrop(true)
    if (props.selectedChat) {
      setChatTable(props.selectedChat);

      props.newChat.forEach(t => {
        if (parseInt(t.table, 0) === parseInt(chatTable, 0)) {
          t.newMessages = 0;
        }
      })
      setSelectedChat(null)
      refreshMessage({})
    } else {
      getChat();
    }

  }, []);

  React.useEffect(() => {
    if (props.selectedChat) {
      setChatTable(props.selectedChat);
      const index = props.newChat.indexOf(props.selectedChat);
      if (index > -1) {
        props.newChat.splice(index, 1);
      }
      setSelectedChat(null)
      refreshMessage({})
    }
  }, [props.selectedChat])

  React.useEffect(() => {
    scrollToBottom();
  }, [messagesState])

  const messageReversed = [...messagesState].reverse();

  const onBlurField = () => {
    inputRef.current.parentElement.parentElement.style.bottom = "0vh"
    messagesRef.current.style.height = "77vh"
  }

  const onFocusField = () => {
    inputRef.current.parentElement.parentElement.style.bottom = "31vh"
    messagesRef.current.style.height = "45vh"
    scrollToBottom()
  }

  React.useEffect(() => {
    if (props.refreshChat.from && props.refreshChat.to) {
      if (props.refreshChat.from !== idTablet && props.refreshChat.to === "public" && !chatTable) {
        setBackDrop(true)
        getChat()
        refreshMessage({})
      }

      if (chatTable && parseInt(props.refreshChat.to, 0) === parseInt(idTable, 0) && parseInt(props.refreshChat.from, 0) === parseInt(chatTable, 0)) {
        getPrivateChat()
        refreshMessage({})
      }
    }
  }, [props.refreshChat])

  React.useEffect(() => {
    setMessagesState([]);
    setBackDrop(true);
    if (chatTable) {
      getPrivateChat()
    } else {
      getChat()
    }
  }, [chatTable])

  return (
    <>

      <Header2 chat={true}/>

      <div style={{marginTop: "6vh"}}/>

      <SeatingPlan setOpenSeatingPlan={setOpenSeatingPlan} open={openSeatingPlan} setChatTable={setChatTable}/>

      {
        chatTable ? (
          <div className={"messagesTitle"}>
            {i18next.t("chat.privateChat")} {chatTable}
          </div>
        ) : (
          <div className={"messagesTitle"}>
            {i18next.t("chat.public")}
          </div>
        )
      }

      {
        chatTable ? (
          <Grid container justify={"center"} alignItems={"center"} alignContent={"center"} className={"buttonsChat"}>
            <div className={"yellowButtonFull"} onClick={() => setChatTable(false)}>
              {i18next.t("chat.goToPublic")}
            </div>
          </Grid>
        ) : (
          <Grid container justify={"center"} alignItems={"center"} alignContent={"center"} className={"buttonsChat"}>
            <div className={"yellowButtonFull"} onClick={() => setOpenSeatingPlan(true)}>
              {i18next.t("chat.private")}
            </div>
          </Grid>
        )
      }


      <div ref={messagesRef} className={"messages"}>
        {
          messageReversed.map((message) => (
            <div key={message.id_chat ? `chat_${message.id_chat}` : `chat_${message.id_private_chat}`}
                 className={"messageContainer"}>
              {
                (localStorage.getItem("id_table") !== message.id_table && localStorage.getItem("id_table") !== message.sender) ? (
                  <Grid className={"messageContainerPers"} container justify={"flex-start"} alignItems={"center"}>
                    <Grid item xs={2} style={{position: "relative"}}>
                      <div className={"numberCircleLeft"}>
                        <div className={"numberCircleDigits"}>
                          {
                            message.id_table ? message.name_table : message.sender ? message.sender : <LocalBarIcon className={"adminChat"}/>
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={9} className={"messageLeftContainer"}>
                      <div className={"message messageLeft"}>
                        {message.message}
                      </div>
                    </Grid>
                    <Grid item xs={1} className={"messageHour"}>
                      {message.date.substr(11, 5)}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid className={"messageContainerPers"} direction={"row-reverse"} container justify={"flex-start"}
                        alignItems={"center"}>
                    <Grid item xs={2} style={{position: "relative"}}>
                      <div className={"numberCircleRight"}>
                        <div className={"numberCircleDigits"}>
                          {
                            message.id_table ? message.name_table : message.sender
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={9} className={"messageRightContainer"}>
                      <div className={"message messageRight"}>
                        {message.message}
                      </div>
                      {
                        message.state && (
                          <div className={"stateMessage"}>
                            {
                              message.state === "1" ? (
                                i18next.t("chat.saw")
                              ) : (
                                i18next.t("chat.notSaw")
                              )
                            }
                          </div>
                        )
                      }
                    </Grid>
                    <Grid item xs={1} className={"messageHour"}>
                      {message.date.substr(11, 5)}
                    </Grid>
                  </Grid>
                )
              }

            </div>
          ))
        }

        <div className={"messagesEnd"} ref={messagesEndRef}/>


      </div>

      <footer>
        <Grid justify={"center"} alignItems={"center"} alignContent={"center"} container className={"footerChat"}>
          <Grid item xs={12}>
            <form ref={inputRef} style={{position: "relative"}} onSubmit={(e) => {
              if (chatTable) {
                sendPrivateChat(e)
              } else {
                sendChat(e)
              }
            }}>
              <input onFocus={onFocusField} onBlur={onBlurField} value={chat}
                     onChange={e => setChat(e.target.value)} className={"inputChat"}/>
            </form>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
const mapStateToProps = (state) => ({
  refreshChat: state.session.refreshChat,
  newChat: state.session.newChat,
  selectedChat: state.session.selectedChat
});

export default connect(mapStateToProps)(Chat)
