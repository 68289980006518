import React from "react"
import Header from "../Header/Header";
import './css/firstMenu.css'
import {AdMediaLink, ColorLuminance, contrast, invertColor, MENU_CHOICE} from "../../constants/global";
import i18next from "i18next";
import {CircularProgress, Link} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {setSos} from "../../actions/menu";
import {setShowBill} from "../../actions/billing";
import {connect} from "react-redux";
import {setShowLegalMention, setShowPartners, setStateContainer} from "../../actions/session";
import {ArrowBackIos, ArrowForwardIos, Close} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Preload from "react-preload";


const FirstMenu = (props) => {

  const [selectedPartner, SetSelectedPartner] = React.useState(null);

  const buttons = [
    {
      className: props.menu.FOODS.count ? "alcoolButtonFood" : "alcoolButton",
      callback: () => props.changeMenu(1, MENU_CHOICE.VODKA),
      backgroundColor: props.colors.hardBackgroundColor,
      menuLegend: "menuLegendLeft",
      backgroundColorLegend: props.colors.buttonsColor,
      colorTextLegend: props.colors.buttonsTextColor,
      image: "/assets/img/menu/alcools.png",
      text: "mainMenu.hard",
      condition: true
    }, {
      className: props.menu.FOODS.count ? "softButtonFood" : "softButton",
      callback: () => props.changeMenu(1, MENU_CHOICE.SODA),
      backgroundColor: props.colors.softBackgroundColor,
      menuLegend: "menuLegendTop",
      backgroundColorLegend: props.colors.buttonsColor,
      colorTextLegend: props.colors.buttonsTextColor,
      image: "/assets/img/menu/softs.png",
      text: "mainMenu.soft",
      condition: true
    }, {
      className: props.menu.FOODS.count ? "otherButtonFood" : "otherButton",
      callback: () => props.changeMenu(1, MENU_CHOICE.SWEETS),
      backgroundColor: props.colors.otherBackgroundColor,
      menuLegend: "menuLegendBottom",
      backgroundColorLegend: props.colors.buttonsColor,
      colorTextLegend: props.colors.buttonsTextColor,
      image: "/assets/img/menu/others.png",
      text: "mainMenu.other",
      condition: true
    },{
      className: "foodButton",
      callback: () => props.changeMenu(1, MENU_CHOICE.FOOD),
      backgroundColor: props.colors.foodBackgroundColor,
      menuLegend: "menuLegendBottom",
      backgroundColorLegend: props.colors.buttonsColor,
      colorTextLegend: props.colors.buttonsTextColor,
      image: "/assets/img/menu/food.svg",
      text: "mainMenu.food",
      condition: props.menu.FOODS.count > 0
    },
    {
      className: "sosButton",
      callback: () => setSos(true),
      backgroundColor: props.colors.sosBackgroundColor,
      menuLegend: "menuLegendBottom smallMenuSOS",
      backgroundColorLegend: props.colors.sosButtonColor,
      colorTextLegend: props.colors.sosTextColor,
      image: "/assets/img/menu/sosMain.png",
      text: "mainMenu.sos",
      condition: !props.onlyAds
    },
    {
      className: "chatButton",
      callback: () => setStateContainer("chat"),
      backgroundColor: props.colors.chatAndSelfieBackgroundColor,
      menuLegend: "menuLegendTop smallMenuSOS",
      backgroundColorLegend: props.colors.chatAndSelfieButtonColor,
      colorTextLegend: props.colors.chatAndSelfieTextColor,
      image: "/assets/img/menu/chat.png",
      text: "mainMenu.chat",
      condition: !props.onlyAds && !props.chatOff
    },
    {
      className: props.chatOff ? "selfieButtonChatOff" : "selfieButton",
      callback: () => setStateContainer("selfie"),
      backgroundColor: props.colors.chatAndSelfieBackgroundColor,
      menuLegend: "menuLegendBottom smallMenuSOS",
      backgroundColorLegend: props.colors.chatAndSelfieButtonColor,
      colorTextLegend: props.colors.chatAndSelfieTextColor,
      image: "/assets/img/menu/selfieMain.png",
      text: "mainMenu.selfie",
      condition: !props.onlyAds
    }
  ]

  const backPartner = () => {
    if (parseInt(selectedPartner, 0) === 0) {
      const newVal = props.partners.length - 1;
      SetSelectedPartner(newVal.toString());
    } else {
      const newVal = parseInt(selectedPartner, 0) - 1;
      SetSelectedPartner(newVal.toString());
    }
  }

  const forwardPartner = () => {
    if (parseInt(selectedPartner, 0) === props.partners.length - 1) {
      SetSelectedPartner("0");
    } else {
      const newVal = parseInt(selectedPartner, 0) + 1;
      SetSelectedPartner(newVal.toString());
    }
  }

  const showButton = (callback, className, backgroundColor, menuLegend, backgroundColorLegend, colorTextLegend, text, image, show = true) => (
    <React.Fragment key={image}>
      {show &&
      <Link onClick={callback}>
        <Preload
          loadingIndicator={<>
            <div className={className} style={{
              backgroundColor
            }}>
              <CircularProgress className={"progressLoaderPosition"}/>
              <div className={props.language === "ae" ? `${menuLegend} arab` : menuLegend} style={{
                backgroundColor: backgroundColorLegend,
                color: colorTextLegend
              }}>
                {i18next.t(text)}
              </div>
            </div>
          </>}
          images={[image]}
          resolveOnError={true}
          mountChildren={true}
        >
          <div className={className} style={{
            backgroundImage: `url("${image}")`,
            backgroundColor
          }}>
            <div className={props.language === "ae" ? `${menuLegend} arab` : menuLegend} style={{
              backgroundColor: backgroundColorLegend,
              color: colorTextLegend
            }}>
              {i18next.t(text)}
            </div>
          </div>
        </Preload>
      </Link>
      }
    </React.Fragment>
  )

  React.useEffect(() => {
    if (props.partners.length > 0)
      SetSelectedPartner("0")
  }, [props.showPartners])

  React.useEffect(() => {
    if (props.adLaunched)
      setShowPartners(false);
  }, [props.adLaunched])

  return (
    <>
      <Header/>

      {
        (props.welcome && props.welcome.length > 0 && !props.onlyAds) && (
          <div className={"welcomeFirstMenu"} style={{
            color: contrast(props.colors.wave3)
          }}>
            {props.welcome}
          </div>
        )
      }

      <div className={"wave1"} style={{
        background: `linear-gradient(${props.colors.wave1}, ${props.colors.wave1}) no-repeat 0px 175px`
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill={props.colors.wave1}
                d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,234.7C560,256,640,256,720,250.7C800,245,880,235,960,229.3C1040,224,1120,224,1200,197.3C1280,171,1360,117,1400,90.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
        </svg>
      </div>

      <div className={"wave2"} style={{
        background: `linear-gradient(${props.colors.wave2}, ${props.colors.wave2}) no-repeat 0px 175px`
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill={props.colors.wave2}
                d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,234.7C560,256,640,256,720,250.7C800,245,880,235,960,229.3C1040,224,1120,224,1200,197.3C1280,171,1360,117,1400,90.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
        </svg>
      </div>

      <div className={"wave3"} style={{
        background: `linear-gradient(${props.colors.wave3}, ${props.colors.wave3}) no-repeat 0px 175px`
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill={props.colors.wave3}
                d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,234.7C560,256,640,256,720,250.7C800,245,880,235,960,229.3C1040,224,1120,224,1200,197.3C1280,171,1360,117,1400,90.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
        </svg>
      </div>

      <div className={!props.onlyAds ? "" : "onlyAds"}>
        {
          buttons.map(button => (
            showButton(button.callback, button.className, button.backgroundColor, button.menuLegend, button.backgroundColorLegend, button.colorTextLegend, button.text, button.image, button.condition)
          ))
        }
      </div>

      {
        !props.onlyAds && (
          <Link onClick={() => {
            setShowBill(true)
          }}>
            <div className={"mainBill"} style={{
              backgroundColor: props.colors.billBackgroundColor,
              color: props.colors.fontAndCategoryColor
            }}>
              {i18next.t("bill.title")} <ArrowForwardIcon/>
            </div>
          </Link>
        )
      }

      {
        props.partners.length > 0 && (
          <Link onClick={() => {
            setShowPartners(true)
          }}>
            <div className={"mainPartners"} style={{
              backgroundColor: props.colors.billBackgroundColor,
              color: props.colors.fontAndCategoryColor
            }}>
              {i18next.t("partners.main")}
            </div>
          </Link>
        )
      }


      <Link onClick={() => {
        setShowLegalMention(true)
      }}>
        <div className={"cgv"} style={{
          color: invertColor(props.colors.wave3)
        }}>{i18next.t("legal_mention.button")}</div>
      </Link>

      {props.showPartners && <div className={"backdropMainPartners"}/>}
      <Dialog
        hideBackdrop
        PaperProps={{
          className: "mainPartnersDialog",
        }} maxWidth={"xl"} spacing={4} fullWidth={true}
        onClose={() => {
          setShowPartners(false)
          SetSelectedPartner(null)
        }}
        aria-labelledby="customized-dialog-title"
        open={props.showPartners && props.type !== "asking"}>
        <DialogContent>
          <div className={"titleBillingPartner tw"}>
            {i18next.t("partners.main")}
          </div>
          <div className={"mainPartnerDialogText tw"}>
            {i18next.t("partners.text")}
          </div>

          <Link onClick={() => setShowPartners(false)}>
            <div className={"closeDialog"}>
              <Close style={{fontSize: "2em"}}/>
            </div>
          </Link>

          <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}
                className={"partnerBillingContainer"}>
            {
              Object.keys(props.partners).map((i) => (
                <Grid key={i} onClick={() => {
                  if (selectedPartner === i)
                    SetSelectedPartner(null)
                  else
                    SetSelectedPartner(i)
                }} item xs={2}
                      className={selectedPartner === i ? "partnerBillingPicture partnerBillingSelected" : "partnerBillingPicture"}
                      alt={"partner"}>
                  {
                    props.partners[i].premium_partner === "1" &&
                    <div className={selectedPartner === i ? "premiumPartner premiumPartnerSelected" : "premiumPartner"}>
                      <WhatshotIcon style={{color: "#F6BC41", fontSize: "2em"}}/>
                    </div>
                  }
                  <img alt={"imagePatners"} src={`${AdMediaLink}${props.partners[i].media_link}`}
                       className={selectedPartner === i ? "partnerImage partnerImageSelected" : "partnerImage"}/>
                </Grid>
              ))
            }
            <div className={"barPartnersMain"}/>
          </Grid>

          {
            selectedPartner !== null && (
              <>
                <div className={"selectionPartner"}>
                  <Zoom in={selectedPartner !== null}>
                    <div className={"textCirclePartner"}>
                      <div className={"textCirclePartnerText"}>
                        {selectedPartner && props.partners[selectedPartner].desc_message_partner}
                      </div>
                    </div>
                  </Zoom>

                  <Zoom in={selectedPartner !== null}>
                    <div className={"yellowButtonFull yellowButtonContainedPartner"}>
                      {selectedPartner && props.partners[selectedPartner].message_partner}
                    </div>
                  </Zoom>

                </div>

                <Link onClick={() => {
                  backPartner()
                }}>
                  <IconButton className={"backPartner"} aria-label="delete" color="primary">
                    <ArrowBackIos/>
                  </IconButton>
                </Link>

                <Link onClick={() => forwardPartner()}>
                  <IconButton className={"forwardPartner"} aria-label="delete" color="primary">
                    <ArrowForwardIos/>
                  </IconButton>
                </Link>
              </>
            )
          }
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  notValidated: state.cart.notValidated,
  language: state.language.language,
  menu: state.menu.menu,
  chatOff: state.session.chatOff,
  colors: state.session.colors,
  partners: state.session.partners,
  adLaunched: state.session.adLaunched,
  welcome: state.session.welcome,
  onlyAds: state.session.onlyAds,
  showPartners: state.session.showPartners
});

export default connect(mapStateToProps)(FirstMenu)
